.cropper-wrapper {
  position: absolute;
  z-index: 99;
  right: 100px;
  background: white;
  padding: 20px;
  box-shadow: 1px 1px 11px black;
}
.cropper-container-inner {
}
.main-profile-image {
  object-fit: cover;
  width: 185px;
  height: 185px;
  border-radius: 50%;
}

.img-preview {
  overflow: hidden;
}

.cropper-btn-file {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
}
.cropper-btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: pointer;
  display: block;

}
