.rescheduleText {
  text-align: center;
  font-size: 21px !important;
}

.contactbox-wrapper {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
  margin: 10px;
  font-size: 18px;
}

.contactbox-container {
  max-width: 60%;
  margin: 0 auto;
  margin-top: 45px;
}
.cross-icon-popup-1 > img {
  width: 20px;
  position: absolute;
  top: 18px;
  right: 20px;
  cursor: pointer;
  z-index: 88888;
}
.contactbox-contact {
  display: inline-block;
  background-color: unset !important;
  margin: unset !important;
  box-shadow: unset !important;
  width: unset !important;
  height: unset !important;
  line-height: unset !important ;
  color: unset !important;
  border-radius: unset !important;
  font-size: unset !important;
  font-weight: unset !important;
  font-family: unset !important;
  cursor: pointer;
}
.contactbox-contact-country {
  margin-bottom: unset;
  position: relative;
  top: 9px;
}

.contactbox-contact-country span {
  font-size: 24px;
}
.contactbox-contact-country img {
  
  width: 24px !important;
  height: 24px !important;
  
}

.contactbox-contact-country img {
  
  width: 24px !important;
  height: 24px !important;
  
  
}
/* styles.css or App.css */
.contactbox-contact {
  text-decoration: none !important; /* Initial state: no underline */
  transition: text-decoration 0.3s ease; /* Smooth transition */
}

.contactbox-contact:hover {
  text-decoration: underline !important; /* Underline on hover */
}
