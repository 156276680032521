.payment-wrapper {
  padding: 40px;
}
.payment-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}
.payment-price-tags {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.payment-price-tags .tag {
  width: 121px;
  height: 51px;

  background: #d5a67b;
  box-shadow: 0px 5px 10px #e0e4ee;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag-white {
  background: #ffffff !important;
  border: 1px solid #d5a67b !important;
  color: #d5a67b !important;
  box-shadow: unset !important;
}

.thankyou-page-wrapper {
  background-image: url(/integrationimages/flower.png);
  background-repeat: no-repeat;
  margin: 20px 40px;
  background-position: center;
  height: 600px !important;
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  justify-content: center;

  width: 100%;
}

.thankyou-page-container {
  border: 5px solid #d5a67b;
  border-radius: 5px;
    height: 198px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: white;
    margin: 80px;
}
.thankyou-page-container p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
  margin: 20px 0px;
}

.thankyou-page-container a {
  background: #d5a67b;
  box-shadow: 0px 5px 10px #e0e4ee;
  border-radius: 5px;
  color: white;
  width: 200px;
  height:40px;
  border: 1px solid #d5a67b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thankyou-page-box{
    height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;
}