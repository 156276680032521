.modal-body-waiting-time {
  min-height: 300px;
}

.modal-body-waiting-time .modal-body-waiting-time-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}
.border-waiting-time {
  border: 10px solid #d5a67b;
  border-radius: 16px;
}
