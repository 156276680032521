.giftyourmediums-wrap-input input {
  width: 100px !important;
  /* margin-left: 20px !important; */
  border-right: none !important;
  padding-right: 15px !important;
}
.giftyourmediums-wrap-input {
  margin-left: 45px !important;
}
.giftyourmediums-wrap-select {
  height: 51px;
  width: unset !important;
  border-radius: 5px !important;
  border-left: none !important;
  margin-left: -14px !important;
}
.payment-save-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  /* flex-wrap: wrap; */
  gap: 10px;
}
.pay-if-you-wish {
  justify-content: center !important;
}

@media (max-width: 576px) {
  .gift-your-medium-modal > .modal-dialog {
    width: unset !important;
  }
}

.giftyourmediums-wrap-input-payit-forward input {
  width: 290px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
