.userstatus div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 20px;
  
}
.userstatus-green {
  background-color: green;
  border: 1px solid green;
}
.userstatus-red {
  background-color: red;
  border: 1px solid red;
}
