.select-check{height: 50px;
    /* max-width: 500px; */
    width: 100%;}

    .error-select{
        text-align: left;

    }
    .error-select span{
        font-size: 12px; 
    }