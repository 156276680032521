.action-btn-feedbacks {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}
.w-20-px {
  width: 20px;
}
.w-70-px {
  width: 70px;
}
/* .table-feed{} */

.table-feed-bg {
  background-color: #2f2f2f;
}
.dark-subheading th {
  padding: 5px 10px !important;
  font-size: 13px;
  /* text-align: center; */
}
.min-width-125{
  min-width: 125px;
}
.min-width-120{
  min-width: 120px;
}
.min-width-135 {
  min-width: 135px;
}
.min-width-210 {
  min-width: 210px;
}
.min-width-300 {
  min-width: 300px;
}
.min-width-150 {
  min-width: 150px;
}
.min-width-100 {
  min-width: 100px;
}
.overflow-scroll {
  overflow: scroll !important;
}
.editable-form-feedback {
  display: flex;
  justify-content: space-between;
}
.feedback-urls:hover {
  text-decoration: underline;
}
.feedback-table td {
  font-size: 10px;
}

.feedback-text-area {
  border: 1px solid #ccc;
  width: 250px;
}
@media (max-width: 930px) {
  .editable-form-feedback {
    display: block;
  }
}
