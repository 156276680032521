.filter-container select {
  min-width: 153px;
  height: 44px;
  /* width: 153px; */
  padding: 10px 10px 10px 13px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("/images/filter.png"), url("/images/dropdown.png");
  background-repeat: no-repeat;
  background-position: 10%,90%;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 3px;
  color: #485257;
  width: unset;
}
