.payment-new-container {
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .payment-new-wrapper .main-btn{
    color: #d5a67b;
  }
  .payment-continue-btn{
  min-width: 210px !important;
  height: 55px !important;
  }
  .float-unset{
    float: unset !important;
  }