.sinle-profile {
	background-color: #E5E5E5;
}
.back-btn-profile {
	display: inline-block;
	width: 100%;
	margin: 31px 0 0;
}
.back-btn-profile .back-handler {
	display: inline-block;
	width: 199px;
	height: 40px;
	border: 1px solid #CACACA;
	border-radius: 5px;
	text-align: center;
	line-height: 40px;
	background-color: #fff;
	color: #485257 !important;
	font-size: 14px;
	font-family: "Oakes-Grotesk-Regular";
	font-weight: 400;
}
.back-btn-profile .back-handler img {
	position: relative;
	top: 2px;
	margin: 0 6px 0 0;
}
.profile-all-box {
	display: inline-block;
	width: 100%;
	margin: 31px 0 61.1px 0;
	background-color: #fff;
	box-shadow: 0px 0px 33px #E0E4EE;
	border-radius: 11px;
	padding-bottom: 118.63px;
}
.profile-all-box img {
	border: 6px solid #fff;
	border-radius: 11px 11px 0 0;
	width: 100%;
}
.profile-info {
	display: flex;
	/* display: inline-block; */
	width: 100%;
	padding-left: 94.71px;
	height: 150px;
}
.profile-image {
	float: left;
	width: 183px;
	height: 183px;
	border-radius: 50%;
	/* overflow: hidden; */
	position: relative;
	bottom: 60px;
}
.profile-name {

	float: right;
	/* width: 82%; */
	margin: 10px 0 0 0;
	margin-left: 30px;
}
.profile-image img {
	width: 100%;
	border: none;
	border-radius: 50%;
}
.profile-name .nam-prof {
	display: inline-block;
	width: 100%;
	color: #485257;
	font-size: 24px;
	font-weight: 600;
	font-family: "Oakes-Grotesk-Medium";
}
.profile-name a {
	color: #485257;
	font-size: 16px;
	font-weight: 400;
	font-family: "Oakes-Grotesk-Regular";
}
.star-rating {
	display: inline-block;
	width: 100%;
	margin: 14px 0 0 0;
}
.star-rating img {
	width: auto;
	border: none;
	border-radius: 0;
	margin: 0 6px 0 0;
}
.star-rating span {
	color: #2F2F2F;
	font-size: 20px;
	font-family: "Oakes-Grotesk-SemiBold";
	font-weight: 700;
	display: inline-block;
	position: relative;
	bottom: 2px;
}
.star-rating em {
	font-style: normal;
	color: #2F2F2F;
	font-family: "Oakes-Grotesk-Medium";
	font-size: 14px;
	font-weight: 500;
	position: relative;
	top: -4px;
	border-left: 1px solid #dcdcdc;
	margin: 0 0px 0 7px;
	padding: 0 0 0 10px;
	height: 19px;
	display: inline-block;
}
.profile-details {
	padding: 30.12px 94.71px;
	display: inline-block;
	width: 100%;
	border-top: 1px solid #EFEFEB;
}
.profile-details ul {
	display: inline-block;
	width: 100%;
	padding: 0;
	margin: 0;
}
.profile-details ul li {
	display: inline-block;
	width: 100%;
	text-align: left;
	margin: 0 0 22px 0;
}
.profile-details ul li .icon-detls {
	float: left;
	background-color: #F7F7F3;
	width: 48.87px;
	height: 48.87px;
	border-radius: 50%;
	text-align: center;
	padding: 8px 0 0 0;
	margin: 0 20px 0 0;
}
.icon-detls img {
	border: none;
	width: auto;
	border-radius: 0;
}
.profile-details ul li em {
	font-style: normal;
	color: #000;
	font-size: 16px;
	font-weight: 400;
	font-family: "Oakes-Grotesk-Regular";
}
.profile-details ul li span {
	display: inline-block;
	height: 36px;
	line-height: 36px;
	text-align: center;
	border: 1px solid #D5A67B;
	border-radius: 5px;
	margin: 0 8px;
	padding: 0 20px;
	color: #000;
	font-weight: 400;
	font-size: 14px;
	font-family: "Oakes-Grotesk-Regular";
}
.profile-details ul li a {
	color: #D5A67B;
	font-size: 14px;
	font-weight: 500;
	display: inline-block;
	margin: 0 0 0 10px;
	font-family: "Oakes-Grotesk-Medium";
}
.profile-bio {
	padding: 36.56px 94.71px;
	display: inline-block;
	width: 100%;
	box-sizing: border-box;
	border-top: 1px solid #EFEFEB;
}

.profile-bio .paypal{
	display:flex;
	padding-top: 20px;
}
.profile-details ul li:last-child {
	margin-bottom: 0;
}
.profile-bio h2 {
	color: #2F2F2F;
	font-size: 20px;
	font-weight: 600;
	font-family: "Oakes-Grotesk-Regular";
	margin: 0;
	display: inline-block;
}
.profile-bio p {
	margin: 11.7px 0 0 0;
	color: #485257;
	font-size: 16px;
	line-height: 34px;
	font-family: "Oakes-Grotesk-Regular";
	font-weight: 400;
}
.forgot-pasword-caction {
	box-shadow: 0px 0px 33px #E0E4EE;
	background-color: #fff;
	display: inline-block;
	width: 100%;
	border-radius: 5px;
	margin: 25px 0 27px;
	text-align: center;
	padding: 78px 0 0 0;
	height: 878px;
}
.forgot-pasword-caction h3 {
	display: inline-block;
	color: #485257;
	font-size: 32px;
	font-weight: 500;
	letter-spacing: 0.01em;
	font-family: "Oakes-Grotesk-Medium";
	text-transform: capitalize;
	margin: 0;
	width: 100%;
}
.forgot-pasword-caction p {
	margin: 7px 0 0 0;
	width: 100%;
	display: inline-block;
	text-align: center;
	color: #485257;
	font-size: 16px;
	font-family: "Oakes-Grotesk-Regular";
}
.email-pxx {
	display: inline-block;
	margin: 85px auto 0;
	width: 50%;
	text-align: left;
}
.email-pxx label {
	display: inline-block;
	width: 100%;
	margin: 0 0 9.62px;
	color: #2F2F2F;
	font-size: 16px;
	font-weight: 500;
	font-family: "Oakes-Grotesk-Medium";
}
.email-pxx input {
	width: 100%;
	border-radius: 5px;
	border: 1px solid #CACACA;
	padding: 0 10px;
	height: 61.37px;
}
.email-pxx .rest-pasword {
	width: 100%;
	background-color: #00D34F;
	margin: 41.1px 0 0 0;
	border-color: #00D34F;
	border-radius: 5px;
	height: 61.41px;
	box-shadow: 0px 5px 10px #E0E4EE;
	font-size: 16px;
	font-weight: 500;
	font-family: "Oakes-Grotesk-Medium";
}
.email-pxx h6 {
	text-align: center;
	display: inline-block;
	width: 100%;
	margin: 28px 0 0 0;
	color: #485257;
	font-size: 16px;
	font-weight: 400;
	font-family: "Oakes-Grotesk-Regular";
}
.email-pxx h6 a {
	margin: 0 0 0 5px;
	color: #4E64DD;
	text-decoration: underline;
}

@media (max-width: 767px) {
    .headar-saction .container {
        padding-left: 15px;
        padding-right: 15px;
    }
        
    /*My-profile-single-page*/
    .single-profile-saction .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .back-btn-profile {
        margin: 15px 0 0;
    }
    .back-btn-profile .back-handler img {
        top: 1px;
        margin: 0 4px 0 0;
        width: 17px;
    }
    .back-btn-profile .back-handler {
        width: auto;
        height: auto;
        line-height: inherit;
        font-size: 13px;
        padding: 8px 7px;
    }
    .profile-all-box {
        margin: 15px 0 2rem 0;
        border-radius: 5px;
        padding-bottom: 0rem;
    }
    .profile-all-box img {
        border: 3px solid #fff;
        border-radius: 5px 5px 0 0;
    }
    .profile-info {
        padding-left: 15px;
        height: auto;
        padding-right: 15px;
    }
    .profile-image {
        width: 120px;
        height: 120px;
        bottom: 55px;
    }
    .profile-image img {
        width: 120px;
        height: 120px;
        border: none !important;
    }
    .profile-name {
        width: 64%;
        margin: 0 0 0 0;
    }
    .profile-name .nam-prof {
        font-size: 20px;
    }
    .profile-name a {
        font-size: 14px;
    }
    .star-rating {
        margin: 0 0 0 0;
    }
    .star-rating span {
        font-size: 16px;
        bottom: 4px;
    }
    .star-rating img {
        width: 18px;
        margin: 0;
    }
    .star-rating em {
        font-size: 12px;
        margin: 0 0px 0 7px;
        padding: 0 0 0 6px;
    }
    .user-menu {
        right: -7px;
        width: 244px;
    }
    .profile-details {
        padding: 15px;
    }
    .profile-details ul li {
        margin: 0 0 15px 0;
    }
    .profile-details ul li .icon-detls {
        width: 40.87px;
        height: 40.87px;
        padding: 7px 0 0 0;
        margin: 0 15px 0 0;
    }
    .icon-detls img {
        border: none;
        width: auto;
        border-radius: 0 !important;
        border: none !important;
    }
    .profile-details ul li em {
        font-size: 13px;
        width: 84%;
        float: right;
    }
    .profile-details ul li span {
        height: 30px;
        line-height: 30px;
        margin: 7px 4px;
        padding: 0 8px;
        font-size: 12px;
    }
    .profile-details ul li a {
        font-size: 12px;
    }
    .profile-bio {
        padding: 15px;
    }
    .profile-bio h2 {
        font-size: 17px;
    }
    .profile-bio p {
        margin: 5.7px 0 0 0;
        font-size: 14px;
        line-height: 25px;
    }
    
   
    
    /*End-My-profile-single-page*/
    
    
}

.label-value{
	color: #D5A67B;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin: 0 0 0 10px;
}