.from-step-new .from-inner-box {
	padding: 31.28px 50px 32.71px;
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid #ebebeb;
}
.from-step-new .from-inner-box input {
	margin: 0;
}
.from-step-new  .from-inner-box input {
	height: 49.37px;
}
.from-step-new .ul-li-list li input {
	height: 100%;
}









@media (max-width: 767px) {
    .from-step-new .from-inner-box {
        padding: 10px !important;
    }
    .from-step-new .css-1rhbuit-multiValue {
        position: relative;
        bottom: 4px;
    }

}



@media (min-width: 768px) and (max-width: 1024px) {
    .from-saction .row.justify-content-center {
        margin: 30px 0 0 0 !important;
        width: 95%;
    }
    .header-from #progressbar {
        margin: 0 auto;
        width: 100%;
        padding: 0;
    }
    .from-step-new .from-inner-box {
        padding: 20px;
    }
    .from-step-new .selection-box, .selection-box > .css-1s2u09g-control {
        height: 52.37px !important;
    }
    #msform .action-button {
        margin: 30px 0 !important;
    }
    #msform .action-button-previous {
        margin: 0 15px 0 0px;
    }
    .from-step-new .check-field label {
        height: 49px;
        line-height: 49px;
    }



}
