.client-dashboard-card-wrapper{
  align-items: center !important;
  display: flex !important;
  flex-flow: row wrap !important;
  gap: 20px !important;
  width: 100% !important;
  /* margin: 18px 0 0 0; */
}
.client-dashboard-card-container {
  width: 299px;
  height: 161px;
  background: #ffffff;
  box-shadow: 0px 8px 22px -6px rgba(73, 73, 73, 0.12),
    0px 14px 64px -4px rgba(71, 71, 71, 0.12);
  border-radius: 11px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.client-dashboard-card-container:hover{
  color: #000000;
}

.client-dashboard-card-container div {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.client-dashboard-card-container div img {
  width: 46.76px;
  height: 47px;
}
.client-dashboard-card-container div p {
    margin-top: 20px;
    margin-bottom: 0 !important;
}

