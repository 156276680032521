/*all-medium-page*/
.all-medium-page .tabs {
  padding: 0;
  list-style: none;
  position: relative;
  border-bottom: 1px solid #d9d9d9;
}
.active-tab{
  border: 2px solid #d5a67b;
}
.table-container {
  overflow-x: auto;
}
/* .tabs li {
    transition: 0.3s;
} */
.all-medium-page .tabs .active-tab {
  border-bottom: none;
  position: relative;
  color: #d5a67b;
}
.all-medium-page .tabs li {
  display: inline-block;
  cursor: pointer;
  color: #485257;
  padding: 5px 36px 11px 36px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.02em;
  font-family: "Oakes-Grotesk-Medium";
  position: relative;
}
.all-medium-page .tabs li span {
  font-weight: 300;
  font-family: "Oakes-Grotesk-Light";
}
.all-medium-page .tabs-content {
  margin: 0;
  padding: 0;
  list-style: none;
}
.all-medium-page .tabs-content li {
  /* display: none; */
}
.all-medium-page {
  background-color: #fff;
  box-shadow: 0px 0px 33px #e0e4ee;
  border-radius: 5px;
  margin: 26px 10px 40px;
  padding: 21px 30px;
}
.all-medium-page .tabs .active-tab::after {
  content: "";
  background-color: #d5a67b;
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -1px;
}
.add-neapp {
  float: left;
  width: 200px;
  text-align: center;
  margin: 0 30px 0 0;
}
.all-medium-page .right-filtr {
  width: 52%;
}
.add-neapp a {
  display: inline-block;
  width: 100%;
  border: 1px solid #4e64dd;
  border-radius: 4px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  color: #4e64dd;
  font-family: "Oakes-Grotesk-Medium";
  text-decoration: none;
}
.add-neapp a img {
  margin: 0px 10px 0 0;
  position: relative;
  top: 4px;
}
.table-box-medium {
  margin: 22px 0 0 0;
  width: 100%;
}
.table-box-medium table {
  width: 100%;
}
.table-box-medium tr.innr {
  margin-bottom: 8.35px;
  box-shadow: 0 0 0 1px rgba(24, 39, 75, 0.12),
    8px 10px 32px -4px rgba(24, 39, 75, 0.1);
  border-radius: 10px;
  border: 1px solid #f3f3f3;
}
.table-box-medium th label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  width: 21px;
  height: 21px;
  border-radius: 5px;
  margin: 0 22px 0 0 !important;
  background-color: #fff;
}
.table-box-medium th label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  width: 21px;
  height: 21px;
  border-radius: 5px;
  margin: 0 22px 0 0 !important;
  background-color: #fff;
}
.table-box-medium input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.table-box-medium th input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 5px;
  height: 13px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.table-box-medium th input:checked + label::before {
  background-color: #d5a67b;
}
.table-box-medium th label {
  position: relative;
}
.table-box-medium td label {
  position: relative;
  float: left;
  margin: 15px 0 0 0;
}
.table-box-medium td label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #d5a67b;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  width: 21px;
  height: 21px;
  border-radius: 5px;
  margin: 0 22px 0 0 !important;
}
.table-box-medium td input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 5px;
  height: 13px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.table-box-medium td input:checked + label::before {
  background-color: #d5a67b;
}
.table-box-medium td {
  padding: 8px 14px;
  color: #485257;
  font-family: "Oakes-Grotesk-Regular";
  font-size: 14px;
}
.table-box-medium td.change em {
  font-style: normal;
}
.table-box-medium td.change a {
  background-color: rgba(78, 100, 221, 0.12);
  width: 64px;
  height: 21px;
  display: inline-block;
  text-align: center;
  line-height: 22px;
  border-radius: 3px;
  margin: 0 0 0 10px;
  font-size: 12px;
  color: #4e64dd;
  font-family: "Oakes-Grotesk-Regular";
}
.table-box-medium td span {
  float: right;
  text-align: left;
  width: 65%;
  margin: 17px 0 0 0;
}
.table-box-medium tr th:last-child {
  text-align: right;
}
.table-box-medium tr td:last-child {
  text-align: right;
}
.all-medium-page .pagination-box li {
  display: inline-block;
}
.all-medium-page .pagination-box .left-pagi span {
  margin: 9px 0 0 0 !important;
  color: #2f2f2f !important;
  font-size: 13px !important;
  display: inline-block;
  width: 100%;
  font-family: "Oakes-Grotesk-Medium";
}
.dot-image .approv-view {
  background-color: rgba(78, 100, 221, 0.12);
  width: 104px;
  height: 35px;
  display: inline-block;
  text-align: center;
  line-height: 34px;
  border-radius: 3px;
  color: #4e64dd;
  font-size: 12px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
}
.dot-image .approv-view .w-e {
  display: none;
}
.dot-image .approv-view img {
  margin: 0 7px;
}
.dot-image .approv-view:hover {
  background-color: #4e64dd;
  color: #fff;
  text-decoration: none;
}
.dot-image .approv-view:hover .b-e {
  display: none;
}
.dot-image .approv-view:hover .w-e {
  display: inline;
}

.mt-20 {
  margin-top: 20px;
}
.dott .approve-btn-1 {
  margin-left: unset !important;
  width: 120px;
  margin-top: 20px;
  text-align: left !important;
}
.dott .approve-btn-1 span {
  float: unset !important;
  text-align: unset !important;
  width: unset !important;
  margin: unset !important;
}

.sort-screenName{
  transform: rotate(180deg);
}
.sort-screenNames{
  width: 15px;

}
.d-flex align-items-center{
  /* position: relative; */
}
.sort-box{
  margin-left: 10px;
  /* position: absolute;
  top: 13%;
  right: 30%; */
}
.bottomClass{
  margin-bottom: 20px;
}