
@media (max-width: 767px) {

}

@media (max-width: 350px) {

}

@media (max-width: 375px) {

}


@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-width: 992px) and (max-width: 1190px) {
}

@media (min-width: 1191px) and (max-width: 1240px) {

}

@media (width: 1920px) {

}
@media  screen and (max-width: 1366px) {
.form-l---r form {
	width: 69%;
}	
}



@media (max-width: 767px) {
	.login-register-saction .container {
		width: 100% !important;
		margin: 0;
		max-width: inherit;
		flex-wrap: wrap;
		display: flex;
	}
	.login-register-saction .col-md-6:nth-child(1) {
		order: 2;
		flex-wrap: wrap;
	}
	.login-register-saction .col-md-6:nth-child(2) {
		order: 1;
		flex-wrap: wrap;
	}
	.login-register-saction {
		width: 100%;
		background: inherit;
		height: 100vh;
		background-color: #f6f6f6;
	}
	.black-container{
		background:unset !important
	}
	.login-saction-right {
		display: inline-block;
		width: auto;
		padding: 0;
		text-align: left;
	}
	.form-l---r form {
		width: 100% !important;
	}
	.testim .cont div .img img {
		width: 75%;
	}
	.logo {
		margin: 30px 0 0 0;
	}
	.login-saction-right h2 {
		margin: 50.41px 0 0 0;
		font-size: 20px;
		line-height: 30px;
		font-weight: 600;
		width: 100%;
	}
	.login-saction-right h2 br {
		display: none;
	}
	.form-l---r {
		margin: 25.5px 0 0 0;
		text-align: left;
	}
	.form-l---r input {
		height: 55px;
		padding: 0 40px;
		font-size: 14px;
	}
	.icon {
		left: 10px;
		top: 49px;
	}
	.icon-right {
		right: 10px;
		top: 48px;
	}
	.form-l---r .from-fild {
		margin: 0 0 15px 0;
	}
	.bution {
		margin: 20.05px 0 0 0;
	}
	.forget-fild {
		margin: 4px 0 0 0;
	}
	.bution .button-bx {
		height: 55px;
	}
	.dont-have-acc {
		margin: 25.46px 0 0 0 !important;
	}
	.dont-have-acc p {
		font-size: 13px;
	}
	.dont-have-acc .btn.btn-info.btn-lg {
		font-size: 13px;
	}
	.testim .cont {
		margin: 3rem 0 0;
	}
	.testim .cont div h2 {
		font-size: 18px;
		margin: 15px 0 0 0;
	}
	.testim .cont div p {
		font-size: 13px;
		width: 100%;
		margin: 10px 0 0;
		line-height: 18px !important;
	}
	.testim .dots {
		margin: 15px 0;
	}
	.dont-have-acc .modal-dialog {
		max-width: 90% !important;
		margin: 0 auto !important;
		height: auto !important;
		border-radius: 10px !important;
		border: 2px solid #D5A67B !important;
		background-color: #fff !important;
	}
	.dont-have-acc .modal-body {
		text-align: center;
		padding: 34px 15px;
	}
	.icon-pop img {
		width: 15%;
	}
	.dont-have-acc .modal-body ul {
		margin: 30.28px 0 0;
	}
	.dont-have-acc .modal-body ul li {
		margin: 0 10px;
	}
	.dont-have-acc .modal-body ul li a {
		width: 100%;
		border: 1px solid #fff;
		border-radius: 6px;
		height: auto;
		padding: 10px;
	}
	.dont-have-acc .modal-body ul li a img {
		display: inline;
		width: 48px;
		height: 40px;
	}
	.dont-have-acc .modal-body ul li a span {
		margin: 10.08px 0 0 0;
		font-size: 13px;
		line-height: 20px;
	}
	.images img {
		width: 85%;
	}
	.register-medium #testim-content {
		margin: 3rem 0 0;
	}
	.register-medium .modal-dialog {
		width: 90% !important;
		height: auto !important;
		border-radius: 11px;
		margin: 0 auto;
	}
	.register-medium .modal-dialog .icon-pop {
		margin: 20.95px 0 0 0;
	}
	.register-medium .modal-dialog h3 {
		color: #485257;
		font-size: 18px;
		margin: 15.09px 0 0 0;
		line-height: 25px;
	}
	.register-medium .modal-dialog p {
		margin: 10px auto 0;
		font-size: 14px;
		line-height: 20px;
	}
	.register-medium .modal-dialog a ,.register-medium .modal-dialog .register-medium-confirnation ,.client-reschedule-btns{
		margin: 20.1px 0;
		width: 87%;
		height: 50px;
		line-height: 50px;
		font-size: 13px;
	}
	.bution .btn.btn-info.btn-lg {
		height: 55px;
	}
	
	/*step-from*/
	.headar-saction .col-md-6 {
		float: left;
		width: 50%;
	}
	.logo-saction img {
		width: 100%;
	}
	.from-saction {
		display: inline-block;
		width: 100%;
		height: 100%;
		padding:30px 0 !important;
	}
	.from-saction .row.justify-content-center {
		margin: 0 !important;
		display: inline-block;
		width: 100%;
	}
	.from-saction .container {
		padding: 0 10px;
	}
	.header-from {
		padding: 15.83px 0;
	}
	.header-from #progressbar {
		width: 100%;
		text-align:center;
	}
	#progressbar li {
		font-size: 15px;
		width: 24%;
		float: inherit;
		display: inline-block;
	}
	#progressbar li span {
		width: 40px;
		height: 40px;
		line-height: 52px;
	}
	#progressbar li strong {
		width: 100%;
		margin: 10px 0 0 0;
		font-size: 10px;
	}
	#progressbar li span img {
		width: 26px;
	}
	.from-inner-box {
		padding: 20px 15px;
	}
	.left-side {
		width: 100%;
	}
	.right-side {
		width: 100%;
	}
	.from-inner-box label {
		margin: 0 0 5px 0;
		font-size: 14px;
	}
	.from-inner-box input {
		height: 50.37px;
		padding: 0 10px;
		font-size: 14px;
		margin: 0 0 15.62px 0;
	}
	.chosen-container-multi .chosen-choices {
		padding: 0 16px;
		height: 48.37px !important;
		line-height: 48.37px;
	}
	.from-inner-box textarea {
		height: 100.66px;
	}
	#msform .action-button {
		width: 130px;
		height: 48px;
		font-size: 16px;
		margin: 25.9px 10px 25.9px;
	}
	#msform .action-button-previous {
		width: 130px;
		height: 50px;
	}
	.radio-box {
		background-color: transparent;
	}
	.footer-box.footer-one {
		text-align: center;
		width: 100%;
	}
	.footer-box.footer-one p img {
		width: 28.71px;
		margin: 0px 7.29px 0 0;
		float: inherit;
		display: inline;
		position: relative;
		top: 8px;
	}
	.footer-box.footer-one p {
		margin: 0;
		font-size: 18px;
		line-height: 33px;
	}
	.footer-box-centar ul li {
		margin: 14px 6px 0 0;
		padding: 0 6px 0 0;
		line-height: 9px;
	}
	.footer-box-right {
		text-align: center;
	}
	.footer-box-right p {
		font-size: 10px;
	}
	.footer-box-centar ul li a {
		font-size: 12px;
	}
	.check-box-input .check-field label {
		margin: 0;
		font-size: 12px !important;
	}
	.check-box-input .check-field {
		margin: 0 12px 10px 0;
	}
	.as-medium .full .check-field {
		margin-right: 0 !important;
	}
	.as-medium .full .check-field label {
		padding: 0 17px 0 46px;
		font-size: 10px !important;
	}
	.signup {
		font-size: 12px;
	}
	.signup a {
		margin: 0 6px 15.86px 0;
	}
	.ul-li-list {
		text-align: center;
	}
	.ul-li-list li {
		float: inherit;
		width: 46%;
		margin: 0 9.9px 13.92px 0;
	}
	.ul-li-list li a {
		padding: 18.65px 10.4px;
		border-radius: 5px;
		height: auto;
	}
	.browser-saction br {
		display: none;
	}
	.from-inner-box h4 {
		margin: 0 0 19.43px 0;
		font-size: 13px;
		font-weight: 400;
		line-height: 20px;
	}
	.thanku-popup .modal-dialog {
		max-width: inherit;
		width: 90%;
		height: auto;
		padding: 2.86px 0;
	}
	.thanku-popup p {
		font-size: 14px;
		line-height: 22px;
		margin: 10.3px 0 0 0;
	}
	.closebt .close {
		border-radius: 5px;
		width: 100px !important;
		height: 47px !important;
		margin: 20px 0 0 0 !important;
		font-size: 14px;
	}
	
	
	}
	@media (max-width: 350px) {
	
	}
	
	@media (max-width: 375px) {
	
	}
	@media (min-width: 768px) and (max-width: 991px) {
	}
	
	@media (min-width: 768px) and (max-width: 1024px) {
	.login-saction-right {
		padding: 0 0 0 20px;
	}
	.login-saction-right h2 {
		margin: 30.41px 0 0 0;
		font-size: 35px;
		line-height: 40px;
	}
	.form-l---r {
		margin: 35.5px 0 0 0;
	}	
	.form-l---r form {
		width: 100% !important;
	}
	.form-l---r input {
		height: 55px;
	}
	.dont-have-acc {
		margin: 30.46px 0 0 0;
	}
	.bution {
		margin: 27.05px 0 0 0;
	}	
	#testim-content .img img {
		width: 100% !important;
	}
	.testim .cont div h2 {
		font-size: 28px;
		margin: 35px 0 0 0;
	}
	.images img {
		width: 100%;
	}
	.from-saction {
		display: inline-block;
		width: 100%;
		height: 100%;
		padding-bottom: 0;
	}
	.from-saction .container {
		padding: 0 5px;
	}
	.from-saction .row.justify-content-center {
		margin: 30px 0 !important;
		width: 100%;
	}
	.header-from {
		padding: 20.83px 0;
	}
	.header-from #progressbar {
		margin: 0 auto;
		width: 100%;
	}
	#progressbar li strong {
		display: inline-block;
		width: 100%;
		margin: 10px 0 0 0;
		font-size: 13px;
	}
	#progressbar li span {
		width: 55px;
		height: 55px;
		line-height: 76px;
	}
	.from-inner-box {
		width: 100%;
		padding: 20px;
	}
	#msform .action-button {
		width: 150px;
		height: 53px;
		font-size: 16px;
		margin: 40.9px 50.5px 40.9px ;
	}
	#msform .action-button-previous {
		width: 150px;
		height: 55px;
		font-size: 16px;
	}
	.footer-box.footer-one p img {
		float: inherit;
		position: relative;
		top: 12px;
	}
	.footer-box-centar {
		text-align: left;
	}
	.footer-box-centar ul li {
		margin: 20px 5px 0 0;
		padding: 0 6px 0 0;
		line-height: 14px;
	}
	.footer-box-centar ul li a {
		font-size: 13px;
	}
	.footer-box-right p {
		margin: 13px 0 0 0;
		font-size: 13px;
	}
	.check-box-input .check-field {
		margin-bottom: 17px;
	}
	.footer-saction .col-md-4 {
		max-width: 100%;
		flex: inherit !important;
		width: 100%;
	}
	.footer-saction .row {
		display: inherit;
	}
	.footer-box.footer-one {
		text-align: center;
	}
	.footer-box.footer-one p img {
		float: inherit !important;
	}
	.footer-box-right p {
		margin: 28px 0 0 0 !important;
		text-align: center;
	}
	.from-step-new .ul-li-list li label {
		height: 100%;
	}



	
	
	
	
	
	
	
		
		
	}
	@media (min-width: 992px) and (max-width: 1190px) {
	}
	
	@media (min-width: 1191px) and (max-width: 1240px) {
	
	}
	@media (width: 1920px) {
	
	}
	@media  screen and (max-width: 1366px) {
	.form-l---r form {
		width: 69%;
	}	
	}