.registernew {
	background: inherit;
	display: inline-block;
	width: 100%;
	text-align: center;
}
.registernew .register-clint {
	margin: 4rem auto;
	width: 90%;
	text-align: left;
	background-color: #fff;
	box-shadow: 0 0 11px 0 #dcdcdc;
	border-radius: 14px;
	padding: 30px;
}
.registernew form {
	width: 100%;
}
.registernew form .from-fild {
	float: left;
	width: 44%;
	margin: 0 22px 22px 0;
}
.registernew form .bution {
	float: left;
	width: 100%;
	margin: 32px 0 0 0;
}
.registernew form .bution .button-bx {
	width: 40%;
	float: left;
	margin-right: 2px;
}