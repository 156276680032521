.ondemandpractice-wrapper-medium-practice {
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin-top: 24px;
  margin-right: -16px;
  margin-left: -16px;
  padding-bottom: 24px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(18, 18, 18, 0.18) 0px 3px 12px 0px;
  width: 70%;
  margin-bottom: 40px;
}
.pad-20 {
  padding: 20px;
}
.ondemandpractice-wrapper-medium-practice-filter-secion {
  background-color: #f8ecdc;
  padding: 20px;
  height: 100px !important;
  align-items: center;
  border-bottom: 2px solid #e8c4ac;
}
.ondemandpractice-wrapper-medium-practice-odd-color {
  background-color: #fff4ec;
  border-radius: 12px;
}

.ondemandpractice-heading-ractice-mediums {
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 0.02em;
  margin: 0px;
  display: flex;
  align-items: center;
}
.ondemandpractice-heading-ractice-mediums::before {
  content: "";
  background-color: #c08c54;
  width: 6px;
  height: 35px;
  display: block;
  border-radius: 20px;
  margin-right: 6px;
  margin-bottom: 4px;
}

.godemantevent-box {
  padding-top: 16px;
  padding-bottom: 16px;
}
.godemantevent-box-list {
  margin: 0px;
  padding: 0px 16px;
  list-style: none;
}
.godemantevent-box-list li {
  border-bottom: unset !important;
}
.godemantevent-box-list li:nth-child(odd) {
  background-color: #fff4ec;
  border-radius: 12px;
}

.godemantevent-activeevent-info {
  background-color: #fff4ec;
}
.godemantevent-activeevent-info-heading {
  color: #c08c5c;
}

.godemantevent-internal-box {
  position: relative;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 4px;
}

.godemantevent-date-box {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  -webkit-box-pack: justify;
  justify-content: space-between;
  /* width: 4.2em; */
  margin-right: 8px;
  color: rgb(18, 18, 18);
  /* text-align: center; */
}

.godemantevent-date-box .month-name {
  line-height: 1.2;
  text-transform: uppercase;
  font-size: 16px;
}
.more-info-go-ondemand-practice span {
  font-size: 15px;
  font-weight: bold;
  display: block;
  margin-right: 3px;
}
.more-info-go-ondemand-practice img {
  /* width: 20px;
  display: block; */
}
.godemantevent-date-box .month-date {
  font-size: 23px;
  line-height: 1;
}

.godemantevent-date-box {
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin-right: 8px;
  word-break: break-word;
  overflow-wrap: break-word;
  line-height: 25px;
  /* text-align: left; */
}

.ondemandpractice-date-box-day {
  color: rgb(100, 100, 100);
  /* width: 4.2em; */
}

.dieHWG::before {
  top: 50%;
  display: inline-block;
  width: 0.25em;
  height: 0.25em;
  margin: 0px 0.4em;
  border-radius: 50%;
  background-color: currentcolor;
  transform: translateY(-50%);
  content: "";
}

.ivIeWB {
  font-size: var(--rainier-font-size, 16px);
  line-height: var(--rainier-line-height, 22px);
  letter-spacing: 0.02em;
  font-weight: 600;
  display: block;
  color: rgb(18, 18, 18);
}

.sc-fyofxi-5 + .sc-fyofxi-5::before {
  top: 50%;
  display: inline-block;
  width: 0.25em;
  height: 0.25em;
  margin: 0px 0.4em;
  border-radius: 50%;
  background-color: currentcolor;
  transform: translateY(-50%);
  content: "";
}

.jWLmQR {
  display: block;
  color: rgb(100, 100, 100);
}

.godemantevent-date-box {
  display: block;
  align-self: center;
  margin-left: 16px;
}

.godemantevent-date-box .jdbotF {
  margin: initial;
  border: 1px solid transparent;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  padding: 8px 24px;
  color: rgb(255, 255, 255);
  background-color: #d5a67b;
  display: inline-block;
  width: auto;
  min-height: 44px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  transition: background-color 0.2s;
  text-decoration: none;
}
.kCQEIv {
  display: flex !important;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.lmaHQg::after {
  /* position: absolute;
    inset: -16px;
    content: ""; */
}
.jdbotF {
  margin: initial;
  border: 1px solid transparent;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  padding: 8px 24px;
  color: rgb(255, 255, 255);
  background-color: #d5a67b;
  display: inline-block;
  width: 450px;
  height: 50px;
  min-height: 44px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
  text-align: center;
  transition: background-color 0.2s;
  text-decoration: none;
}
.jdbotF-blacnk {
  margin: initial;
  border: 2px solid #d5a67b;
  color: #d5a67b;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  padding: 8px 24px;
  /* background-color: #d5a67b; */
  display: inline-block;
  width: auto;
  min-height: 44px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  transition: background-color 0.2s;
  text-decoration: none;
  margin-left: 40px;
}
.bcqnRP {
  display: block;
  transform: rotate(-90deg);
}
.ckLyyv {
  display: inline-block;
  flex-shrink: 0;
  vertical-align: middle;
  transition: transform 0.2s ease-out;
  fill: white;
}

.width-175-px {
  width: 175px !important;
}
.width-215-px {
  width: 215px !important;
}
.width-55 {
  width: 55%;
}

.filter-container-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 153px;
  height: 44px;
  /* width: 153px; */
  /* padding: 10px 10px 10px 13px; */
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("/images/filter.png"), url("/images/dropdown.png");
  background-repeat: no-repeat;
  background-position: 10%, 90%;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 3px;
  color: #485257;
  width: unset;
  cursor: pointer;
}

.filter-container-box input {
  background: transparent;
  border: unset;
  margin-left: 36px;
  width: 240px;
  box-shadow: unset !important;
  cursor: pointer;
  margin-left: 50px;
}
.filter-container-box input:active,
.filter-container-box input:focus,
.filter-container-box input:focus-within,
.filter-container-box input:focus-visible {
  background: transparent;
  border: unset;
  margin-left: 36px;
  width: 240px;
  box-shadow: unset !important;
  cursor: pointer;
}

.start-at-ondemand {
  padding: 24px 0px !important;
  padding-left: 91px !important;
}

/* /left right modal/ */
.modal.left_modal,
.modal.right_modal {
  position: fixed;
  z-index: 99999;
}

.modal.left_modal .modal-dialog,
.modal.right_modal .modal-dialog {
  position: fixed;
  margin: auto;
  width: 90%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.modal-30w {
  max-width: 30% !important;
}
.modal-dialog {
  /* max-width: 100%; */
  margin: 1.75rem auto;
}

@media (min-width: 576px) {
  .left_modal .modal-dialog {
    max-width: 100%;
  }

  .right_modal .modal-dialog {
    max-width: 100%;
  }
}

.modal.left_modal .modal-content,
.modal.right_modal .modal-content {
  /*overflow-y: auto;
	  overflow-x: hidden;*/
  height: 100vh !important;
}

.modal.left_modal .modal-body,
.modal.right_modal .modal-body {
  padding: 15px 15px 30px;
}

/*.modal.left_modal  {
	  pointer-events: none;
	  background: transparent;
  }*/

/* /Left/ */
.modal.left_modal.fade .modal-dialog {
  left: -50%;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left_modal.fade.show .modal-dialog {
  left: 0;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.5);
}

/* /Right/ */
.modal.right_modal.fade .modal-dialog {
  right: -50%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right_modal.fade.show .modal-dialog {
  right: 0;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.5);
}

/* ----- MODAL STYLE ----- */
/* .modal-content {
	border-radius: 0;
	border: none;
  } */

.modal-header.left_modal,
.modal-header.right_modal {
  padding: 10px 15px;
  border-bottom-color: #eeeeee;
  /* background-color: #FAFAFA; */
}

.modal_outer .modal-body {
  /* /height:90%;/ */
  /* overflow-y: auto;
	  overflow-x: hidden;
	  height: 91vh; */
}

/* // */
.fybOzd {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 50px;
  border-bottom: 1px solid rgb(191, 191, 191);
  background: rgb(255, 255, 255);
  pointer-events: auto;
}

.cwNJQZ {
  margin: initial;
  padding: initial;
  border: 1px transparent;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: #d5a67b;
  background-color: transparent;
  display: inline-block;
  width: 44px;
  height: 44px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
}
.cwNJQZ:hover {
  background-color: unset;
}
.eZmLtC {
  position: absolute;
  top: 2px;
}
.iXcSWy {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 100%;
}
.ggZsDW {
  display: inline-block;
  flex-shrink: 0;
  vertical-align: middle;
  transition: transform 0.2s ease-out;
  fill: currentcolor;
  transform: rotate(90deg);
}

.iFZzof {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: var(--fiji-font-size, 18px);
  letter-spacing: 0.02em;
  margin: 0px auto;
  padding: 0px 44px;
  line-height: 50px;
  text-align: center;
}

.gRbkB {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: rgb(255, 255, 255);
  pointer-events: auto;
  height: 100%;
}
.lfhkUh {
  padding: 24px 16px 0px;
}

.QondX {
  align-items: flex-start;
}

.hksPTU {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}

.KhUBn {
  margin-bottom: 24px;
  color: rgb(100, 100, 100);
}

.dUiSGl {
  margin: 0px;
  color: rgb(18, 18, 18);
  font-weight: 800;
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.bottom-line {
  border-bottom: 1px solid rgb(191, 191, 191);
}

@media (max-width: 1000px) {
  .ondemandpractice-wrapper {
    width: 100% !important;
  }
}
@media (max-width: 700px) {
  /* .godemantevent-internal-box {
    flex-direction: column;
  } */
  .modal-30w {
    max-width: 100% !important;
  }
}
@media (max-width: 715px) {
  /* .godemantevent-internal-box {
    flex-direction: column;
  } */

  .ondemand-button-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 12px;
    flex-wrap: wrap;
  }
  .ondemand-button-box a {
    margin-top: unset !important;
  }
}
@media (max-width: 475px) {
  /* .godemantevent-internal-box {
    flex-direction: column;
  } */

  .ondemand-button-box {
    flex-direction: column;
  }
}
@media (max-width: 775px) {
  /* .godemantevent-internal-box {
    flex-direction: column;
  } */
  .ondemandpractice-wrapper-medium-practice-filter-secion {
    height: 225px !important;
  }
  .ondeand-practive-reset-btn {
    margin-top: 10px;
  }
  .ondeand-practive-reset-btn-container{
    width: 100%;
    text-align: right;

  }
}

.ondemand-button-box .go-ondemand-practice-hover .hover-popup-r {
  width: unset;
  min-width: 500px;
  padding: 10px;
}
.go-ondemand-practice-hover .hover-popup-r .mainpre {
  font-size: 12px;
  margin: 5px;
}
.ondemant-i-btn {
  width: 15px;
  position: relative;
  top: -2px;
  left: 3px;
}
.disabled-btn-join {
  background: gainsboro !important;
  color: white !important;
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed !important;
}
.disabled-btn-join:hover {
  background: gainsboro;
  color: white;
  cursor: pointer;
}
.no-app-fount-box {
  margin: 15px;
  padding: 40px 10px;
  background: #80808030;
  border: 1px solid;
}

.white-border {
  border: 2px solid white;
}
.white-bg-btn {
  background: white;
  color: #d5a67b;
  border: 2px solid #d5a67b;
}

.globe-gif-wrapper {
  margin: 0 auto;
  max-width: 70%;
}
@media (max-width: 600px) {
  .globe-gif-wrapper {
    margin: 0 auto;
    max-width: 90%;
  }
}
@media (max-width: 900px) {
  .width-55 {
    width: 50% !important;
  }
  .godemantevent-date-button-box-before {
    display: none !important;
  }
  /* .ondemandpractice-wrapper-medium-practice {
    width: 100% !important;
  } */
}
@media (max-width: 1200px) {
  .ondemandpractice-wrapper-medium-practice {
    width: 100% !important;
  }
}
@media (min-width: 1201px) and (max-width: 1550px) {
  .ondemandpractice-wrapper-medium-practice {
    width: 100% !important;
  }
}
.globe-gif-wrapper-margins {
  margin-top: -50px;
}

.globe-gif-wrapper-padd {
  padding-bottom: 60px;
  padding-top: 20px;
}

.choose-medium button,
.choose-medium span {
  font-size: 24px !important;
  width: 170px !important;
}

/* .gap-20 {
  gap: 20px;
} */
.margin-12px {
  margin: 12px 0;
}

.min-height-50vh {
  min-height: 50vh !important;
}
.globe-image {
  border: 10px solid white;
  outline: 1px solid #d5a67b;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 1px #d5a67b;
}
.back-btn-go-ondemand-practice {
  border: 2px solid #c08c5c !important;
  border-radius: 32px !important;
  color: #c08c5c !important;
  height: 45px !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
}
.border-radius-40 {
  border-radius: 40px !important;
}
.pad-30 {
  padding: 0px 30px 30px 30px;
}
.padd-heading {
  padding: 15px 20px 5px 20px;
  background: #f8ecdc;
  font-weight: bold;
}
.margin-left-unset {
  margin-left: unset !important;
}
.pad-bottom-30 {
  padding-bottom: 30px !important;
}
.font-style-italic {
  font-style: italic;
  color: #00000085;
}
.godemantevent-date-button-box-before {
  background-color: #c08c54;
  width: 1px;
  display: block;
  border-radius: 72px;
}

.godemantevent-date-button-box {
  display: flex !important;
  flex-direction: row !important;
}
.w-25-px {
  width: 25px;
}
.ondemand-diabled {
  background-color: #e8d4bc !important;
}
.ondemand-calendar-box {
  background-image: url(/ondemand-icons/calendar-icon.png);
  background-repeat: no-repeat;
  height: 75px;
}
.ondemand-calendar-box-internal {
  padding-top: 20px;
  padding-left: 15px;
  /* text-align: center; */
  width: 65px;
}
.cross-icon-popup {
  position: relative;
}
.cross-icon-popup > img {
  width: 20px;
  position: absolute;
  top: -25px;
  right: -25px;
  cursor: pointer;
}
.color-d5a67b-qw:hover {
  color: #fff !important;
}

/* // */

.custom-select-goondemand {
  background-color: #ffffff;
  border-radius: 8px;
  min-width: 250px;
}

.custom-select-goondemand  select {
  text-align: center;
  border-radius: 3px;
  appearance: none;
  background: url(/images/dropdown.png) no-repeat right 10px center;
  height: 100%;
  width: 100%;
  background-position: 88% 50%;
  text-align-last: center;

}

.select-box-goondemand {
  height: 44px;
  position: relative;
  width: 100%;

}

.select-box-goondemand::before {
  content: "";
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  background: url(/images/filter.png) no-repeat center;
  background-size: contain;
  pointer-events: none;
  height: 100%;
  width: 15px;
}

.select-box-goondemand::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  /* background: url('after.jpg') no-repeat center; */
  background-size: cover;
  pointer-events: none;
}

/* Dropdown styling */
.select-box-goondemand select option {
  padding: 10px;
}
@media (max-width: 767px) {
  .custom-select-goondemand ,.custom-select-goondemand-wrapper{
    width: 100%;
    margin-bottom: 5px;
}
.select-box-goondemand::before{
  left: 63px;

}
.custom-select-goondemand select {
  background-position: 90% 50%;
}
.ondemandpractice-wrapper-medium-practice-filter-secion .search-box .search-input{
  padding: 0 10px 0px 80px;
}
.ondemandpractice-wrapper-medium-practice-filter-secion .search-container input{
  background-position: 9%;
}
}
.ondemandpractice-wrapper-medium-practice-filter-secion .search-box{
  width: 260px;
}
@media screen and (min-width: 1025px) and (max-width: 1167px) {
  .ondemandpractice-wrapper-medium-practice-filter-secion{

    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 1168px) and (max-width: 1248px) {

  .ondemandpractice-wrapper-medium-practice-filter-secion {
        height: 120px !important;

  }
  .ondeand-practive-reset-btn {
    margin-top: 10px;
  }
  .ondeand-practive-reset-btn-container{
    width: 100%;
    text-align: right;

  }
}
@media screen and (min-width: 882px) and (max-width: 959px) {

  .ondemandpractice-wrapper-medium-practice-filter-secion {
        height: 120px !important;

  }
  .ondeand-practive-reset-btn {
    margin-top: 10px;
  }
  .ondeand-practive-reset-btn-container{
    width: 100%;
    text-align: right;

  }
}
@media screen and (min-width: 1025px) and (max-width: 1120px) {

    .filter-container-box input{
      padding-left: 30px;
    }

}
@media screen and (min-width: 776px) and (max-width: 881px) {

  .filter-container-box input{
    padding-left: 30px;
  }
  .ondemandpractice-wrapper-medium-practice-filter-secion{

    flex-wrap: nowrap !important;
  }
}
@media (min-width: 768px) and (max-width: 775px) {
  .search-box {
    width: 100% !important;
    margin-bottom: 5px;
}
  .custom-select-goondemand ,.custom-select-goondemand-wrapper{
    width: 100%;
    margin-bottom: 5px;
}
.filter-wrapper {
  display: inline-block !important;
  width: 100% !important;
}
  .ondemandpractice-wrapper-medium-practice-filter-secion {
    flex-wrap: wrap !important;
}
  .ondeand-practive-reset-btn {
    margin-top: 10px;
  }
  .ondeand-practive-reset-btn-container{
    width: 100%;
    text-align: right;

  }
}
@media (min-width: 1551px) and (max-width: 1654px) {

  .ondemandpractice-wrapper-medium-practice-filter-secion {
    height: 125px !important;
  }
  .ondeand-practive-reset-btn {
    margin-top: 10px;
  }
  .ondeand-practive-reset-btn-container{
    width: 100%;
    text-align: right;

  }
}
.ondemandpractice-wrapper-medium-practice-loader{
  /* background: black; */
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 9;

}
.ondemandpractice-wrapper-medium-practice-spinner{

      z-index: 9999;
      width: 100%;
      height: 100%;
      display: flex
  ;
      align-items: center;
      justify-content: center;
      top: 0;
      background-color: #f8ecdc94 !important;
}

.ondeand-practive-reset-btn ,.ondeand-practive-reset-btn:hover{
  width: 70px !important;
    height: 39px;
    min-height: 30px;
    padding: 0px;
    font-size: 15px;
    color: rgb(255, 255, 255);
    background-color: #d5a67b;
    border-radius: 6px;
    border: 1px solid transparent;
}
