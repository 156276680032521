.schedule-advance-saction {
  display: inline-block;
  width: 100%;
  margin: 40px 0;
}
.schedule-advance-saction h3 {
  display: inline-block;
  width: 100%;
  color: #2f2f2f;
  font-family: "Oakes-Grotesk-Medium";
  font-weight: 600;
  margin: 0;
}
.list-medium-box-right {
  display: inline-block;
  width: 100%;
  margin: 18px 0 0 0;
  padding: 19px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 33px #e0e4ee;
  border-radius: 5px;
  min-height: 720px;
  max-height: 90vh;
  overflow-y: scroll;
  box-sizing: content-box;
}
.list-medium-box-right ul {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
}
.list-medium-box-right ul li {
  padding: 15px 17px;
  background-color: #fff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  margin: 0 0 15px 0;
  width: 100%;
  display: inline-block;
}
.medium-lit-bution {
  float: right;
  width: 73px;
  height: 76px;
  line-height: 67px;
}
.box-list-medium .medium-lit-profile {
  float: left;
  width: 75%;
}
.profile-list-img {
  float: left;
  width: 85px;
  height: 85px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #d5a67b;
  padding: 3px 3px;
}
.profile-list-img img {
  width: 100%;
  border-radius: 50%;
}
.profile-list-bio {
  float: right;
  width: 60%;
}
.profile-list-bio span {
  color: #000;
  font-size: 18px;
  font-family: "Oakes-Grotesk-Medium";
  font-weight: 600;
}
.profile-list-bio h6 {
  display: inline-block;
  margin: 5px 0 0 0;
  width: 100%;
}
.profile-list-bio h6 em {
  float: left;
  font-style: normal;
  border-radius: 300px;
  width: 53px;
  height: 23px;
  background-color: rgba(255, 193, 7, 0.14);
  font-size: 13px;
  font-family: "Oakes-Grotesk-Regular";
  font-weight: 400;
  color: #000;
}
.profile-list-bio h6 em img {
  width: 10px;
  margin: 7px 6px 0 9px;
}
.profile-list-bio h6 i {
  font-style: normal;
  color: #000;
  font-size: 11px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
  margin: 0 0 0 7px;
}
.profile-list-bio h5 {
  color: #000;
  font-size: 13px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
  margin: 8px 0 0 0;
  display: inline-block;
  width: 100%;
}
.profile-list-bio h5 img {
  margin: 0px 5px 0 0;
  position: relative;
  top: 3px;
}
.medium-lit-bution a {
  width: 100%;
  display: inline-block;
  border: 1px solid #4e64dd;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
  line-height: 20px;
  height: 100%;
  padding: 18px 0;
  text-decoration: none !important;
}
.medium-lit-bution a:hover {
  background-color: #4e64dd;
  color: #fff;
}
.medium-lit-bution-selected a {
  background-color: #4e64dd;
  color: #fff;
}

.new-appointment-listt .prgress-baar-new {
  width: 100%;
}
.new-appointment-listt .prgress-baar-new::after {
  /* content: "100%";
  right: 4px; */
}
.continue .dont-have-acc {
  margin: 0;
  text-align: right;
}
.continue .dont-have-acc .btn.btn-info.btn-lg {
  padding: 0 !important;
  width: 243px;
  height: 58px;
  line-height: 58px;
  background-color: #4e64dd;
  border-radius: 5px;
  color: #fff;
}
.continue .dont-have-acc .btn.btn-info.btn-lg img {
  margin: 0 0 0 10px;
  position: relative;
  top: 0px;
}
.continue .modal-dialog::before {
  display: none;
}
.continue .modal-dialog {
  max-width: 606px !important;
  height: 454px;
  background-color: #fff;
}
.continue .modal-dialog .modal-body {
  padding: 42px 0 56px;
  text-align: center;
  background-color: #fff !important;
  border-radius: 0;
}
.continue .modal-dialog .schedule-popup h5 {
  display: inline-block;
  width: 100%;
  color: #485257;
  font-size: 24px;
  font-weight: 400;
  margin: 32px 0 0 0;
  font-family: "Oakes-Grotesk-Medium";
  line-height: 35px;
}
.schedule-popup span {
  margin: 10px 0 0;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  font-family: "Oakes-Grotesk-Light";
}
.schedule-popup p {
  color: #000;
  font-size: 18px;
  font-family: "Oakes-Grotesk-Light";
  margin: 8px 0 0 0;
  display: inline-block;
}
.schedule-popup a {
  box-sizing: border-box;
  display: inline-block;
  margin: 40px 0 0 0;
  background-color: #d5a67b !important;
  width: 300px !important;
  height: 61px !important;
}
.dummy-text {
  display: inline-block;
  text-align: center;
  width: 100%;
  color: #ececec;
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0 0 0;
}
.dummy-box-list {
  text-align: center;
  padding: 15rem 0;
}
.paidppractice .btn.btn-info.btn-lg {
  border: 1px solid #d5a67b;
  background-color: transparent;
  color: #d5a67b;
}
.paidppractice .btn.btn-info.btn-lg:hover {
  background-color: #d5a67b;
  color: #fff;
}
.page-heading h6 {
  font-size: 24px;
  font-weight: 600;
  font-family: "Oakes-Grotesk-Medium";
}
.page-heading a {
  font-size: 14px;
  font-weight: 600;
  margin: 0 26px;
  color: #4e64dd;
}
.dash-head {
  margin: 40px 0;
}
.page-hd img {
  margin: 0 5px;
}
.page-text h6 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.clandel-scn-two p {
  font-size: 24px;
  font-weight: 600;
  color: #ececec;
  text-align: center;
}
.check-scn h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 0 0 27px 0;
}
.check-scn {
  background: #ffffff;
  padding: 15px;
  overflow: auto;
  border: 1px solid #cacaca;
  border-radius: 5px;
}
.button-container-calendar button {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  color: #fff;
  background-color: transparent !important;
  border: none;
  padding: 0;
}
.table-calendar {
  border-collapse: collapse;
  width: 100%;
}
.table-calendar td,
.table-calendar th {
  text-align: center;
  vertical-align: top;
  font-size: 18px;
  height: 42px;
  font-weight: 500;
  vertical-align: middle;
  line-height: 22px;
  color: #485257;
}
.calendar-td {
  /* display: inline-flex; */
  align-items: center;
  justify-content: center;
  /* width: 100%; */
}
.date-picker div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 55px;
  min-height: 55px;
  position: relative;
}
.date-picker .orange-bulb {
  background: orange;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-size: 12px;
  position: absolute;
  margin-left: 48px;
}
.date-picker .selected {
  /* background: #D5A67B;
	border-radius: 50px;
	width: 55px;
	color: #fff;
	height: 55px; */
  background: #d5a67b;
  border-radius: 50%;
  width: 50px;
  color: #fff;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.date-picker div span:hover{
background-color: unset !important;
}
.date-picker div span:hover,
.td-clicked {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  border: 1px solid #d3d3d36b;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #d3d3d36b;
}
.td-clicked-active-date {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  /* background-color: #d5a67b; */
  border: 1px solid lightgray;;
  /* color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
/* .date-picker span:hover{
 width: 55px;
 height: 55px;
 display: flex;
 align-items: center;
 justify-content: center;
 	border: 1px solid #D5A67B;

} */

#monthAndYear {
  text-align: center;
  border-bottom: 1px solid #cacaca;
}
.container-calendar {
  display: inline-block;
  width: 100%;
  position: relative;
  border: 1px solid #cacaca;
  border-radius: 5px;
  padding: 0 34px 15px;
}
.table-calendar td {
  padding: 15px 0;
}
#previous {
  position: absolute;
  top: 22px;
  left: 15rem;

  margin: 0 auto;
}

#next {
  position: absolute;
  top: 22px;

  right: 15rem;
  margin: 0 auto;
}
.footer-container-calendar {
  display: none;
}

.footer-container-calendar select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #ffffff;
  color: #585858;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}
.calenl-scn h3 {
  font-size: 20px;
  font-weight: 600;
  color: #485257;
  padding: 27px 0;
  display: inline-block;
  position: relative;
}
thead#thead-month th {
  font-size: 14px;
  font-weight: 500;
  color: #485257;
  text-transform: uppercase;
}
.calenl-scn {
  background: #ffffff;
  box-shadow: 0px 0px 33px #e0e4ee;
  border-radius: 5px;
  margin: 22px 0 0 0;
  padding: 23px 20px;
}
.clandel-scn-two {
  background: #ffffff;
  box-shadow: 0px 0px 33px #e0e4ee;
  border-radius: 5px;
}
.clandel-scn-two {
  background: #ffffff;
  box-shadow: 0px 0px 33px #e0e4ee;
  border-radius: 5px;
  height: 600px;
  width: 374px;
}
.tim-clander-shedul {
  display: inline-block;
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 5px;
  margin: 19px 0 0;
  padding: 22px 26px;
}
.form-l---r .chosen-container-active ul {
  overflow: scroll !important;
  overflow-x: inherit !important;
}
.tim-clander-shedul h4 {
  margin: 0 0 26px 0;
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #485257;
  font-size: 20px;
  font-weight: 600;
  font-family: "Oakes-Grotesk-Regular";
}
.tim-clander-shedul input::before {
  display: none;
}
.tim-clander-shedul input::after {
  display: none;
}
.tim-clander-shedul label {
  padding: 0 0 !important;
  width: 100% !important;
  text-align: center;
  height: 36px;
  line-height: 36px;
  color: #000000;
  font-weight: 500 !important;
  font-family: "Oakes-Grotesk-Medium";
}
.tim-clander-shedul .check-field {
  margin-right: 14px;
}
.tim-clander-shedul .check-field {
  width: 159px !important;
  margin-bottom: 12px;
}
.tim-clander-shedul .check-field-coaching {
  width: 230px !important;
  margin-bottom: 12px;
}

/*and-schedule-advance*/

@media (max-width: 600px) {
  .td-clicked-active-date {
    width: 30px;
    height: 30px;
  }
  .date-picker div {
    min-width: unset !important;
  }
  .calendar-view .table-calendar td {
    padding: unset !important;
  }
  .date-picker div span:hover,
  .td-clicked {
    width: 30px;
    height: 30px;
  }
}
