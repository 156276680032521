.toogle-nav {
  display: none !important;
}

.close-btn {
  font-size: 25px;
  position: absolute;
  right: 0;
  padding: 28px;
  /* display: none; */
}

@media only screen and (min-width: 800px) {
  .toogle-nav {
    display: block !important;
  }
  /* .close-btn {
    display: none !important;
  } */
}
@media only screen and (min-width: 600px) and (max-width:800px) {
  .close-btn {
    display: block !important;
  }
}

@media only screen and (min-width:801px) {
  .close-btn {
    display: none !important;
  }
}