@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100&family=Libre+Baskerville:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;200;300;400;500;600;700;800;900&display=swap'); 

.header-saction {
	display: inline-block;
	width: 100%;
	margin: 0;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 999;
	top: 0;
	padding: 30.1px 0 0 0;
}
.header-saction .navbar.navbar-expand-lg.navbar-light.bg-light {
	padding: 0;
	background-color: transparent !important;
}
.header-saction #navbarSupportedContent {
	/* display: inline-block !important; */
	width: 100%;
	flex-basis: inherit;
	text-align: right;
}
.header-saction #navbarSupportedContent .navbar-nav.mr-auto {
	display: inline-block;
	width: 100%;
	text-align: right;
}
.header-saction ul li {
	display: inline-block;
	width: auto;
	margin: 0 0 0 42px;
}
.header-saction ul li a {
	padding: 0 !important;
	font-size: 18px;
	font-weight: 300;
	font-family: "Oakes-Grotesk-Regular";
	color: #fff !important;
}
.sign-up {
	width: 101px;
	height: 43px;
	display: inline-block;
	border: 1px solid #D5A67B;
	text-align: center;
	line-height: 43px;
	color: #D5A67B !important;
	border-radius: 5px;
}
.login {
	background-color: #D5A67B;
	display: inline-block;
	width: 87px;
	height: 43px;
	text-align: center;
	line-height: 43px;
	border-radius: 5px;
}
.banner-saction {
	display: inline-block;
	width: 100%;
	text-align: center;
	background-image: url(/images/banner.png);
	padding: 227px 0 0 0;
}
.banner-saction h2 {
	color: #fff;
	font-size: 44px;
	font-family: 'Libre Baskerville', serif;
	width: 100%;
	display: inline-block;
}
.banner-saction p {
	display: inline-block;
	width: 100%;
	margin: 20px 0 0 0;
	color: #fff;
	font-family: 'Be Vietnam Pro', sans-serif;
	font-size: 20px;
	font-weight: 1;
}
.banner-saction a {
	width: 287px;
	height: 55px;
	display: inline-block;
	background-color: #D5A67B;
	line-height: 55px;
	text-align: center;
	border-radius: 5px;
	color: #fff;
	font-size: 20px;
	font-weight: 500;
	margin: 36px 0 0 0;
}
.banner-saction img {
	margin: 81px 0 0 0;
	position: relative;
	top: 4px;
}
.how-it-works {
	display: inline-block;
	width: 100%;
	background-color: #f7f8f3;
	text-align: center;
	padding: 113px 0 97px 0;
}
.how-it-works h2 {
	display: inline-block;
	width: 100%;
	font-size: 44px;
	color: #485257;
	font-weight: 700;
	font-family: 'Libre Baskerville', serif;
}
.how-it-works p {
	margin: 17px 0 0 0;
	font-size: 20px;
	font-weight: 300;
	font-family: 'Be Vietnam Pro', sans-serif;
	display: inline-block;
	width: 100%;
	line-height: 30px;
}
.how-it-works-box {
	display: inline-block;
	margin: 76px 0 0 0;
	width: 100%;
}
.how-it-works-inner {
	display: inline-block;
	width: 100%;
	text-align: center;
}
.how-it-works-inner span {
	display: inline-block;
	border: 1px solid #D5A67B;
	width: 79px;
	height: 79px;
	border-radius: 50px;
	line-height: 79px;
	font-size: 32px;
	color: #D5A67B;
	font-family: 'Libre Baskerville', serif;
	font-weight: 700;
}
.how-it-works-inner h4 {
	display: inline-block;
	width: 100%;
	border-top: 1px solid #DDDDDD;
	margin: 25px 0 0 0;
	padding: 25px 0 0 0;
	font-size: 23px;
	color: #485257;
	font-family: 'Libre Baskerville', serif;
	font-weight: 700;
}
.how-it-works-inner p {
	margin: 11px 0 0 0;
	font-size: 16px;
	line-height: 20px;
}
.our-story-box {
	display: inline-block;
	width: 100%;
	margin: 146px 0 0 0;
	background-color: #fff;
	border: 4px solid #D5A67B;
	box-shadow: 0px 17px 56px rgba(0, 0, 0, 0.11);
	border-radius: 21.78px;
	padding: 73px 61px;
}
.our-story-box-left {
	float: left;
	width: 48%;
	text-align: left;
}
.our-story-box-right {
	float: right;
	width: 48%;
	text-align: right;
}
.our-story-box-left h3 {
	color: #485257;
	font-size: 44px;
	font-family: 'Libre Baskerville', serif;
	font-weight: 700;
	width: 100%;
	display: inline-block;
	margin: 0 0 36px 0;
}
.our-story-box-left strong {
	color: #485257;
	font-size: 20px;
	font-family: 'Be Vietnam Pro', sans-serif;
	font-weight: 400;
	line-height: 29px;
}
.our-story-box-left p {
	line-height: 29px;
	margin: 30px 0 0 0;
}
.our-story-box-right img {
	margin-top: 26px;
}
.our-global-presence {
	display: inline-block;
	width: 100%;
	padding: 128px 0 278px;
	background-image: url(/images/video-bg.png);
	background-size: cover;
	background-position: 100%;
}
.our-global-presence span {
	text-align: center;
	width: 100%;
	display: inline-block;
	color: #fff;
	font-size: 16px;
	font-family: 'Be Vietnam Pro', sans-serif;
	font-weight: 30;
}
.our-global-presence h2 {
	display: inline-block;
	width: 100%;
	text-align: center;
	color: #fff;
	font-size: 44px;
	margin: 10px 0 0 0;
	font-family: 'Libre Baskerville', serif;
	font-weight: 700;
}
.our-global-presence .row {
	margin-top: 99px;
}
.our-global-presence-iiner {
	text-align: left;
	width: 100%;
	display: inline-block;
}
.our-global-presence-iiner h4 {
	color: #fff;
	font-size: 44px;
	font-family: 'Libre Baskerville', serif;
	font-weight: 700;
	display: inline-block;
	width: 100%;
}
.our-global-presence-iiner h4 i {
	font-style: normal;
	color: #D5A67B;
	text-decoration: underline;
	font-family: 'Libre Baskerville', serif;
}
.our-global-presence-iiner p {
	color: #fff;
	margin: 21px 0 0 0;
	display: inline-block;
	width: 100%;
	font-family: 'Be Vietnam Pro', sans-serif;
	font-weight: 4;
	line-height: 20px;
}
.numbar {
	display: inline-block;
	width: 100%;
	padding: 0;
	text-align: left;
	margin: 43px 0 0 0;
}
.numbar li {
	display: inline-block;
	width: 137px;
	margin: 0 14px 0 0;
	border: 1px solid #D5A67B;
	border-radius: 7px;
	overflow: hidden;
}
.numbar li span {
	background-color: #D5A67B;
	padding: 22px 8px;
	font-family: 'Libre Baskerville', serif;
	font-size: 44px;
	font-weight: 700;
}
.numbar li i {
	display: inline-block;
	width: 100%;
	text-align: center;
	font-style: normal;
	padding: 13px 0;
	color: #fff;
	font-size: 14px;
	font-family: 'Be Vietnam Pro', sans-serif;
	font-weight: 3;
}
.get-started-with-verySoul {
	display: inline-block;
	width: 100%;
	text-align: center;
	background-color: #D5A67B;
	padding: 138px 0;
}
.get-started-with-verySoul h2 {
	display: inline-block;
	width: 100%;
	color: #fff;
	font-family: 'Libre Baskerville', serif;
	font-size: 44px;
	font-weight: 700;
}
.get-started-with-verySoul p {
	display: inline-block;
	width: 100%;
	color: #fff;
	font-size: 18px;
	font-family: 'Be Vietnam Pro', sans-serif;
	font-weight: 3;
	margin: 18px 0 20px 0;
	line-height: 23px;
}
.signup-get {
	display: inline-block;
	border: 1px solid #FFFFFF;
	width: 101px;
	height: 43px;
	line-height: 43px;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
	font-family: 'Be Vietnam Pro', sans-serif;
	margin: 0px 10px 0 0;
	position: relative;
	top: 3px;
}
.login-get {
	display: inline-block;
	background-color: #fff;
	font-family: 'Be Vietnam Pro', sans-serif;
	width: 101px;
	height: 45px;
	line-height: 43px;
	border-radius: 5px;
	color: #D5A67B;
	text-transform: uppercase;
	font-weight: 600;
}
.footer-saction-home {
	display: inline-block;
	width: 100%;
	text-align: center;
	padding: 50px 0;
	margin: 66px 0 0 0;
}
.footer-saction-home ul {
	display: inline-block;
	width: 100%;
	padding: 0;
	margin: 30px 0 0 0;
}
.footer-saction-home p {
	display: inline-block;
	width: 100%;
	margin: 20px 0 0 0;
	font-size: 23px;
	font-family: 'Libre Baskerville', serif;
	font-weight: 400;
}
.footer-saction-home ul li {
	display: inline-block;
	width: auto;
	margin: 0 8px;
	color: #D5A67B;
}
.footer-saction-home ul li a {
	display: inline-block;
	color: #485257;
	font-size: 18px;
	font-family: 'Be Vietnam Pro', sans-serif;
}
.footer-saction-home span {
	display: inline-block;
	width: 100%;
	margin: 30px 0 0 0;
	color: #485257;
	font-family: 'Be Vietnam Pro', sans-serif;
	font-size: 14px;
}
a {
	text-decoration: inherit !important;
}

@media only screen and (max-width: 992px) {
.our-story-box{
	display: flex;
    flex-direction: column;
    gap: 15px;
}
.our-story-box-left ,.our-story-box-right{
	float: unset !important;
    width: 100% !important;
    text-align:center !important;	
}
.our-global-wrapper{
	flex-direction: column;
}
.our-global-presence-inner{
	margin-top: 20px;
}
.our-global-presence-iiner{
	text-align: center;
}
.numbar{
	text-align: center;
}
}



