.question-container #msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

.question-container #msform fieldset {
  background: #fff !important;
  border: 0 none;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  position: relative;
  border-radius: 10px !important;
  box-shadow: 0 0 13px 0 #dcdcdc;
  margin: 0 !important;
  padding: 30px;
}

.question-container .form-card {
  text-align: left;
  margin-bottom: 40px;
}
.question-container .form-card .row {
  margin: 0;
}
.question-container .form-card h3 {
  font-size: 17px;
  display: inline-block;
  width: 100%;
  margin: 12px 0;
  font-weight: 400;
}

.question-container #msform fieldset:not(:first-of-type) {
  display: none;
}

.question-container #msform input,
.question-container #msform textarea {
  padding: 8px 15px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  background-color: #eceff1;
  font-size: 16px;
  letter-spacing: 1px;
}

.question-container #msform input:focus,
.question-container #msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #d5a67b;
  outline-width: 0;
}
.question-container #msform .action-button {
  width: 120px;
  background: #d5a67b;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 4px;
  cursor: pointer;
  padding: 20px 5px;
  float: right;
  margin: 0 !important;
  height: auto;
}

.question-container #msform .action-button-previous {
  width: 120px;
  background: #fff;
  font-weight: bold;
  color: #d5a67b;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 20px 5px;
  margin: 0;
  float: left;
  border: 1px solid #d5a67b;
  border-radius: 4px;
  height: auto;
}
.question-container .form-card a {
  color: #4e73df;
  font-weight: 600;
  font-size: 18px;
}

.question-container .card {
  z-index: 0;
  border: none;
  position: relative;
}
.question-container .fs-title {
  font-size: 25px;
  color: #673ab7;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}
.question-container .purple-text {
  color: #673ab7;
  font-weight: normal;
}
.question-container .new-appointment-listt .row.justify-content-center {
  margin: 0 !important;
}

.question-container .steps {
  font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right;
}
.question-container .fieldlabels {
  color: gray;
  text-align: left;
}

.question-container .form-group {
  display: block;
  margin-bottom: 30px;
  position: relative;
  margin-left: 25px;
}

.question-container .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.question-container .form-group label {
  position: relative;
  cursor: pointer;
}

.question-container .form-group label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #d5a67b;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 0;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  left: 0;
  top: 6px;
}
.question-container .form-group input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 6px;
  height: 14px;
  border: solid #d5a67b;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.question-container .form-card h2 {
  color: #2f2f2f;
  font-size: 18px;
  font-weight: 700;
  /* font-family: ; */
  display: inline-block;
  width: 100%;
}
.question-container .form-card .new {
  margin: 20px 0 0 0;
  display: inline-block;
  width: 100%;
}
.question-container .form-card .new label {
  line-height: 19px;
  font-weight: 500;
  font-size: 15px;
  padding: 12px 0 0 40px;
  margin: 0;
}
.question-container .wrap {
  flex-wrap: wrap !important;
}
.text-underline {
  text-decoration: underline !important;
}

/********filter ***/

.filter-image {
}
.filter-image img {
  width: 25px;
  cursor: pointer;
}
.cross-icon-image img {
  width: 27px;
  cursor: pointer;
}
.appointment-select-tab-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bookin-new-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.bookin-new-btn span:first-child {
  position: absolute;
  z-index: 17;
  left: 0;
}
.bookin-new-btn span:last-child {
  position: absolute;
  z-index: 15;
  right: 0;
}
.bookin-new-btn .second-child {
  position: absolute;
  right: 67px;
  margin-bottom: unset !important;
  z-index: -1;
}
.bookin-new-btn .first-child {
  position: absolute;
  left: 68px;
  margin-bottom: unset !important;
  z-index: -1;
}
/********filter ***/
@media (min-width: 767px) {
  .filter-image {
    display: none;
  }
}

@media (max-width: 767px) {
  .svroll-arrow {
    display: block !important;
  }
  .filter-box-professional {
    position: absolute;
    width: 300px;
    right: 5px;
    z-index: 1;
    margin-top: -70px;
  }
  .cross-icon-image img {
    position: absolute;
    right: 0px;
    margin-top: -18px;
    z-index: 2;
  }
  .filter-box-professional-inner {
    box-shadow: 1px 1px 14px 0px black !important;
    padding: 5px;
  }
  .question-container .card {
    margin: 0 !important;
    padding: 0 !important;
  }
  .question-container #msform fieldset {
    padding: 15px;
  }
  .question-container .form-card h2 {
    color: #2f2f2f;
    font-size: 14px;
    font-weight: 500;
  }
  .question-container .form-card .new {
    margin: 15px 0 0 0;
  }
  .question-container .form-card .new label {
    line-height: 16px;
    font-size: 12px;
    padding: 0 0 0 34px;
    position: relative;
  }
  .question-container .form-group label::before {
    width: 22px;
    height: 22px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 2px;
  }
  .question-container #msform .action-button-previous {
    width: 100px;
    padding: 12px 0px;
    margin: 0 !important;
    border-radius: 4px;
    height: auto;
    font-size: 13px;
    line-height: inherit;
  }
  .question-container #msform .action-button {
    width: 100px;
    background: #d5a67b;
    padding: 12px 0;
    margin: 0 !important;
    height: auto;
    font-size: 14px;
  }
  .question-container .form-card {
    margin-bottom: 20px;
  }
  .question-container .form-group input:checked + label::after {
    left: 9px;
    width: 5px;
    height: 12px;
    top: 5px;
  }
  .question-container .form-card h3 {
    font-size: 12px;
    margin: 7px 0;
  }
  .question-container .secend-step label {
    padding-top: 7px !important;
    font-size: 12px !important;
  }
  .question-container .form-card a {
    color: #4e73df;
    font-weight: 600;
    font-size: 14px;
    margin: 7px 0;
  }
}
.svroll-arrow {
  display: none;
  position: fixed;
  width: 40px;
  right: 24px;
  top: 90%;
  cursor: pointer;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
.advance-notice {
  text-align: left;
  margin-left: 60px;
  margin-right: 60px;
  border: 1px solid black;
  padding: 10px;
  margin-top: 32px !important;
  border-radius: 0.3rem;
}

.advance-notice h5 {
  margin-top: unset !important;
}
.checkbooktime .modal {
  top: 20% !important;
}

.subsession-box {
  max-width: 400px;
  margin: 0 auto;
  min-width: 400px;
  font-size: 18px;
  background: #d5a67b;
  color: white;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
}
.scrollable {
  position: fixed;
  z-index: 1;
  transition: 200ms linear;
  background-color: #2f2f2f;
  top: 0;
  color: white;
}
.practioner-tab-appointment-tab-dummy {
  height: 22.9px;
  display: block;
}
.practioner-tab-appointment-tab-dummy-img {
  height: 172px;
  display: inline-block;
}

.starter-w {
  width: 100% !important;
}
.starter-w li {
  /* width: 45% !important; */
  height: unset !important;
}
.starter-w li button {
  width: 100% !important;
}

.starter-w li a .icon-select {
  /* height: 70px !important; */
}
.starter-w ul li {
  height: 320px !important;
}
.starter-w ul li a .icon-select {
  height: 135px !important;
}

.starter-w .practioner-tab-appointment-tab-dummy-img {
  height: 37px;
}
.cancel-btn-check-popup-wraper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
.cancel-btn-check-popup {
  background: #fff;
  font-weight: bold;
  color: #d5a67b;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 20px 5px;
  margin: 0;
  float: left;
  border: 1px solid #d5a67b;
  border-radius: 4px;
  height: auto;
  min-width: 240px;
  font-size: 16px;
}

.try-again-book-btn {
  background: #d5a67b;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 4px;
  cursor: pointer;
  padding: 20px 5px;
  float: right;
  margin: 0 !important;
  height: auto;
  min-width: 240px;
  font-size: 16px;
}
.cancel-btn-check-popup:hover {
  background: #fff;
  color: #d5a67b;
}
.try-again-book-btn:hover {
  background: #d5a67b;
  color: white;
}
.outline-offset-5px{
  outline-offset: 5px;
}