
.mainHeading{
    font-size: 24px;
}
.mainpre{
  font-size: 20px;
  line-height: 1.5;
  text-align: justify;
}
.main-sub-heading {
  font-size: 22px;
}
.main-top-pre {
  font-size: 20px;
}
.logo-info{
  width: 20px;
}



/* @media only screen and (min-width: 1340px) and (max-width: 1399px) {
  .hoverContent {
    margin-left: -60px;
}
}
@media only screen and (min-width: 1400px) and (max-width: 1449px) {
  .hoverContent {
    margin-left: -80px;
}
}
@media only screen and (min-width: 1450px) and (max-width: 1550px) {
  .hoverContent {
    margin-left: -85px;
}
}
@media only screen and (min-width: 1551px) and (max-width: 1700px) {
  .hoverContent {
    margin-left: -123px;
}
}
@media only screen and (min-width: 1701px) and (max-width: 1850px) {
  .hoverContent {
    margin-left: -162px;
}
}
@media only screen and (min-width: 1875px) and (max-width: 1919px) {
  .hoverContent {
    margin-left: -210px;
}
}
@media only screen and (min-width: 1920px) and (max-width: 2100px) {
  .hoverContent {
    margin-left: -222px;
}
} */

.hover-container {
  position: relative;
}

.hover-target {
  position: relative;
  
}

@media (max-width: 760px) {
  .hover-popup-r {
    left: -10%;
    width: 30ch;
  }
}
@media only screen and (min-width: 761px) and (max-width: 1299px) {
  .hover-popup-r {
    left: 0%;
    width: 60ch;
  }
}

/* Media query for web (desktop and larger screens) */
@media (min-width: 1300px) {
  .hover-popup-r {
    left: 5%;
    width: 95ch;
  }
}

.hover-popup-r {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 70%;
    margin: min(1rem, 20px);
    font-size: .8rem;
    background-color: #F6F6F6; /* Set background color to light gray */
    border: 1px solid #F6F6F6; /* Add a 1px solid border with light gray color */
    border-radius: 8px;
    padding: 1.5em;
    z-index: 42;
    transform: scale(0);
    transition: transform 200ms ease;
    transform-origin: 8% -10px;
}

.hover-target:hover + .hover-popup-r,
.hover-target:focus + .hover-popup-r,
.hover-popup-r:hover {
  transform: scale(1);
}

.hover-popup-r :not(:first-child) {
  margin-top: 1rem;
}

.hover-popup-r span {
  color: rgb(200, 20, 0);
  font-weight: 700;
}

.hover-popup-r::before {
  /* This is the triangle/arrow */
  content: '';
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  top: -10px;
}

.hover-popup-r::after {
  /* This is merely here to expand the hoverable area, as a buffer between the "Hover me" text and the popup. */
  content: '';
  position: absolute;
  top: -1rem;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media (prefers-reduced-motion: reduce) {
 
}
