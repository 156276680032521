.add-availability-window-box {
  display: inline-block;
  width: 100%;
  padding: 59px 28px 0 0;
}

.hadding-window {
  display: inline-block;
  width: 100%;
  margin: 0;
}

.hadding-window span {
  margin: 0;
  color: #485257;
  font-size: 20px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif !important;
}

.chosse-name-selectde {
  display: inline-block;
  width: 100%;
  margin: 25px 0 0 0;
}

.row-one-step {
  padding: 30px 0 0 !important;
}

.chosse-name-selectde label {
  margin: 0;
  color: #485257;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  width: 100%;
  font-weight: 700;
}

.chosse-name-selectde .select-dropdown,
.select-dropdown * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

.chosse-name-selectde .select-dropdown select {
  font-size: 1rem;
  max-width: 100%;
  padding: 0 10px;
  border: none;
  appearance: none;
  width: 100%;
  border-radius: 5px;
  height: 50px;
  margin: 11px 0 0 0;
  cursor: pointer;
  background-color: #e7e7e7;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
}

.chosse-name-selectde .select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}

/* .chosse-name-selectde .select-dropdown::after {
	content: "";
	position: absolute;
	top: 62%;
	right: 12px;
	width: 13px;
	height: 13px;
	background: url('/images/Iconeroww.png');
	background-repeat: no-repeat;
	background-size: contain;
} */

.row-valid-from {
  display: inline-block;
  width: 100%;
  margin: 30px 0 0 0;
}

.box-valid-from-one {
  float: left;
  width: 20%;
}

.box-valid-from-two {
  display: flex;
  width: 100%;
  text-align: center;
}

.box-valid-from-three {
  float: right;
  width: 20%;
  text-align: right;
}

.box-valid-from-three button {
  color: #d5a67b;
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  font-family: "Montserrat", sans-serif !important;
  margin-top: 11px;
}

.box-valid-from-one span {
  font-size: 18px;
  color: #485257;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  display: inline-block;
  margin: 19px 0 0 0;
}

.box-valid-from-two #filterDate2 {
  display: inline-block;
  width: 20%;
  margin: 0 16px 0 0;
  height: auto;
}

.box-valid-from-two em {
  font-style: normal;
  color: #485257;
  font-size: 18px;
  padding: 11px 19px 0 0;
}

.box-valid-from-two #filterDate2 input {
  border: 1px solid #929292;
  /* border-radius: 0; */
  padding: 10px !important;
  font-size: 13px;
  font-weight: 600;
  color: #000;
  height: 44px;
}
.add-blocked-date-new .d-flex {
  display: flex !important;
  width: 100%;
}
/* #collapse-3 .d-flex.justify-content-between.width-530-px.mb-2.ml-3 {
	margin-bottom: 0 !important;
}
#collapse-3 .add-blocked-date-new .d-flex {
	display: flex;
	width: 100%;
	margin: 0 0 0 9px ;
}
#collapse-3 .span-tag.pointer {
	text-align: left !important;
	width: 100%;
	position: relative;
	bottom: 14px;
} */
.box-valid-from-two span {
  color: #485257;
  font-size: 18px;
  padding: 13px 0 0 0;
}
.row-one-step {
  border-bottom: none !important;
}
.availability-window-table {
  display: inline-block;
  width: 100%;
  margin: 10px 0 0 0;
}

.availability-window-table table {
  width: 100%;
}

.availability-window-table table th {
  background-color: #e7e7e7;
  padding: 24px 92px;
  color: #37474f;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
}

.availability-window-table td {
  text-align: left;
  padding: 28.78px 92px;
  color: #37474f;
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif !important;
  border-bottom: 1px solid #707070;
}

.availability-window-table td .form-group {
  margin: 0;
}

.availability-window-table td .form-group input {
  width: auto;
  height: auto;
}

.availability-window-table td .form-group label {
  font-weight: 400;
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
}

.availability-window-table td .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.availability-window-table td .form-group label {
  position: relative;
  cursor: pointer;
}

.availability-window-table td .form-group label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #707070;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 18.17px;
  border-radius: 5px;
  width: 24.23px;
  height: 24.23px;
}

.availability-window-table td .form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.availability-window-table td .form-group input:checked + label::before {
  background-color: #d6a478;
  border-color: #d6a478;
}

.availability-window-table td input {
  border: 0.5px solid #707070;
  border-radius: 5px;
  width: 125.4px;
  height: 50px;
  padding: 0 7px;
  color: #37474f;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
}
.day-schedule-input {
  border: 0.5px solid #707070;
  border-radius: 5px;
  width: 165px;
  /* width: 125.4px; */
  /* height: 50px; */
  padding: 0 7px;
  color: #37474f;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
}
.availability-window-table td input:hover,
.availability-window-table td input:active {
  border-color: #707070 !important;
}

.availability-window-table td span {
  text-align: center;
  display: inline-block;
  width: 63%;
}

.availability-window-table td a,
.span-tag {
  color: #37474f;
  font-size: 16px;
  text-decoration: underline;
}

.add-availability-window-box .hadding-window a {
  color: #485257;
  font-size: 20px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
}

.add-availability-window-box .hadding-window a img {
  margin: 0 7px 0 0;
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card,
.accordion > .card-modify {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type),
.accordion > .card-modify:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.calendar-box-new .mb-0 > a[aria-expanded="true"]::after {
  content: "";
  background: url("/images/Iconeroww.png");
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotatex(178deg);
  width: 15px;
  top: 8px;
}
.calendar-box-new {
  display: inline-block;
  width: 100%;
  padding: 57px 28px 0 0 !important;
}
.calendar-box-new .card-header {
  padding: 23px 26px;
  background-color: #f5f5f5;
  border: none !important;
  border-radius: 10px !important;
  margin-bottom: 0;
}
.calendar-box-new .card-header h5 a {
  color: #485257;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  width: 100%;
  display: inline-block;
  font-family: "Montserrat", sans-serif !important;
}

.fc-bootstrap4 .fc-popover .card-body {
  padding: 0;
}
.accordion > .card:not(:first-of-type),
.accordion > .card-modify:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header,
.accordion > .card-modify > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.box-valid-from-three .modal {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(77, 77, 77, 0.7);
  transition: all 0.4s;
}

.box-valid-from-three .modal:target {
  visibility: visible;
  opacity: 1;
}

.box-valid-from-three .modal__content {
  border-radius: 0;
  position: relative;
  width: 828px;
  max-width: 100%;
  background: #fff;
  padding: 54px 56px;
  border: 1px solid #707070;
  text-align: left;
}

.box-valid-from-three .modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  text-decoration: none;
  margin: 0;
  background-color: #dedede;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  font-size: 29px;
}

.box-valid-from-three a,
.box-valid-from-three-btn {
  color: #d5a67b;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none !important;
  font-family: "Montserrat", sans-serif !important;
  display: inline-block;
  margin: 23px 0 0 0;
}

.header-popup-specifc {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
  padding: 0 0 30px 0;
}

.header-popup-specifc span {
  display: inline-block;
  width: 100%;
  color: #485257;
  font-size: 20px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
}

#btnPrev {
  float: left;
  margin-bottom: 20px;
}

#btnPrev:before {
  content: "\f104";
  font-family: FontAwesome;
  padding-right: 4px;
}

#btnNext {
  float: right;
  margin-bottom: 20px;
}

#btnNext:after {
  content: "\f105";
  font-family: FontAwesome;
  padding-left: 4px;
}

#btnPrev,
#btnNext {
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  color: #c0c0c0;
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

#btnPrev:hover,
#btnNext:hover {
  color: #28283b;
  font-weight: bold;
}

.box-valid-from-three .row {
  margin-top: 50px;
}

.add-specific-time-add {
  display: inline-block;
  width: 100%;
}

.add-specific-time-add .select-dropdown,
.select-dropdown * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

.add-specific-time-add .select-dropdown select {
  max-width: 100%;
  border: 1px solid #dcdcdc;
  background-color: transparent;
  appearance: none;
  height: 50px;
  border-radius: 5px;
  padding: 0 8px;
  color: #37474f;
  font-size: 14px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  cursor: pointer !important;
}

.add-specific-time-add .select-dropdown:last-child {
  float: right;
  margin: 0;
}

.add-specific-time-add .select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}

.add-specific-time-add .select-dropdown::after {
  content: "";
  position: absolute;
  top: 43%;
  right: 10px;
  width: 13px;
  height: 13px;
  background: url("/images/Iconeroww.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.add-specific-time-add label {
  color: #1d2634;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  width: 100%;
  font-family: "Montserrat", sans-serif !important;
}

.add-specific-time-add .select-dropdown {
  width: 74.04px;
  float: left;
  margin: 0 18px 0 0;
}

.add-specific-time-filde {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}

.add-specific-time-add #loadMore {
  text-align: center;
  color: #d5a67b !important;
  width: 100%;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 500;
}

.button-popup-box {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 55px 0 0 0;
}

.submit_button .updatebtn {
  margin-top: 10px;
  margin-left: 200px;
  width: 212px;
  height: 54px;
  border-radius: 6px;
  background-color: #485257;
  border: none;
  font-weight: 300;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
}

.submit_button .removebtn {
  margin-top: 10px;
  margin-left: 200px;
  width: 212px;
  height: 54px;
  border-radius: 6px;
  background-color: #485257;
  border: none;
  font-weight: 300;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
}

.button-popup-box .cancel-btn-bx {
  width: 212px;
  height: 54px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  color: #485257;
  text-decoration: none;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
}

.button-popup-box .submit-btn-bx {
  width: 212px;
  height: 54px;
  background-color: #485257;
  border-radius: 6px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-family: "Montserrat", sans-serif !important;
}

.button-popup-box button {
  margin: 0 10px;
}

.header-popup-specifc-body .col-md-5 {
  border-left: 1px solid #dcdcdc;
  padding: 0 24px;
}

.scheduling-preferences-saction {
  display: inline-block;
  width: 100%;
  margin: 40px 0 0 0;
}

.scheduling-preferencesfilde .select-dropdown {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin: 10px 0 0 0;
}

.scheduling-preferencesfilde .select-dropdown select ,.input-in-availbilities {
  font-size: 18px;
  font-weight: 500;
  max-width: 100%;
  padding: 8px 24px 8px 10px;
  background-color: transparent;
  /* appearance: none; */
  height: 50px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  color: #485257;
  font-family: "Montserrat", sans-serif !important;
  width: 100%;
  background-color: #fff;
}

.scheduling-preferencesfilde .select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}

.scheduling-preferencesfilde .select-dropdown:after {
  /* content: "";
	position: absolute;
	top: 43%;
	right: 10px;
	width: 13px;
	height: 13px;
	background: url('/images/Iconeroww.png');
	background-repeat: no-repeat;
	background-size: contain; */
}

.scheduling-preferences-saction h3 {
  display: inline-block;
  width: 100%;
  margin: 0 0 0 0;
  color: #485257;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
}

.scheduling-preferencesfilde {
  display: inline-block;
  width: 100%;
  margin: 13px 0 10px 0;
}

.preferencesfilde-left {
  float: left;
  width: 48%;
}

.preferencesfilde-right {
  float: right;
  width: 48%;
}

.scheduling-preferencesfilde span {
  display: inline-block;
  width: 100%;
  color: #485257;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  margin: 14px 0 0 0;
}
.end-timetool-text {
  font-size: 18px;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500;
}
.preferencesfilde-right .select-dropdown {
  width: 100%;
}

.minimum-booking-time-row {
  display: inline-block;
  width: 100%;
  margin: 30px 0 0 0;
}

.minimum-booking-time-row {
  display: inline-block;
  width: 100%;
  margin: 30px 0 0 0;
}

.minimum-booking-time-row-left {
  position: relative;
  float: left;
  width: 48%;
}

.minimum-booking-time-row-right {
  position: relative;
  float: right;
  width: 48%;
}

.minimum-booking-time-row label {
  color: #485257;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  width: 100%;
  margin: 0 0 16px 0;
}

.minimum-inner-row-left {
  width: 34%;
  margin: 0 !important;
}

/* .calendar-box-new .right-last-td.ml-5 {
	margin: 10px 0 0 0 !important;
} */
.minimum-inner-row-right {
  float: left;
  width: 34%;
  margin: 0 0 0 25px;
}

.minimum-booking-time-row span {
  width: 60px;
  color: #485257;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  margin: 15px 0 7px 0;
  display: inline-block;
}

.minimum-booking-time-row .select-dropdown {
  width: 90%;
  /* width: 102px; */
  height: 50px;
}

.minimum-booking-time-row .select-dropdown select {
  font-size: 18px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 0 15px;
  background-color: #fff;
  color: #485257;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 18px;
}

.meeting-preferences-box-row {
  /* display: inline-block; */
  /* width: 70%; */
  margin: 30px 0 0 0;
}

.meeting-preferences-box-row span {
  float: left;
  color: #485257;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  margin: 11px 0 0 0;
}

.meeting-preferences-box-row .select-dropdown {
  float: left;
  margin: 0 19px;
}

.meeting-preferences-box-row select {
  font-size: 18px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  height: 50px;
  padding: 0 10px;
  background-color: #fff;
  color: #485257;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  cursor: pointer;
}

.meeting-preferences-box-row h4 {
  color: #485257;
  font-size: 18px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif !important;
  margin: 0 0 20px 0;
  width: 100%;
}

.advancesettings-connect-meeting {
  display: inline-block;
  width: 100%;
  margin: 40px 0 0 0;
}

.advancesettings-connect-meeting button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0 35px 0 0;
  color: #485257;
  font-size: 18px;
  font-weight: 500;
}

.advancesettings-connect-meeting .active {
  color: #d5a67b;
}

.advancesettings-connect-meeting h4 {
  margin: 44px 0 0 0;
  color: #485257;
  font-weight: 600;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
}

.advancesettings-connect-meeting [type="radio"]:checked,
.advancesettings-connect-meeting [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.advancesettings-connect-meeting [type="radio"]:checked + label,
.advancesettings-connect-meeting [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 50px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.advancesettings-connect-meeting [type="radio"]:checked + label::before,
.advancesettings-connect-meeting [type="radio"]:not(:checked) + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: -7px;
  width: 31px;
  height: 31px;
  border: 1px solid #d3d3d3;
  border-radius: 100%;
  background: transparent;
}

.advancesettings-connect-meeting [type="radio"]:checked + label::after,
.advancesettings-connect-meeting [type="radio"]:not(:checked) + label::after {
  content: "";
  width: 20px;
  height: 20px;
  background: #485257;
  position: absolute;
  top: 0px;
  left: 6.7px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.advancesettings-connect-meeting [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.advancesettings-connect-meeting [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.payment-setup-boxx p {
  width: auto;
  display: inline-block;
  margin: 0 30px 0 0;
}

.payment-setup-boxx {
  margin: 21px 0 0 0;
  display: inline-block;
  width: 100%;
}

.paypaluser-filde {
  display: flex;
  margin: 20px 0 0 0;
}

.paypaluser-filde label {
  margin: 16px 20px 0 0;
  color: #485257;
  font-size: 18px;
  font-weight: 500;
}

.paypaluser-filde input {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 18px;
  height: 50px;
  width: 360px;
}

.payment-setup-boxx label {
  font-size: 17px;
}

.back-to-schudle-btn {
  /* display: inline-block; */
  /* width: 100%; */
  text-align: center;
  /* margin: 87px 0; */
}
.floating-button-back-to-schudle {
  display: block;
  position: fixed;
  z-index: 22222;
  /* width: 100%; */
  /* top: 0; */
  bottom: 0;
  margin-bottom: 25px;
  right: 70px;
}
.updatebtn-green{
  background-color: green !important;

}
.pay-cnale {
  /* width: 212px;
  height: 54px; */
  width: 180px;
    height: 35px;
  background-color: #f5f5f5;
  border: 1px solid #666;
  color: #485257;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif !important;
}
.copy-btn-days {
  width: 212px !important;
  height: 54px !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #666 !important;
  color: #485257 !important;
  border-radius: 6px !important;
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  text-decoration: none !important;
}
.pay-cnale:hover {
  background-color: #f5f5f5;
}

.updatebtn {
  width: 212px;
  height: 54px;
  border-radius: 6px;
  background-color: #485257;
  border: none;
  font-weight: 700;
  font-size: 20px;
  font-family: "Montserrat", sans-serif !important;
}

.updatebtn:hover {
  background-color: #485257;
}
.back-to-schudle-btn button {
  margin: 0 10px;
}

.calendar-wrapper .react-calendar__navigation__label {
  color: #1d2634;
  font-size: 18px;
}

.calendar-wrapper .react-calendar__navigation {
  text-align: center;
}

.calendar-wrapper .react-calendar__month-view__weekdays__weekday {
  color: #bebebe;
  font-size: 16px;
}

.calendar-wrapper .react-calendar__month-view__weekdays__weekday abbr,
.calendar-wrapper .react-calendar__month-view__days__day {
  text-decoration: unset !important;
}

.calendar-wrapper .react-calendar__month-view__days__day {
  color: #1d2634;
  font-size: 18px;
}

.calendar-wrapper .react-calendar__navigation__prev2-button,
.calendar-wrapper .react-calendar__navigation__next2-button {
  display: none !important;
}

/* .calendar-wrapper   .react-calendar__month-view__days__day--neighboringMonth{
	display: none !important;
} */

.calendar-wrapper .react-calendar__tile--active abbr {
  background: #0078ff 0% 0% no-repeat padding-box;
  border-radius: 50%;
  color: white;
  width: 38px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.times-container {
  max-height: 400px;
  overflow: scroll-y;
  overflow-y: scroll;
}

.times-container::-webkit-scrollbar {
  /* appearance: none; */
  overflow: scroll;
}

.times-btn {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.width-530-px {
  width: 530px !important;
}

.width-354-px {
  width: 354px !important;
}
#accordion .d-flex span {
  margin: 20px 0px 0 0;
}
#accordion #filterDate2 {
  width: 100%;
}
.copy-container::-webkit-scrollbar {
  display: none !important;
}
.copy-container label::before {
  margin-right: 3px !important;
}
.copy-container {
  overflow: scroll;
  height: 50px;
}

.new-three-bx {
  display: flex;
  width: 100%;
}
.bookingperiodbar-btn {
  margin: 30px 0 0 0 !important;
}
.bookingperiodbar-wrapper {
  background-color: white;
  padding: 39px 32px;
  margin-top: 20px;
  margin-left: 0;
}
.add-blocked-date-new {
  display: flex;
  width: 100%;
}
.addspecifce {
  position: relative;
  bottom: 5px;
}
.css-g1d714-ValueContainer {
  height: 50.37px !important;
}

@media (max-width: 767px) {
  .width-unset-img img {
    width: unset !important;
  }
  .add-availability-window-box {
    padding: 0;
  }
  .hadding-window span {
    font-size: 16px;
    font-weight: 600;
  }
  .hadding-window span img {
    margin: 0px 6px 0 0;
    position: relative;
    top: 2px;
  }
  .time-zone-area {
    text-align: left;
    margin: 20px 0 0 0 !important;
  }
  .select-box-timezone {
    font-size: 14px;
    width: 100%;
    height: 38px;
    margin-right: 0;
    border-radius: 6px;
    padding: 9px;
  }
  .time-zone-area b {
    font-size: 14px;
    float: left;
    margin: 12px 0 0 0;
    font-weight: 400;
    width: 100%;
    text-align: left;
  }
  .chosse-name-selectde label {
    margin: 0 0 3px 0;
    font-size: 14px;
    font-weight: 600;
  }
  /* .chosse-name-selectde .select-dropdown::after {
		top: 47%;
	} */
  .css-1gtu0rj-indicatorContainer {
    display: none !important;
  }
  .row-valid-from {
    margin: 24px 0 0 0;
  }
  .box-valid-from-one {
    width: 100%;
  }
  .box-valid-from-one span {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 6px 0;
  }
  .box-valid-from-two {
    float: left;
    width: 100%;
    text-align: left;
    display: inherit;
  }
  .box-valid-from-two #filterDate2 {
    width: 100%;
    margin: 5px 0 10px 0;
    height: auto;
  }
  .box-valid-from-two em {
    font-size: 13px;
    margin: 0 8px 0 0;
    /* display: none; */
    width: 100%;
    text-align: center;
    display: block;
    padding: 0;
  }
  .box-valid-from-two #filterDate2 input {
    border: 1px solid #929292;
    padding: 10px !important;
    font-size: 13px;
    font-weight: 500;
    color: #000;
    height: auto;
    padding: 10px 17px !important;
    width: 100%;
  }
  .box-valid-from-two span {
    font-size: 12px;
    display: none;
  }
  .availability-window-table table th {
    padding: 10px 8px;
    font-size: 15px;
  }
  .availability-window-table td {
    padding: 10px;
    font-size: 16px;
  }
  .availability-window-table td .form-group label::before {
    margin-right: 10.17px;
    width: 18.23px;
    height: 18.23px;
    position: absolute;
    left: 0;
    top: -1px;
  }
  .availability-window-table td .form-group label {
    font-weight: 400;
    font-family: "Montserrat", sans-serif !important;
    margin: 0;
    padding: 0 0 0 26px;
    font-size: 13px;
  }
  .css-1s2u09g-control {
    height: 30px;
    font-size: 15px;
  }
  .css-319lph-ValueContainer {
    height: 100%;
    padding: 10px !important;
  }
  .css-6j8wv5-Input {
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: inherit !important;
  }
  .css-qc6sy-singleValue {
    padding: 0px 0px;
    font-size: 14px;
  }
  .availability-window-table td .form-group input:checked + label::after {
    top: -1px;
    left: 6px;
    width: 5px;
    height: 13px;
  }
  .width-530-px {
    width: auto !important;
    margin: 0 !important;
  }
  .select-dropdown {
    margin: 0 10px 0 0;
  }
  .availability-window-table td span {
    text-align: left;
    display: inline-block;
    width: 82px;
    font-size: 13px;
  }
  .time-option span {
    min-width: unset !important;
  }
  .select-dropdown {
    margin: 0 10px 0 0 !important;
    padding: 0 0 0 0;
  }
  .calendar-box-new {
    padding: 24px 0 0 0 !important;
  }
  .availability-window-table {
    display: inline-block;
    width: 100%;
    margin: 10px 0 0 0;
    overflow: scroll;
    overflow-x: inherit !important;
  }
  .calendar-box-new .card-header {
    padding: 5px 10px 0px;
    margin-bottom: -2px;
    border-radius: inherit !important;
  }
  #accordion .start_time {
    margin: 0;
  }
  .padding-left-hours {
    padding-left: 0;
    text-align: center;
    width: 100%;
    display: inline-block;
  }
  .calendar-box-new .card-header h5 a {
    color: #485257;
    font-size: 15px;
  }
  .scheduling-preferencesfilde {
    margin: 15px 0 0 0;
  }
  .preferencesfilde-left {
    float: left;
    width: 100%;
  }
  .preferencesfilde-right {
    float: left;
    width: 100%;
    margin: 9px 0 0 0;
  }
  .scheduling-preferencesfilde span {
    font-size: 14px;
    margin: 0 0 2px 0;
  }
  .scheduling-preferencesfilde .select-dropdown {
    margin: 0;
    padding: 0;
    width: 100%;
    margin: 0 0 13px 0;
  }
  .scheduling-preferencesfilde .select-dropdown select ,.input-in-availbilities  {
    font-size: 15px;
    max-width: 100%;
    padding: 10px;
    height: auto;
    margin: 3px 0 0 0;
  }
  .minimum-booking-time-row {
    width: 100%;
    margin: 20px 0 0 0;
  }
  .minimum-booking-time-row-left {
    float: left;
    width: 100%;
  }
  .minimum-booking-time-row label {
    color: #485257;
    font-size: 16px;
    width: 100%;
    margin: 0 0 0 0;
  }
  .minimum-inner-row-left {
    float: left;
    width: 100%;
  }
  .minimum-booking-time-row .select-dropdown {
    float: left;
    width: 100%;
  }
  .minimum-booking-time-row span {
    float: left;
    width: auto;
    font-size: 15px;
    font-weight: 500;
    margin: 17px 0 5px 0;
  }
  .select-dropdown {
    margin: 0;
  }
  .minimum-booking-time-row .select-dropdown select {
    font-size: 15px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 0 15px;
  }
  .minimum-booking-time-row-right {
    width: 100%;
    margin: 25px 0;
  }
  .meeting-preferences-box-row {
    margin: 0px 0 0 0;
  }
  .meeting-preferences-box-row h4 {
    font-size: 15px;
    margin: 0 0 15px 0;
    width: 100%;
  }
  .language-box--row {
    display: inline-block !important;
    width: 100%;
  }
  .meeting-preferences-box-row span {
    float: left;
    color: #485257;
    font-size: 14px;
    margin: 11px 0;
    width: 100%;
  }
  .meeting-preferences-box-row .select-dropdown {
    float: left;
    margin: 0 !important;
    width: 100%;
  }
  .meeting-preferences-box-row #react-select-33-live-region {
    margin: 0 !important;
  }
  .meeting-preferences-box-row .css-1f43avz-a11yText-A11yText {
    margin: 0 !important;
  }
  .css-1s2u09g-control {
    height: 59px;
  }
  .recording-box--row {
    display: inline-block;
    margin: 20px 0 0 0;
    width: 100%;
  }
  .meeting-preferences-box-row select {
    font-size: 15px;
    height: 44px;
    width: 100%;
  }
  .mt-top {
    margin-top: 24px !important;
  }
  .ml-3,
  .mx-3 {
    margin-left: 0 !important;
  }
  .day-schedule-input .css-1s2u09g-control {
    height: 30px;
    padding: 0px 0 0 8px;
    text-align: left;
  }
  .check-field {
    float: left;
    width: 100%;
  }
  .language-box--row label {
    width: 100%;
  }
  #collapse-2 .mt-4.mb-4,
  #collapse-3 .mt-4.mb-4 {
    margin: 0 !important;
  }
  #collapse-2 #filterDate2,
  #collapse-3 #filterDate2 {
    width: 100%;
    margin: 0;
  }
  #collapse-2 .col-md-3,
  #collapse-3 .col-md-3 {
    padding: 0 !important;
  }

  #collapse-2 .d-flex,
  #collapse-3 .d-flex {
    display: inline-block !important;
    width: 100% !important;
    margin: 0 !important;
  }
  #collapse-2 .d-flex .select-dropdown,
  #collapse-3 .d-flex .select-dropdown {
    margin: 12px 0 0 0 !important;
  }
  #collapse-2 .d-flex #specificDateSlots\.0\.time\.0\.from,
  #collapse-3 .d-flex #specificDateSlots\.0\.time\.0\.from {
    width: 100%;
  }
  #collapse-2 .d-flex #specificDateSlots\.0\.time\.0\.to,
  #collapse-3 .d-flex #specificDateSlots\.0\.time\.0\.to {
    width: 100%;
  }
  #collapse-2 .justify-content-around.align-items-center.ml-5,
  #collapse-3 .justify-content-around.align-items-center.ml-5 {
    margin: 15px 0 0 !important;
  }
  #collapse-2 .right-last-td.ml-5,
  #collapse-3 .right-last-td.ml-5 {
    margin: 0 !important;
  }
  .advancesettings-connect-meeting h4 {
    margin: 0 0 0 0;
    font-size: 15px;
  }
  .advancesettings-connect-meeting [type="radio"]:checked + label::before,
  .advancesettings-connect-meeting
    [type="radio"]:not(:checked)
    + label::before {
    width: 26px;
    height: 26px;
  }
  .advancesettings-connect-meeting [type="radio"]:checked + label::after,
  .advancesettings-connect-meeting [type="radio"]:not(:checked) + label::after {
    width: 17px;
    height: 17px;
    top: -1px;
    left: 5.7px;
  }
  .payment-setup-boxx label {
    font-size: 14px;
    padding: 0 0 0 38px !important;
  }
  .advancesettings-connect-meeting .paypaluser-filde {
    display: inline-block;
    width: 100%;
  }
  .advancesettings-connect-meeting .paypaluser-filde label {
    margin: 6px 0 10px 0;
    color: #485257;
    font-size: 15px;
    font-weight: 500;
  }
  .paypaluser-filde input {
    padding: 0 10px;
    font-size: 15px;
    height: 45px;
    width: 100%;
  }
  .back-to-schudle-btn {
    width: 100%;
    margin: 40px 0 20px;
  }
  .pay-cnale {
    /* width: 100%; */
    /* height: 45px; */
    font-size: 16px;
    margin: 10px !important;
    text-align: center;
  }
  .updatebtn {
    /* width: 100%; */
    font-size: 16px;
    height: 45px;
    margin: 10px !important;
  }
  .student-lavel {
    padding: 0 0;
    width: 100% !important;
    border-bottom: none !important;
  }
  .new-three-bx {
    display: inline-block;
  }
  .bookingperiodbar-btn {
    margin: 0px 0 24px 0 !important;
    width: 100%;
  }
  .row-one-step {
    padding: 0 0 20px !important;
  }
  .add-blocked-date-new {
    display: inline-block;
    width: 100%;
  }
  .add-blocked-date-new .day-schedule-input {
    border: 0.5px solid #707070;
    border-radius: 5px;
    width: 100%;
  }
  .add-blocked-date-new .right-last-td.ml-5 {
    display: none;
  }
  .add-blocked-date-new .right-last-td button {
    width: 100%;
    margin: 17px 0 20px 0;
  }
  .day-schedule-input {
    width: 100%;
  }
  .buttion-add-box.onclick-button.mt-2 {
    /* display: none; */
  }
  .specific-specific-blocked-date .right-last-td.ml-5 {
    display: none;
  }
  .margin-left-auto {
    margin-left: auto;
    float: left;
    width: 100%;
    margin: 11px 0 0 0;
  }
  .action-btn-blocked-specific {
    margin-left: unset !important;
    margin-top: 10px !important;
  }
  .addspecifce {
    width: 100%;
  }
  .specific-dated-text-box {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-left: 0;
  }
  #collapse-2 #filterDate2 {
    position: relative;
    bottom: 4px;
  }
  .bookingperiodbar-wrapper {
    background-color: white;
    padding: 15px;
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-left: 0;
  }
  #collapse-2 #filterDate2 {
    position: relative;
    bottom: 4px;
  }
  .div-minimum-time::before {
    top: -26px !important;
    font-size: 12px;
  }
  .div-minimum-time::after {
    top: -26px !important;
    right: -33px !important;
    font-size: 12px;
  }
  .div-maximum-time-container::after {
    top: -25px !important;
    left: 6% !important;
    font-size: 12px;
  }
  .div-maximum-time::after {
    top: -25px !important;
    right: 2px !important;
    font-size: 12px;
  }
  .div-maximum-time-container {
    width: 100%;
    float: right;
    text-align: center;
    padding: 12px 0;
  }
  .div-minimum-time-container {
    width: 100%;
    text-align: center;
    padding: 12px 0;
  }
  .div-minimum-time {
    height: 100% !important;
    float: left;
    align-items: center;
    width: 20% !important;
    position: relative;
    text-align: left;
  }
  .div-maximum-time {
    height: 100%;
    float: right;
    align-items: center;
    width: 79%;
    background-color: #90ee90;
    position: relative;
    text-align: center;
  }
  .bookingperiodbar-container {
    display: inline-block !important;
    height: 40px;
    align-items: center;
    border: 1px solid;
    width: 100%;
  }
  .selection-box .css-14el2xx-placeholder {
    padding: 7px 0 !important;
  }
  .css-26l3qy-menu {
    position: absolute;
    z-index: 999;
  }
  .specific-dated-text-box {
    width: 100%;
    display: inline-block;
    margin-top: 25px;
    margin-left: 0;
  }
  .multiple-datee {
    display: inline-block;
    width: 100%;
  }
  .multiple-datepicker > div::before {
    position: relative;
    width: 100%;
  }
  .multiple-datepicker input {
    position: absolute !important;
    opacity: 0 !important;
    top: 0;
    width: 100% !important;
    height: 100%;
  }
  .multiple-datepicker {
    margin: 0 0 13px 0;
  }
  .fKLcWV {
    width: 100%;
    max-width: 95% !important;
  }
  .heJwJj {
    height: 342px !important;
  }
  #collapse-3 .add-blocked-date-new .span-tag.pointer {
    bottom: 0;
  }
  .addspecifce {
    position: relative;
    bottom: 0;
  }
  .availability-window-table .select-dropdown {
    width: 120px;
  }
  .multiple-datepicker > div::before {
    margin: 20px 0 0 0;
  }
  .table-mideum-table {
    overflow: scroll;
  }
  .tableclander tr:nth-child(2) td:nth-child(5) {
    width: 27%;
  }
  .css-6j8wv5-Input {
    position: absolute;
    left: 0;
    width: 100%;
  }
  #accordion .d-flex span {
    margin: 0;
    display: inline-block;
  }
  .date-picker div span:hover,
  .td-clicked {
    /* width: inherit;
		border-radius: 50%; */
    /* height: inherit; */
    /* border: none; */
  }
  .tim-clander-shedul h4 {
    font-size: 16px;
    font-weight: 400;
  }
  .date-picker .orange-bulb {
    width: 16px;
    height: 16px;
    border-radius: 20px;
    font-size: 8px;
    position: absolute;
    margin-left: 31px;
    line-height: 18px;
  }
  #previous {
    top: 6px !important;
    left: 7px !important;
    margin: 0 !important;
    right: inherit !important;
  }
  .select-dropdown .css-ackcql {
    display: none !important;
  }
  .css-tlfecz-indicatorContainer {
    position: absolute;
    right: 0;
    top: -2px;
  }
  .css-26l3qy-menu > div > div {
    padding: 8px 6px !important;
  }
  span.css-1okebmr-indicatorSeparator {
    width: auto !important;
    background-color: transparent !important;
    margin: 0 !important;
  }
  .css-1s2u09g-control {
    height: 36px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .toogle-nav {
    display: none !important;
  }
  .d-md-none {
    display: block !important;
  }
  .topbar #sidebarToggleTop {
    height: 40px;
    width: 40px;
    padding: 0 !important;
    line-height: 52px;
  }
  .btn img {
    top: 0px !important;
    width: 22px ;
    margin: 0 7px !important;
  }
  .book-app-btn-client{
    width: unset !important;
  }
  .sidebar {
    width: 100% !important;
    overflow: scroll;
    position: absolute;
    z-index: 99;
    height: 100%;
    
  }
  .close-btn {
    display: block !important;
  }
  .close-btn img {
    width: 27px;
  }
  .container-fluid {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .tr-padding {
    padding: 10px 0 0;
    text-align: right;
    display: inline-block !important;
    width: 100%;
  }
  .btn-sedule button {
    width: 140px !important;
    padding: 0 0 !important;
    margin: 14px 7px !important;
  }
  .time-zone-area {
    width: 100% !important;
    text-align: center !important;
  }
  .time-zone-area-client {
    text-align: unset !important;
    width: unset !important;
  }
  /* .time-zone-area-client{
    width: 100% !important;
    text-align: left !important;
  } */
  .table-mideum-table table th {
    padding: 10px;
    font-size: 15px;
    width: auto !important;
  }
  .table-mideum-table {
    display: inline-block;
    margin: 21px 0 0;
    overflow: scroll;
  }
  .table-mideum-table table td {
    padding: 10px;
  }
  .table-mideum-table table td .right-last-td {
    width: 100%;
  }
  .tableclander .right-last-td button {
    height: 45px;
    width: 100%;
    padding: 0;
    margin: 0 0 20px;
    font-size: 13px;
  }
  .middle-screen-name {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .tableclander table {
    width: 2060px;
  }
  .table-mideum-table table td p {
    font-size: 11px !important;
    font-family: "Montserrat", sans-serif !important;
    line-height: 20px;
  }
  .add-availability-window-box {
    padding: 0;
  }
  .hadding-window span {
    margin: 0;
    color: #485257;
    font-size: 17px;
  }
  .hadding-window span img {
    margin: 0 8px 0 0;
    position: relative;
    top: 2px;
  }
  .add-availability-window-box .time-zone-area {
    width: 100% !important;
    text-align: left !important;
    margin: 28px 0 0 0 !important;
  }
  .box-valid-from-one {
    float: left;
    width: 100%;
  }
  .row-valid-from {
    margin: 0 0 0 0;
  }
  .box-valid-from-one span {
    font-size: 17px;
    width: 100%;
  }
  .box-valid-from-two #filterDate2 {
    display: inline-block;
    width: 50%;
    margin: 0 20px 0 0;
    height: auto;
  }
  .box-valid-from-two #filterDate2 input {
    width: 100%;
    border-radius: 10px;
  }
  .box-valid-from-two span {
    font-size: 14px;
    padding: 15px 0 0 0;
  }
  .box-valid-from-two em {
    font-size: 14px;
    padding: 15px 19px 0 0;
  }
  .availability-window-table table th {
    padding: 10px;
    font-size: 14px;
  }
  .availability-window-table td {
    padding: 10px;
    font-size: 14px;
  }
  .availability-window-table td .form-group label::before {
    margin-right: 6px;
    width: 19.23px;
    height: 19.23px;
  }
  .width-530-px {
    width: auto !important;
    margin: 0 !important;
  }
  .d-flex .select-dropdown {
    margin: 0 6px;
  }
  .calendar-box-new {
    padding: 8px 0 !important;
  }
  .buttion-add-box {
    /* display: none; */
  }
  .availability-window-table td .form-group input:checked + label::after {
    top: 2px;
    left: 8px;
    width: 4px;
    height: 12px;
  }
  .availability-window-table td input {
    height: 34px;
  }
  .calendar-box-new .card-header {
    padding: 14px;
    border-radius: 0 !important;
  }
  .calendar-box-new .card-header h5 a {
    color: #485257;
    font-size: 17px;
  }
  .scheduling-preferencesfilde span {
    display: inline-block;
    width: 100%;
    color: #485257;
    font-size: 15px;
    margin: 5px 0 0 0;
  }
  .scheduling-preferencesfilde .select-dropdown {
    margin: 6px 0 0 0;
  }
  .scheduling-preferencesfilde .select-dropdown select ,.input-in-availbilities {
    font-size: 16px;
    height: 46px;
  }
  .minimum-booking-time-row {
    margin: 15px 0 0 0;
  }
  .minimum-booking-time-row label {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
  }
  .minimum-booking-time-row span {
    font-size: 13px;
    margin: 10px 0 6px 0;
  }
  .new-three-bx .select-dropdown {
    margin: 0 !important;
  }
  .new-three-bx .select-dropdown {
    margin: 0 !important;
    width: 90%;
    height: 46px;
  }
  .minimum-booking-time-row .select-dropdown select {
    font-size: 15px;
    padding: 0 10px;
    font-weight: 18px;
  }
  .bookingperiodbar-btn {
    margin: 20px 0 0 0 !important;
    width: 100%;
  }
  .meeting-preferences-box-row {
    margin: 20px 0 0 0;
  }
  .language-box--row {
    display: inline-block;
    width: 100%;
  }
  .meeting-preferences-box-row span {
    font-size: 15px;
    margin: 0;
    width: 100%;
  }
  .language-box--row .select-dropdown {
    margin: 10px 0 0 0;
  }
  .language-box--row .select-dropdown {
    width: 100%;
  }
  .recording-box--row {
    display: inline-block;
    width: 100%;
    margin: 20px 0 0 0;
  }
  .recording-box--row .select-dropdown {
    margin: 10px 0 0 0;
    width: 100%;
  }
  .meeting-preferences-box-row select {
    font-size: 16px;
    height: 46px;
    width: 100%;
  }
  .meeting-preferences-box-row.mt-top {
    margin: 30px 0 0 !important;
  }
  .meeting-preferences-box-row.mt-top .language-box--row.clear-both.d-flex {
    display: inline-block !important;
    margin: 0 0 0 0;
  }
  .meeting-preferences-box-row.mt-top
    .language-box--row.clear-both.d-flex
    .ml-3 {
    margin: 10px 0 0 !important;
    display: inline-block;
    width: 100%;
  }
  .check-field label {
    height: 46px;
    line-height: 46px;
    font-size: 12px;
    padding: 0 20px 0 43px;
  }
  .check-field input[type="radio"]::before {
    width: 19px;
    height: 19px;
    left: 9px;
    top: 11px;
  }
  .check-field input[type="radio"]:checked::after {
    width: 11.93px;
    height: 9.83px;
    left: 14px;
    background-size: contain;
    top: 16px;
  }
  .row-one-step label {
    font-weight: 600;
  }
  .selection-box,
  .selection-box > .css-1s2u09g-control {
    height: 46.37px;
  }
  .row-one-step input {
    width: 100%;
    height: 43.37px;
  }
  .selection-box .css-14el2xx-placeholder {
    padding: 0 0;
  }
  #collapse-2 .pointer {
    margin: 0;
  }
  #collapse-2 .pointer {
    margin: 0;
    width: 100%;
  }
  .specific-specific-blocked-date {
    display: inline-block;
    margin: 0 0 0 !important;
    width: 100%;
  }
  #collapse-2 #filterDate2 {
    width: 100%;
    margin: 0;
  }
  #collapse-2
    .d-flex
    .w-100.d-flex.justify-content-around.align-items-center.ml-5 {
    margin: 0 0 0 16px !important;
  }
  #collapse-2 .d-flex .select-dropdown.d-flex {
    margin-bottom: 10px;
  }
  #collapse-2 .d-flex {
    display: inline-block !important;
    width: 100%;
  }
  #collapse-2 .d-flex {
    width: 100% !important;
  }
  #collapse-2 .d-flex .select-dropdown .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }
  #collapse-2 .d-flex .select-dropdown {
    margin: 0;
    float: left;
    width: 48%;
  }
  #collapse-2 .d-flex .select-dropdown.d-flex {
    float: right;
    width: 50% !important;
  }
  #collapse-2 .span-tag.pointer {
    text-align: center !important;
    width: 100%;
    display: inline-block;
    margin: 15px 0 0 -16px;
    bottom: 0;
  }

  #collapse-3 .pointer {
    margin: 0;
  }
  #collapse-3.pointer {
    margin: 0;
    width: 100%;
  }
  .specific-specific-blocked-date {
    display: inline-block;
    margin: 0 0 0 !important;
    width: 100%;
  }
  #collapse-3 #filterDate2 {
    width: 100%;
    margin: 0;
  }
  #collapse-3
    .d-flex
    .w-100.d-flex.justify-content-around.align-items-center.ml-5 {
    margin: 0 0 0 16px !important;
  }
  #collapse-3 .d-flex .select-dropdown.d-flex {
    margin-bottom: 10px;
  }
  #collapse-3 .d-flex {
    display: inline-block !important;
    width: 100%;
  }
  #collapse-3 .d-flex {
    width: 100% !important;
  }
  #collapse-3 .d-flex .select-dropdown .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }
  #collapse-3 .d-flex .select-dropdown {
    margin: 0;
    float: left;
    width: 48%;
  }
  #collapse-3 .d-flex .select-dropdown.d-flex {
    float: right;
    width: 50% !important;
  }
  #collapse-3 .span-tag.pointer {
    text-align: center !important;
    width: 100%;
    display: inline-block;
    margin: 15px 0 0 -16px;
    bottom: 0;
  }
  .add-blocked-date-new {
    display: inline-block;
    width: 100%;
  }
  #collapse-3 .add-blocked-date-new .d-flex {
    display: flex;
    width: 100%;
    margin: 0 0 0 0;
  }
  .action-btn-blocked-specific {
    margin-left: unset !important;
  }
  #collapse-3 .right-last-td button {
    /* height: 45px;
		width: 100%; */
  }
  .advancesettings-connect-meeting {
    margin: 0 0 0 0;
  }
  .advancesettings-connect-meeting h4 {
    margin: 20px 0 0 0;
    font-size: 16px;
  }
  .payment-setup-boxx {
    margin: 16px 0 0 0;
  }
  .advancesettings-connect-meeting [type="radio"]:checked + label::before,
  .advancesettings-connect-meeting
    [type="radio"]:not(:checked)
    + label::before {
    top: -7px;
    width: 26px;
    height: 26px;
  }
  .advancesettings-connect-meeting [type="radio"]:checked + label::after,
  .advancesettings-connect-meeting [type="radio"]:not(:checked) + label::after {
    content: "";
    width: 20px;
    height: 20px;
    top: -3px;
    left: 3.7px;
  }
  .advancesettings-connect-meeting [type="radio"]:checked + label,
  .advancesettings-connect-meeting [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 40px;
  }
  .paypaluser-filde {
    display: inline-block;
    margin: 15px 0 0 0;
    width: 100%;
  }
  .paypaluser-filde label {
    margin: 10px 0 7px 0;
    font-size: 14px;
    width: 100%;
    display: inline-block;
  }
  .paypaluser-filde input {
    font-size: 18px;
    height: 46px;
    width: 100%;
  }
  .back-to-schudle-btn {
    /* display: inline-block; */
    width: 100%;
    margin: 47px 0 30px;
  }
  .back-to-schudle-btn button {
    margin: 0 10px;
    width: 47%;
  }
  .add-availability-window-box b {
    font-size: 15px;
  }
  .css-1rhbuit-multiValue,
  .rti--tag {
    height: 33px;
    line-height: 32px;
    margin: -5px 0 0 !important;
  }
  .css-g1d714-ValueContainer {
    height: 56.37px !important;
  }
  .specific-dated-text-box {
    width: 100%;
    display: inline-block;
    margin-top: 24px;
    margin-left: 0;
  }
  .multiple-datepicker > div > input {
    position: absolute;
    width: 100%;
    top: 0;
  }
  .multiple-datepicker > div::before {
    content: "Copy Time Period";
    display: flex;
    align-items: center;
    padding: 0px 10px;
    position: relative;
    width: 100%;
    margin-top: 11px;
  }
}
.golden-icon::after {
  background: url(/images/info.svg) !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  margin-top: 2px;
}
.margin-botton-200px {
  /* margin-bottom: 200px; */
}
.margin-botton-50px {
  margin-bottom: 50px;
}
.fullDay-checkbox {
  margin-left: 30px;
  display: flex;
  align-items: center;
}
.fullDay-checkbox label {
  margin-bottom: unset;
  font-size: 15px;
  position: relative;
  cursor: pointer;
  width: 120px;
}
.fullDay-checkbox input {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  padding: 0;
  display: none;
  cursor: pointer;
}
.fullDay-checkbox input:checked + label::before {
  background-color: #d6a478;
  border-color: #d6a478;
}

.fullDay-checkbox label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #707070;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 18.17px;
  border-radius: 5px;
  width: 24.23px;
  height: 24.23px;
}

.fullDay-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.time-claas span {
  display: inline-block;
  width: 100%;
  margin: 0 0 8px 0 !important;
}
.these-span {
  margin: 0 0 10px 0 !important;
}
.css-qc6sy-singleValue {
  text-align: left !important;
}
.action-btn-blocked-specific {
  display: flex;
  align-items: center;
  gap: 15px;
}

@media (min-width: 1024px) and (max-width: 1530px) {
  .margin-top-recording {
    margin:unset !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }
}
@media (min-width: 1024px) {
  .margin-top-recording span {
    margin-right: 15px !important;
  }
}
.notofication-setting-box{
  width: 50px;
  height: 20px;
  margin-left: 50px;
}
.display-unset{
  display: unset !important;
}