.error-boundry {
  border: 1px solid red !important;
}

@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/mobile.css");
@import url("./assets/css/style.css");

@import url("./assets/css/main.css");
@import url("./assets/css/sb-admin-2.css");
@import url("./assets/css/sb-admin-2.min.css");
@import url("./assets/css/responsive.css");

.error {
  position: absolute;
  display: block;
  font-size: 12px;
  color: red;
  font-weight: 100;
}

.verified {
  color: #008000d4;
  display: block;
  width: 300px;
}
li::marker {
  content: "";
}

.negative-margin {
  margin-top: -25px;
}

/* .show{
  display: block;
} */

.calendar-box {
  margin-top: 20px;
  width: 100%;
  height: 80vh;
  background-color: white;
}

.left-align {
  text-align: left;
}

.font-size-17 {
  font-size: 17px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-19 {
  font-size: 19px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-12,
.font-12 {
  font-size: 12px !important;
}

.note {
  color: red !important;
}

.font-size-14 {
  font-size: 14px !important;
}
.line-height-20px {
  line-height: 20px !important;
}
.line-height-24px {
  line-height: 24px;
}
.line-height-30px {
  line-height: 30px;
}
.line-height-36px {
  line-height: 36px;
}
.letter-spacing-1px {
  letter-spacing: 1px;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-40 {
  font-size: 40px !important;
}
.font-size-30 {
  font-size: 30px !important;
}
.subcalendar {
  margin-left: 108px;
}
.loader-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.loader-overlay span {
  position: fixed;
}

.d-none {
  display: none !important;
}

.search-choice span span {
  display: none !important;
}

.marg-top-55 {
  margin-top: 55px;
}

.margin-top-115 {
  margin-top: 115px;
}

.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  height: 80vh;
  flex-direction: column;
}

.approve-btn {
  position: absolute;
  /* margin-left: 180px; */
  background-color: white;
  box-shadow: 0px 8px 22px -6px rgb(73 73 73 / 12%),
    0px 14px 64px -4px rgb(71 71 71 / 12%);
  border-radius: 11px;
  border: 1px solid #fff;
}

.approve-btn span {
  display: block;
  text-align: center;
  padding: 15px;
  position: unset !important;
  cursor: pointer;
}

.approve-btn span:hover {
  background-color: #f3f5f9;
}

.mt-30 {
  margin-top: 30px;
}

/* .modal-dialog{
pointer-events: unset !important;
} */

.pointer-event-active {
  pointer-events: unset;
}

.text-align-unset {
  text-align: unset !important;
}

.text-align-center {
  text-align: center !important;
}

.spinner {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: #0000006e;
}
.spinner-text {
  background-color: #000000d4 !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.spinner span span {
  background-color: #d5a67b !important;
}
.spinner-text p {
  color: #d5a67b;
  font-size: 18px;
  /* margin: unset !important; */
  margin-top: 20px !important;
  text-align: center;
  line-height: 25px;
}
.spinner .rs-loader-spin:before {
  border-color: #d5a67b !important;
}
.wrap .arrow {
  display: none !important;
}

/* .number-input .flag-dropdown {
 background-color: unset !important;
 border: unset !important;
}
.number-input input {
  width: 100% !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    border: 1px solid #cacaca !important;
    height: 61.37px !important;

    font-family: "Oakes-Grotesk-Medium";
    font-size: 14px !important;
    font-weight: 500 !important;
}
.number-input input:focus{
  border: unset !important;
}
.number-input .flag-dropdown.open .selected-flag{
background: unset !important;
}
.number-input{
  border-radius: 5px
} */

.checkIn::-webkit-inner-spin-button,
.checkIn::-webkit-calendar-picker-indicator {
  /* display: none; */
  /* -webkit-appearance: none; */
  position: absolute;
  right: 10px;
  z-index: 22222;
  /* width:20px; */
  opacity: 0;
  font-size: 30px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}
.model-close-btn {
  border-style: solid !important;
  border-color: #d5a67b !important;
  background-color: unset !important;
  color: #d5a67b !important;
}

.payment-options-container {
  display: flex;
  justify-content: space-around; /* Space buttons evenly across the container */
  flex-wrap: wrap; /* Allows wrapping if there are too many buttons */
}

.payment-option {
  text-align: center; /* Centers image and text vertically */
  margin-bottom: 20px; /* Add space between buttons vertically on small screens */
}

.payment-option button {
  background: none;
  border: none;
  cursor: pointer;
}

.payment-option img {
  max-width: 100px;
  margin-bottom: 10px; /* Space between image and text */
}

.payment-option p {
  margin-top: 0;
  font-size: 14px;
}
@media (min-width: 701px) {
  .payment-dialog {
    max-width: 40% !important;
  }
}

/* .date-picker:hover{
  border-radius: 50px;
  border: 1px solid #D5A67B;
  width: 55px;
  box-sizing: border-box;
  height: 55px;

} */
/* .tim-clander-shedul .check-field input[type="radio"]:checked
{
  background-color: #d5a67b;
  color: white !important;
}
.tim-clander-shedul .check-field input[type="radio"]:checked + label {

  color: white !important;
} */

.appointment-links {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.create-new-btn button .btn {
  text-align: center;
}

.create-new-btn {
  text-align: center;
}

.no-results span {
  display: none;
}

.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
}
.w-0 {
  width: 0% !important;
}

.selection-box > * {
  outline: none !important;
}

.selection-box,
.selection-box:not(#serviceType) > .css-1s2u09g-control {
  height: 61.37px !important;
  /* max-height: 61.37px!important; */
  /* overflow: scroll !important; */
}

.prefrence-box > .css-1s2u09g-control {
  width: 100%;
  border: 1px solid #d5a67b;
  border-radius: 5px;
  height: 68px !important;
  background-color: #fff;
  cursor: pointer;
  min-height: 68px !important;
}

.prefrence-box > .css-1s2u09g-control:hover {
  border: 1px solid #d5a67b;
}

.prefrence-box > .css-1pahdxg-control {
  min-height: 68px !important;
}

.selection-box .from-inner-box input {
  height: unset !important;
}

.selection-box .css-14el2xx-placeholder {
  height: 100%;
  display: flex;

  color: #bcbfc0 !important;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";

  align-items: center;
  padding: 0 16px;
}

.css-6j8wv5-Input {
  margin: unset !important;
  padding: unset !important;
}

.css-1rhbuit-multiValue,
.rti--tag {
  text-transform: capitalize !important;
  background-clip: padding-box;
  background-color: #d5a67b !important;
  border: 1px solid #d5a67b;
  color: #fff;
  cursor: pointer;
  border-radius: 5px !important;
  height: 39px;
  line-height: 38px;
  margin: 0 8px;
}

.css-1rhbuit-multiValue div {
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  display: flex;
}

.css-1s2u09g-control,
.css-g1d714-ValueContainer {
  overflow: scroll !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.css-g1d714-ValueContainer {
  height: 61.37px !important;
}

.css-1s2u09g-control::-webkit-scrollbar,
.tableclander-schedules::-webkit-scrollbar,
.css-g1d714-ValueContainer::-webkit-scrollbar {
  display: none !important;
}

.css-xb97g8 svg {
  height: 25px;
  width: 25px;
}

.css-xb97g8:hover {
  background-color: unset !important;
  color: unset !important;
}

#react-select-3-listbox {
  /* display:block !important; */
  /* height: 300px; */
}

.css-yt9ioa-option,
.css-1n7v3ny-option {
  background-color: white !important;
  text-align: left;
  color: black;
}

.css-319lph-ValueContainer,
.css-9gakcf-option {
  text-align: left !important;
}

.css-yt9ioa-option:hover,
.css-1n7v3ny-option:hover {
  background-color: #d5a67b !important;
  background: #d5a67b !important;
  color: #fff !important;
  text-align: left;
}

/* .css-1wy0on6  */
/* .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
} */

.css-1rhbuit-multiValue {
}

.disabled-date {
  cursor: not-allowed !important;
}

.disabled-date:hover {
  border: unset !important;
}

.rti--container {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #cacaca;
  height: 61.37px;
  padding: 0 16px;
  font-family: "Oakes-Grotesk-Medium";
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 26.62px 0;
  padding: 0px 12px !important;
}

.rti--input {
  height: 100%;
  width: unset !important;
  background-color: transparent !important;
  border-radius: unset !important;
  border: unset !important;
  /* height: unset !important; */
  padding: unset !important;
  font-family: unset !important;
  font-size: unset !important;
  font-weight: unset !important;
  margin: unset !important;
}

.rti--tag button:hover {
  color: white !important;
}

.rti--tag {
  margin: 0 3px !important;
}
.advance-tag-margin {
  margin: 25px 0 0 0 !important;
}
.advance-tag {
  width: 300px;
  height: 61px;
  background-color: #d5a67b;
  display: inline-block;
  line-height: 61px;
  color: #fff !important;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #e0e4ee;
  font-size: 16px;
  font-family: "Oakes-Grotesk-Medium";
  font-weight: 500;
  margin: 47px 0 0 0;
  text-decoration: none !important;
}
.advance-tag-new {
  width: 300px;
  height: 61px;
  background-color: #d5a67b;
  display: inline-block;
  line-height: 61px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #e0e4ee;
  font-size: 16px;
  font-family: "Oakes-Grotesk-Medium";
  font-weight: 500;
  margin: 47px 0 0 0;
  text-decoration: none !important;
}

.advance-tag-my-calendar {
  width: 220px !important;
  /* height: 61px; */
  background-color: #d5a67b;
  display: inline-block;
  /* line-height: 61px; */
  color: #fff !important;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #e0e4ee;
  font-size: 16px;
  font-family: "Oakes-Grotesk-Medium";
  font-weight: 500;
  margin: 30px 0 0 0 !important;
  text-decoration: none !important;
  padding: 20px !important;
}

.client-selection-box .css-g1d714-ValueContainer::before,
.client-selection-box .css-14el2xx-placeholder::before {
  content: url(/images/australia.png);
  display: flex;
  align-items: center;
  padding: 0px 10px;
  /* background-color: green; */
  /* width: 20px;
  height: 20px; */
}

.client-selection-box .css-14el2xx-placeholder {
  margin-left: -10px;
}

.alerts-border {
  animation: glowing 1300ms infinite;
  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  /* outline: 3px solid #d5a67b !important; */
  /* border-width: 2px !important; */
  /* animation-iteration-count: 10; */
  background-color: #0080009c;
}
@keyframes glowing {
  0% {
    /* background-color: #2ba805; */
    box-shadow: 0 0 5px #2ba805;
    outline: 1px solid #2ba805;
  }
  50% {
    /* background-color: #49e819; */
    box-shadow: 0 0 20px #49e819;
    outline: 3px solid #49e819;
  }
  100% {
    /* background-color: #2ba805; */
    box-shadow: 0 0 5px #2ba805;
    outline: 1px solid #2ba805;
  }
}
.openeye {
  height: 27px;
  position: relative;
  top: -4px;
  width: 24px;
}

#testim-content h2 {
  text-transform: uppercase !important;
}

#testim-content h2,
#testim-content p {
  color: white;
}

.margin-unset {
  margin: unset !important;
}

.min-height-65 td {
  min-height: 65px;
  height: 65px !important;
}

.form-h6 {
  text-align: left;
  color: rgb(72, 82, 87);
  font-size: 13px;
  font-weight: 400;
  font-family: Oakes-Grotesk-Regular;
  line-height: 18px;
  margin: 15.94px 0px 0px;
}

.view-profile-navigate {
  width: 134px;
  display: inline-block;
  background: rgba(78, 100, 221, 0.12);
  text-align: center;
  height: 34px;
  line-height: 34px;
  border-radius: 5px;
  text-transform: capitalize;
}

.view-profile-navigate img {
  margin-right: 5px;
}

.height-unset {
  height: unset !important;
}

.font-600 {
  font-weight: 600 !important;
}

.client-agreement-validations {
  margin-left: 40px;
  margin-top: -5px;
}

.capitalize {
  text-transform: capitalize !important;
}

.confirm-delete-wrapper .modal-dialog {
  height: 340.74px !important;
}

.confirm-delete-wrapper .register-medium-confirnation,
.client-reschedule-btns {
  width: 160px !important;
}

.confirm-delete-wrapper .confirm-btn-box {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 35px;
}

.d5a67b {
  color: #d5a67b;
}

.appointment-select-box {
  width: 100px;
  /* min-width: 50px; */
  height: 30px;
  /* width: unset !important; */
}

.selection-box > * {
  text-transform: capitalize !important;
}

.coming-soon-btn {
  display: inline-block;
  background-color: #d5a67b;
  margin: 26.1px 0 0 0;
  box-shadow: 0px 5px 10px #e0e4ee;
  width: 184px;
  height: 61px;
  line-height: 61px;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
  cursor: pointer;
}

.coming-soon-btn:hover {
  color: #fff;
}

.font-size-42 {
  font-size: 42px;
}

.font-size-28 {
  font-size: 28px !important;
}
.font-size-26 {
  font-size: 26px !important;
}

.box-dashboard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.box-dashboard .button-cla {
  padding-right: unset !important;
}

.box-dashboard .button-cla .btn-dash {
  width: 230px !important;
  padding: unset !important;
}

.box-dashboard .button-cla .modal-dialog {
  width: 818px;
  height: 651px;
  padding: 49px 0;
  transform: translate(0, 20%);
}

.loaderline {
  color: #4e64dd;
  position: absolute;
  left: 97vw;
  top: -23px;
  font-size: 16px;
  font-weight: 600;
}

.prgress-baar-new {
  transition: "width 1s ease-in-out";
}

.padding-10 {
  padding: 10px !important;
}
.nva-link-button-inactive .special-event-buttons {
  outline: 1px solid #fff !important;
}
.nva-link-button-inactive {
  background-color: unset !important;
  border-color: #d5a67b !important;
}
.special-event-buttons {
  width: 100%;
  background-color: #d5a67b;
  border-radius: 40px !important;
  padding: 15.82px 26px;
  display: flex !important;
  align-items: center !important;
}
.w-unset {
  width: unset !important;
}

.h-unset {
  height: unset !important;
}

.do-a-session-note {
  margin-top: 30px;
  font-size: 20px;
  line-height: 30px;
  /* max-width: 70%;
  margin: 0 auto; */
}

.do-a-session-note-new {
  max-width: 950px;
  margin: 30px auto 0 auto;
}

.do-a-seesion-link-first-line {
  width: 2px;
  height: 15px;
  background-color: #d5a67b;
  display: inline-block;
}

.do-a-seesion-link,
.do-a-seesion-link-first {
  text-decoration: none;
  color: black;
  font-weight: 100;
  margin: 15px;
}

.do-a-seesion-link:hover,
.do-a-seesion-link-first:hover {
  text-decoration: none;
  color: black;
}

.demo-tag {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.loader-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/localImages/session-loader.png);
  width: 1002px;
  height: 430px;
  background-position: center;
  background-size: 1500px;
  background-repeat: no-repeat;
}
.loader-video {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80%;
  margin: 0 auto;
}

.loader-img img {
  width: 82px;
  position: relative;
  left: 16px;
  top: 7px;
}

.back-btn-appointments {
  display: inline-block;
  width: 80%;
  text-align: left;
}

.back-btn-appointments .bck {
  display: flex;
  justify-content: center;
  border: 1px solid #cacaca;
  align-items: center;
  width: 120px;
  background-color: white;
  height: 30px;
  color: #2f2f2f;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  text-decoration: none;
}

.back-btn-appointments .bck img {
  margin-right: 10px;
  /* margin-left: 10px; */
}

.resend-btn {
  background-color: #d5a67b;
  width: 80px;
  height: 45px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
}
.resend-btn-payment {
  background-color: unset !important;
  color: #d5a67b !important;
  border: 1px solid #cacaca;
}
.resend-btn-payment:hover {
  border: 1px solid !important;
}

.resend-btn:hover {
  background-color: #d5a67b;
}

.anchor-white > a {
  color: #485257;
}

.anchor-white > a:hover {
  color: #485257 !important;
  text-decoration: none;
  background-color: white !important;
}

.table-box-medium tr.innr:hover .anchor-white a {
  color: #485257 !important;
  text-decoration: none;
  background-color: transparent !important;
}

.margin-top-300 {
  margin-top: 300px;
}

.inline-block {
  display: inline-block !important;
}
.w-50-px {
  width: 50px !important;
}
.w-70-px {
  width: 70px !important;
}
.w-100px {
  width: 100px !important;
}
.w-100-px {
  width: 130px !important;
}
.w-140 {
  width: 140px !important;
}
.w-150 {
  width: 150px !important;
}

.w-170 {
  width: 170px !important;
}
@media (min-width: 471px) {
  .w-133 {
    width: 130px !important;
  }
}
@keyframes blink {
  50% {
    outline-color: red;
  }
}

.tooltip-1::after,
.tooltip-2::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000eb transparent transparent transparent;
}

.tooltip-1,
.tooltip-2 {
  display: none;
  position: absolute;
  z-index: 2;
  background: #000000eb;
  color: white;
  width: 300px;
  padding: 10px;
  border-radius: 12px;
  top: -85px;
}

.tooltip-1 {
  left: 10px;
}

.tooltip-2 {
  left: 28px;
}

.tooltip-1-focus:hover ~ .tooltip-1 {
  display: block;
}

.tooltip-2-focus:hover ~ .tooltip-2 {
  display: block;
}
.height-10 {
  height: 10px !important;
}
.height-45 {
  height: 45px !important;
}
.height-50 {
  height: 50px !important;
}
.height-56 {
  height: 56px !important;
}

.height-35 {
  height: 35px !important;
}
.min-width-300px {
  min-width: 300px;
}
.min-width-130px {
  min-width: 130px;
}
.min-width-170px {
  min-width: 170px;
}
.min-width-70px {
  min-width: 70px !important;
}

.verification-code-section {
  width: 70%;
}

.verification-code-section input {
  margin: 0 0 5px 0;
  max-width: 55%;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.font-size-11 {
  font-size: 11px;
}

.red {
  color: red !important;
}

.green {
  color: green;
}

.color-d5a67b {
  color: #d5a67b !important;
}
.color-black {
  color: black !important;
}
.color-555 {
  color: #555 !important;
}
.bg-green {
  background-color: green !important;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.border-unset {
  border: unset !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.validate-container {
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: center;
}

.min-height-80vh {
  min-height: 80vh !important;
}
.min-height-500px {
  min-height: 500px !important;
}
.min-height-100vh {
  min-height: 100vh !important;
}
@media only screen and (max-width: 600px) {
  #content-wrapper {
    overflow: scroll !important;
  }

  .overflow-scroll {
    overflow: scroll !important;
  }
}

@media only screen and (max-width: 767px) {
  .min-width-130px {
    min-width: unset !important;
  }
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 61.37px !important;
}

.form-control:focus {
  outline: unset !important;
  box-shadow: unset !important;
}

.w-44 {
  width: 44% !important;
}

.alert-number {
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.medium-tab-pad li {
  padding: 0;
}

.max-width-900 {
  max-width: 900px;
}

.linking-error {
  max-width: 90%;
  margin: 10px auto;
}

.linking-error span {
  background: red;
  color: white;
  padding: 12px 45px;
  border-radius: 12px;
  margin-top: 10px;
  display: inline-block;
}

.disabled-cursor {
  cursor: not-allowed !important;
}

.blurred {
  background-color: #d5a67b73 !important;
}

.allow-image {
  max-width: 500px;
  width: 100%;
  border: 1px solid;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.proceed-btn {
  background-color: #485257;
  color: #fff;
  border-color: #485257;
}

.background-beige {
  background-color: beige !important;
}

.background-unset {
  background: unset !important;
}
#sessionstatus1:checked::before {
  border-color: green;
  background-color: green;
}

#sessionstatus2:checked::before {
  border-color: red;
  background-color: red;
}

#sessionstatus3:checked::before {
  border-color: yellow;
  background-color: yellow;
}

.change-email-request {
  color: #4e64dd;
  text-decoration: underline;
  display: inline-block;
}

.width-345px {
  width: 345px !important;
}
.width-470px {
  width: 470px !important;
}

.width-80px {
  width: 80px;
}
.width-245px {
  width: 245px !important;
}
.width-470px {
  width: 470px !important;
}

.padding-unset {
  padding: unset !important;
}

.new-btn-click-here {
  color: white !important;
  background-color: #d5a67b !important;
}

.new-btn-click-here:hover {
  color: white !important;
  background-color: #d5a67b !important;
}

.profile-image-avatar {
  width: 48px;
  height: 48px;
}

.clear-both {
  clear: both;
}

.chevron-aaa:after {
  content: " ";
  display: inline-block;
  border-bottom: 1px solid #f00;
  border-right: 1px solid #f00;
  height: 10px;
  width: 10px;
  transform: rotate(-45deg);
}

/* .statusBar {
  display: flex;
} */
._item0 {
  display: inline-block;
  background: #d3d3d347;
  height: 52.32px;
  position: relative;
  flex-grow: 1;
  width: 250px;
  text-align: center;
  line-height: 52.32px;
}

._item0 label {
  color: black !important;
}

._item1 {
  display: inline-block;
  background: red;
  height: 52.32px;
  position: relative;
  flex-grow: 1;
  text-align: center;
  line-height: 52.32px;
  width: 150px;
}

._item2 {
  display: inline-block;
  background: green;
  height: 52.32px;
  position: relative;
  flex-grow: 1;
  text-align: center;
  line-height: 52.32px;
  width: 250px;
}

.statusBar ._item._item0:nth-child(1) {
  width: 150px;
}

._item3 {
  display: inline-block;
  background: #d5a67b;
  height: 52.32px;
  position: relative;
  flex-grow: 1;
  text-align: center;
  line-height: 52.32px;
  width: 250px;
}

._item::before,
._item::after {
  display: inline-block;
  content: "";
  border-style: solid;
  position: absolute;
}

._item::before {
  left: 0;
  border-width: 27px 0 27px 15px;
  border-color: transparent transparent transparent white;
}

._item::after {
  right: 0;

  border-width: 27px 0 27px 15px;

  border-color: white transparent;
}

.remove-styles input {
  min-width: unset !important;
  height: unset !important;
  padding: unset !important;
  border-radius: unset !important;
  border: unset !important;
  width: unset !important;
  appearance: none !important;
  cursor: pointer;
  margin: unset !important;
}

.remove-styles label {
  min-width: unset !important;
  height: unset !important;
  padding: unset !important;
  border-radius: unset !important;
  border: unset !important;
  font-size: 16px;
  /* width: unset !important; */

  width: 100%;
  text-align: center;

  color: white;
  cursor: pointer;
}

.width-140px {
  width: 140px !important;
}

.width-150px {
  width: 150px;
}
.max-width-150px {
  max-width: 150px;
}
.width-190px {
  width: 190px !important;
}

.check-box-input img {
  position: relative;
  top: 4px;
  right: 4px;
}

@media (max-width: 767px) {
  .statusBar .remove-styles {
    margin-bottom: 15px;
  }

  ._item1 {
    width: 100%;
  }

  ._item0 {
    width: 100%;
  }

  .statusBar ._item._item0:nth-child(1) {
    width: 100%;
  }

  ._item2 {
    width: 100%;
  }
}

.seesion-error-message {
  font-size: 12px;
  color: white;
  /* width: 40px; */
  height: 40px;
  text-align: center;
  max-width: 45%;
  margin: 0 auto;
  margin-top: 22px;
}

._to_be_decided {
  font-size: 30px;
  transform: rotateZ(314deg);
  font-weight: bold;
  margin-top: 5px;
  left: 35%;
}

._coming_soon {
  transform: unset !important;
  margin-top: 40px !important;
}

._tb_parent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 45%;
  border-radius: inherit;
  color: gray;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-1 {
  opacity: 0.5;
}

._tbd-to-0 {
  top: 0 !important;
}

._tbd-left {
  left: 30%;
}

._tbd-z-index-1 {
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  ._to_be_decided {
    font-size: 24px;
    margin-top: -12px;
    margin-left: 30px;
  }

  ._coming_soon {
    transform: unset !important;
    margin-top: 16px !important;
  }
}

.unset-float {
  float: unset !important;
  width: unset !important;
  text-align: unset !important;
}

.exportall-btn {
  margin-right: 10px;
  padding: 14px;
  background: #d5a67b;
  color: white;
  text-decoration: none;
  border-radius: 6px;
}

.exportall-btn:hover {
  color: white;
  text-decoration: none;
}

.export-btn {
  padding: 14px;
  background: #d5a67b;
  color: white;
  text-decoration: none;
  border-radius: 6px;
}

.export-btn:hover {
  color: white;
  text-decoration: none;
}

.public-profile-picture {
  width: 136px !important;
  height: 136px !important;
  border-radius: 50% !important;
  /* outline: solid white; */
  border: 3px solid white !important;
  object-fit: cover;
}

.public-profile-btn {
  background: #d5a67b;

  text-decoration: none;
  color: white;
  padding: 14px;
  border-radius: 8px;
}

.public-profile-btn:hover {
  text-decoration: none;
  color: white;
}

.amount_input {
  height: 50.37px !important;
}

.copy-image-animate:hover {
  transform: scale(1.5);
}

.prof-price-info {
  display: block;
  margin-left: 62px;
  margin-top: -18px;
}

.white-background {
  background-color: white;
}
.white {
  color: white;
}

.tr-padding {
  padding: 25px 0 0px 15px;
}

.min-height-90vh {
  min-height: 90vh;
}
.dbb38f {
  color: #dbb38f;
}
.dbb38f-border {
  border: 2px solid #dbb38f;
}
.dbb38f-background {
  background-color: #dbb38f !important;
  color: white !important;
}
.schedule-day-name {
  font-weight: 800;
  line-height: 13px !important;
  min-width: 90px !important;
}
.schedule-date-name {
  font-weight: 800;
  /* width: 96px; */
  width: 120px;
}
.schedule-time-from-to {
  /* width: 134px !important; */
  width: 165px !important;
}
.schedule-additional-settings {
  font-size: 14px;
  contain: inline-size;
}
.schedule-additional-settings p {
  display: inline !important;
}
.schedule-additional-settings span {
  display: inline !important;
}
.settings {
  font-weight: 800;
}
.practioner-profile-image {
  object-fit: cover;
  width: 100% !important;
  border-radius: 50%;
  height: 100%;
  padding: 1px;
}
.fc-col-header thead .fc-scrollgrid-sync-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #d5a67b;
}
.fc-col-header thead .fc-timegrid-axis {
  background-color: #d5a67b !important;
}
.fc-col-header thead .fc-scrollgrid-sync-inner a {
  color: white !important;
}

.fc-col-header thead tr {
  height: 40px !important;
}
.middle-screen-name {
  display: inline-block;
  width: 55%;
  text-align: center;
}
.time-zone-area {
  width: 100% !important;
  text-align: right !important;
}
.time-zone-area-client {
  width: unset !important;
}
.negative-margin-20-top {
  margin-top: -20px;
}
.negative-margin-15-left {
  margin-left: -15px;
}
.image-advance-client-profile {
  /* padding: 3px !important */
}
.image-advance-client-profile img {
  /* width: 45px !important;
  height: 45px !important; */
}
.select-box-timezone {
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-right: 16px;
  border-radius: 13px;
  padding: 10px;
}
.action-redirect-btn {
  text-align: center;
  display: block;
  width: 120px;
  background: #d5a67b;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 4px;
  cursor: pointer;
  padding: 20px 5px;
  /* float: right; */
  margin: 0 auto !important;
  height: auto;
  text-decoration: none;
  margin-top: 10px !important;
}
.action-redirect-btn:hover {
  text-decoration: none;
  color: white;
}
.non-clickable-state {
  opacity: 0.4;
  cursor: not-allowed;
}

.rotate-image {
  -webkit-animation: rotate 1s infinite linear;
  animation: rotate 1s infinite linear;
}
.feedBack-action-box-medium {
  display: flex;
  align-items: center;
  text-align: right;
  width: 100%;
  justify-content: end;
  gap: 30px;
}
.banner-stop-text {
  font-size: 20px !important;
  border: 2px solid;
  padding: 15px 0px 5px 0px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .registration-background-black {
  background: #0000005e;
  height: 100vh;
} */
.remove-padding {
  padding-left: unset !important;
  padding-right: unset !important;
}
.slider-banner-height {
  height: 100vh;
  display: flex;
}
.black-container {
  background: linear-gradient(to right, #0000005e 50%, transparent 50%);
}
.practioner-eye-image {
  width: 40px !important;
  height: 40px !important;
}
.border-feed {
  border-bottom: 1px solid #efefeb;
  padding-bottom: 10px;
}
.feedback-border-feed {
  border-bottom: 1px solid #00000040;
  padding-bottom: 10px;
}

.tb-donated {
  font-size: 64px;
  /* background: white; */
  position: absolute;
  top: 50%;
  left: 20%;
  transform: rotate(-45deg);
}
.border-instructions {
  border: 1px solid black;
  padding: 10px;
}
.mt-top {
  margin-top: 150px !important;
}
.width-440px {
  width: 440px;
}
.width-430px {
  width: 430px;
}

.z-index-2 {
  z-index: 2 !important;
}
.line-height-unset {
  line-height: 19px !important;
}
.public-DraftEditor-content div::-webkit-scrollbar {
  display: none;
}
.rdw-editor-wrapper {
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 5px;
  height: 163.66px;
  padding: 10px;
}
.public-DraftEditor-content div {
  overflow: scroll;
  max-height: 121px;
}

.star-imh-moon {
  width: 12px !important;
  height: 12px !important;
}
.time-option span {
  /* min-width: 80px !important; */
  width: unset !important;
}
.multiple-datepicker {
  position: relative;
}
.multiple-datepicker > div:before {
  content: "Copy Time Period";
  display: flex;
  align-items: center;
  padding: 0px 10px;
  position: absolute;
  cursor: pointer;
  /* background-color: green; */
  /* width: 20px;
  height: 20px; */
  border: 0.5px solid #485257;
  border-radius: 5px;
  background-color: #fff;
  color: #485257;
  height: 50px;
  width: 140px;
  padding: 0;
  margin-bottom: 11px;
  font-size: 14px;
  /* text-align: center; */
  justify-content: center;
  margin-top: -13px;
}
.multiple-datepicker > div > input {
  position: relative;
  /* margin-top: -20px; */
  background-color: transparent;
  padding: unset;
  width: 100px;
  cursor: pointer;
  /* visibility: hidden; */
  opacity: 0;
}
.specific-dated-text-box {
  align-items: center;
  justify-content: space-between;
  width: 70%;
  display: flex;
  margin-top: 20px;

  margin-left: 40px;
}
.sc-jTzLTM {
  background-color: #dbb38f !important ;
}
.gKyapV {
  background-color: #dbb38f !important;
}
.eeGpAO:hover {
  background-color: #dbb38f6b;
  /* border-color: #dbb38f6b;
  border-radius: 50%;
  width: 34px;
  height: 34px; */
}
.dyHccB {
  background-color: #dbb38f;
}
.dyHccB:hover {
  background-color: #dbb38f6b;
  color: white;
}
.dyHccB,
.dsKVpZ {
  color: #dbb38f !important;
}
.dyHccB:hover,
.dsKVpZ:hover {
  color: white !important;
  background-color: #dbb38f;
}
.z-index-negative-1 {
  z-index: -1 !important;
  position: relative;
}
.feedback-delete-section img {
  width: 15px !important;
  height: 15px !important;
  margin-left: 20px;
}
.specific-specific-blocked-date {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.margin-left-auto {
  margin-left: auto;
}
.bg-theme-d5a67b {
  background-color: #d5a67b !important;
  color: white !important;
  border: unset !important;
}
.about-us-review-close:hover {
  color: unset !important;
}
@media (min-width: 800px) {
  .calendar-view #previous {
    position: absolute;
    top: 22px;
    left: 12rem;
    margin: 0 auto;
  }
  .calendar-view #next {
    position: absolute;
    top: 22px;
    right: 12rem;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .calendar-view {
    padding: 0 !important;
  }
  .calendar-view .middle-screen-name {
    display: inline-block;
    width: 100%;
    text-align: left;
    margin: 0 !important;
    padding: 12px 13px;
  }
  .calendar-view .mr-5 {
    margin: 18px 0 0 0 !important;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  .calendar-view .date-picker > div {
    /* min-width: auto !important; */
  }
  .calendar-view .table-calendar td {
    padding: 0px 14px !important;
  }

  .export-btn {
    display: inline-block;
    width: 100%;
    margin: 7px 0;
    text-align: center;
  }
  .exportall-btn {
    margin-right: 10px;
    display: inline-block;
    width: 100%;
    margin: 7px 0;
    text-align: center;
  }
  .table-box-medium table {
    width: 800px !important;
    padding: 0 6px 0 0;
  }
  .table-box-medium td label {
    margin: 4px 0 0 0 !important;
  }
  .table-box-medium td img {
    margin: 2px 0 0 6px;
  }
  .table-box-medium td label::before {
    width: 18px;
    height: 20px;
  }
  .table-box-medium td input:checked + label::after {
    left: 6px;
  }
  .googl-link-copy-img.copy-image-animate {
    width: auto;
    height: auto;
  }
  .refresh-btn-app {
    width: 14px !important;
    height: 14px !important;
  }
  .loading-regenrate {
    font-size: 12px !important;
  }
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    padding: 0;
  }
  .MuiTablePagination-selectLabel {
    margin: 0;
  }
  .MuiTablePagination-displayedRows.css-levciy-MuiTablePagination-displayedRows {
    margin: 0;
  }
  .sort-box {
    margin-top: 0 !important;
  }
  .scren-name {
    position: relative;
    bottom: 6px;
  }
  .ul-li-list li {
    width: 100%;
    margin-right: 0;
  }
  #paypal_me_link {
    height: 45px !important;
  }
  .login-box {
    padding: 15px;
  }
  .update {
    margin: 0 !important;
  }
  .wizard .nav-tabs > li {
    width: 33%;
  }
  .css-6j8wv5-Input {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto !important;
    width: 96%;
  }
  #country:active:focus:focus-visible {
    outline: none !important;
  }
  .cuntrey-website .css-319lph-ValueContainer {
    padding: 0 10px !important;
  }
  #timezone .css-qc6sy-singleValue {
    max-width: 340px;
  }
  .from-step-new .css-1rhbuit-multiValue {
    position: relative;
    bottom: 0;
  }

  .thanku-popup .modal::before {
    display: none;
  }
  .thanku-popup .modal-dialog {
    margin: 4rem 0 0 0;
  }
  .closebt .close {
    padding: 10px 30px !important;
  }
  .coming-soon p {
    font-size: 16px !important;
    line-height: 24px !important;
    margin: 3rem 0 0 !important;
    width: 100%;
  }
}

@media (max-width: 320px) {
  #timezone .css-qc6sy-singleValue {
    max-width: 249px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .all-medium-page .tabs li {
    padding: 10px 8px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .export-btn {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 20px 0 0 0;
  }
  .exportall-btn {
    margin-right: 10px;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 20px 0 0 0;
  }
  .table-box-medium {
    margin: 22px 0 0 0;
    width: 100%;
    overflow: scroll;
  }
  .table-box-medium table {
    width: 1000px !important;
    padding: 0 5px;
  }
  .table-box-medium td label::before {
    margin: 0 12px 0 0 !important;
  }
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    padding: 0;
  }
  .MuiTablePagination-selectLabel {
    margin: 0;
  }
  .MuiTablePagination-displayedRows.css-levciy-MuiTablePagination-displayedRows {
    margin: 0;
  }
  .sort-box {
    margin-top: 0 !important;
  }
}

.calendar-view .middle-screen-name {
  display: inline-block;
  width: 100%;
  text-align: left;
  margin: 0 !important;
  padding: 12px 13px;
}
.calendar-view .mr-5 {
  margin: 18px 0 0 0 !important;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 16px;
}
.calendar-view .date-picker > div {
  /* min-width: auto !important; */
}
.calendar-view .table-calendar td {
  padding: 0px 14px !important;
}
.fc-timeGridWeek-view table {
  min-width: 1100px !important;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 12px 4px !important;
  font-size: 14px !important;
  font-weight: 400;
}
.fc-view-harness.fc-view-harness-active {
  overflow: scroll;
}
.fc-header-toolbar {
  max-width: 340px;
  margin: 20px auto;
}
.fc-header-toolbar .fc-toolbar-title {
  font-size: 20px;
  padding: 0 10px;
}

.fc-header-toolbar .fc-prev-button,
.fc-header-toolbar .fc-next-button {
  background-color: #d5a67b;
}
.calendar-view-creoss-icin {
  position: absolute !important;
  z-index: 99999;
  right: 25px;
}

.unlink-buttton {
  display: inline-block;
  text-align: right;
  width: 100%;
  position: absolute;
  bottom: 8px;
  right: 13px;
  z-index: 98;
}
.bar-wrapper-note-calendar {
  display: flex;
}
.bar-wrapper-note-calendar {
  width: 400px !important;
  line-height: 18px;
}
.bg-pointer-selected {
  background-color: #d5a67b24;
}
.wga-members-text {
  display: inline-block;
  font-size: 20px;
  margin-top: 25px;
  border: 2px solid black;
  padding: 10px;
  margin-bottom: unset;
  font-weight: bold;
}
.wga-members-text span {
  color: #ac8424;
}
.report-url {
  color: #000000bd !important;
  background: white !important;
}
.report-url,
.report-url:hover,
.report-url:active {
  color: #000000bd !important;
  background: white !important;
}
.advanceConfirmbooking {
  min-height: 454px !important;
}
.css-4ljt47-MenuList {
  max-height: 200px !important;
}

.downward-arrow {
  transform: rotatex(178deg);
}
.hover-stop-navlink:hover {
  background-color: unset !important;
  color: white !important;
}
.hover-stop-navlink:hover .wight {
  display: inline !important;
}

.hover-stop-navlink:hover .browan {
  display: none !important;
}
.border-bottom-sidebar {
  border-bottom: 1px solid #dbb38f;
}
.border-bottom-sidebar a {
  border-bottom: unset !important;
}

.new-sidebar-img {
  /* width: 22px !important; */
  height: 23px !important;
}
.CreateNewAppointment {
  width: 335px !important;
}
.CreateNewAppointment input {
  display: block !important;
}
.d-flex-appointment {
  display: flex;
}

@media (max-width: 991px) {
  .d-flex-appointment {
    display: unset;
  }
  .d-flex-date-time {
    display: flex;
  }
  .CreateNewAppointment {
    width: 100% !important;
  }
  .timeAppointment select {
    border: 1px solid #ccc;
    width: 100%;
  }
}
.eroorbox {
  background: red;
  height: 40px;
  border-radius: 8px;
  margin-bottom: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.moon-create-app {
  background-image: url(/localImages/moon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
#CreateNewAppointment .width-unset,
#NoshowBox .width-unset,
#UpdateAppointments .width-unset,
#ReScheduleAppt .width-unset {
  width: unset !important;
}
.width-335px {
  width: 335px !important;
}
.width-70px {
  width: 70px !important;
  margin-right: 20px;
}

.warning-color {
  background-color: #ff9800 !important;
}
.user-saction {
  display: flex;
  align-items: center;
}
.user-saction a {
  width: 170px;
}
.refresh-btn-app {
  width: 14px;
  height: 14px;
}
.refresh-btn-app:hover {
  transform: rotate(90deg) scale(1.5);
  transition-duration: 0.3s;
}

.loading-regenrate:after {
  content: " .";
  animation: dots-reg 1s steps(5, end) infinite;
}

.box-sizing-border-box {
  box-sizing: border-box !important;
}

.legend-calendar::before {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  margin: 10px;
}

.FF9500-Starter::before {
  background-color: #ff9500;
}
.CD964-Donated::before {
  background-color: #4cd964;
}

.AC8FA-PostPay::before {
  background-color: #5ac8fa;
}

.FECB2E-PrePay::before {
  background-color: #fecb2e;
}
.FECB2E-Blocked::before {
  background-color: #8f8f8f;
}
.C39BD3-extra::before {
  background-color: #c39bd3;
}
.FF0D2-practice::before {
  background-color: #3ff0d2;
}
.legend-calendar {
  display: flex;
  align-items: center;
  margin: 10px;
}
.paymymedium-wrapper {
  display: flex;
  align-items: center;
  height: 80vh;
  text-align: center;
  width: 100%;
  justify-content: center;
}
.paymymedium-container {
  max-width: 1000px;
  border: 1px solid #00000003;
  padding: 70px;
  box-shadow: 0px 0px 9px -5px;
}
.paymymedium-container p {
  font-size: 29px;
  line-height: 40px;
}
#calendar-image-1 {
  display: none;
}
#calendar-image-2 {
  display: block;
}
.save-btn-clinet-registration:hover #calendar-image-2 {
  display: none;
}
.save-btn-clinet-registration:hover #calendar-image-1 {
  display: block;
}

/* .save-btn-clinet-registration:hover + #calendar-image-2 {
  display: block
}
.save-btn-clinet-registration:hover ~ #calendar-image-1 {
  display: none
} */
/* #calendar-image-1{
  display: none;
}
#calendar-image-2{
  display: none;
}
.save-btn-clinet-registration:hover ~ #calendar-image-1 {
  display: block;
} */

@keyframes dots-reg {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}

.appointmentconfirmbox * {
  box-sizing: border-box;
}
.star-app-hightlight {
  width: 12px;
}
.padding-botton-120px {
  padding-bottom: 120px !important;
}
.learn-more-btn {
  width: 120px;
  display: inline-block;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  border-radius: 6px;
  background-color: #d5a67b;
}
.learn-more-btn:hover,
.learn-more-btn:active {
  color: white !important;
  background-color: #d5a67b !important;
}

.ConfirmReschedule .modal-dialog {
  height: 380px !important;
}
.block-tooltip-app {
  top: -45px !important;
  left: -69px !important;
}

.margin-auto {
  margin: 0 auto !important;
}
.top-unset {
  top: unset !important;
}
.margin-auto {
  margin: 0 auto !important;
}
.margin-200 {
  margin: 70px 200px !important;
}
@media (max-width: 768px) {
  .margin-200 {
    margin: 70px 20px !important;
  }
  .margin-200 .jdbotF {
    width: 350px !important;
    font-size: 13px !important;

  }
  .margin-200 h4{
    padding: unset !important;
  }
  .width-415-px {
    width: 350px !important;
    font-size: 13px !important;
  }
}

@media (max-height: 450px), (max-width: 450px) {
  .rmdp-day,
  .rmdp-week-day {
    height: 24px !important;
    width: 24px !important;
  }
  .rmdp-wrapper {
    position: relative !important;
    /* left: 37px !important;sgit */
    z-index: 10000000 !important;
  }
}

.appoint-das .client-reschdule-new-flow td div span {
  float: unset !important;
  width: 50px !important;
  margin: unset !important;
}
