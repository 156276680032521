.single-title.page-title.entry-title {
  margin-top: 9rem;
  color: #000;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
}

.page-title {
  display: block;
  font-size: 1.7rem;
}

.terms-container {
  background-color: white;
  /* max-width: 100%; */
  max-width: 910px;
  margin: 0 auto;
  padding: 30px !important;
  box-shadow: 0px 0px 15px 0px rgb(82 63 105 / 5%);
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0);
}
.terms-wrapper {
  margin-top: 1em;
  margin-bottom: 0em;
}
.terms-date {
  color: #7a7a7a !important;
  font-size: 20px !important;
}
.main-title , .terms-container h2 {
  color: #000000;
  font-family: "Be Vietnam", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 20px;
  margin-top: 20px;
}
.title-desc, .terms-container  p , .terms-container li{
  color: #000000;
  /* font-size: 1.5em; */
  line-height: 22px;
  font-size: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-30 {
  margin-top: 30px;
}
.text-center{
text-align: center;
}
.font-400{
font-weight: 400;
}
.text-underline{
text-decoration: underline !important;
}