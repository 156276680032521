.assignsessionsbylevel-all-box {
	display: inline-block;
	width: 100%;
	margin: 58px 0 0 0;
	padding-right: 30px;
}
.left-top-box {
	float: left;
	width: 50%;
	text-align: left;
}
.right-top-box {
	float: right;
	width: 50%;
	text-align: right;
}
.left-top-box span {
	color: #485257;
	font-size: 20px;
	font-weight: 500;
	font-family: 'Montserrat', sans-serif !important;
}
.right-top-box button.btn-back {
	border: 0.5px solid #485257;
	width: 212px;
	height: 54px;
	text-align: center;
	background-color: #F5F5F5;
	color: #485257;
	font-size: 18px;
	border-radius: 6px;
	font-family: 'Montserrat', sans-serif !important;
}
.right-top-box button.sumbit-btn {
	border: 0.5px solid #485257;
	width: 212px;
	height: 54px;
	text-align: center;
	background-color: #485257;
	color: #fff;
	font-size: 18px;
	border-radius: 6px;
	font-family: 'Montserrat', sans-serif !important;
	margin: 0 0 0 15px;
}
.top-box-saction {
	display: inline-block;
	width: 100%;
}
.assignsessionsbylevel-box-select {
	display: inline-block;
	width: 100%;
	margin: 47px 0 0 0;
}
.assignsessionsbylevel-box-select label {
	margin: 0;
	width: 100%;
	color: #485257;
	font-size: 18px;
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 500;
}
.assignsessionsbylevel-box-select input {
	width: 100%;
	border: 0.5px solid #8E8E8E;
	height: 50px;
	border-radius: 5px;
	padding: 0 15px;
	font-size: 18px;
	font-weight: 500;
	font-family: 'Montserrat', sans-serif !important;
	margin: 11px 0 0 0;
	background-color: #fff;
}
.assignsessionsbylevel-box-select form {
	display: inline-block;
	width: 90%;
}
.assignsessionsbylevel-filde {
	display: inline-block;
	width: 100%;
	margin-bottom: 27px;
}
.assignlevel-table {
	display: inline-block;
	width: 100%;
}
.assignlevel-table table {
	width: 100%;
}
.assignlevel-table table tr th {
	background-color: #F5F5F5;
	padding: 18px 10px;
	text-align: center;
}
.assignlevel-table table tr th {
	background-color: #F5F5F5;
	padding: 18px 10px;
	text-align: center;
	color: #485257;
	font-size: 18px;
	font-family: 'Montserrat', sans-serif !important;
}
.assignlevel-table table tr td span {
	width: 100%;
	display: inline-block;
	color: #485257;
	font-size: 18px;
	margin: 0 0 10px 0;
}
.text-align-center-unset{
	text-align: unset !important;
	padding: 10px 48px !important;
  }

.assignlevel-table table tr td {
	color: #485257;
	font-size: 18px;
	text-align: center;
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 500;
	padding: 37px 0 ;
}
.assignlevel-table table tr td:nth-child(2) {
	width: 45%;
}
.assignlevel-table table tr td:last-child span {
	text-align: left;
}
.assign-level-select input{
	/* width: 100% !important;
    border: 0.5px solid #8E8E8E !important;
    height: 50px !important;
    border-radius: 5px;
    padding: 0 15px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    font-family: 'Montserrat', sans-serif !important;
    margin: 11px 0 0 0 !important;
    background-color: #fff !important; */
}
.approved-single-select{
	width: 100% !important;
    border: 0.5px solid #8E8E8E !important;
    height: 50px !important;
    border-radius: 5px;
    padding: 0 15px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    font-family: 'Montserrat', sans-serif !important;
    margin: 11px 0 0 0 !important;
    background-color: #fff !important;
}