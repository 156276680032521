.bookingperiodbar-wrapper {
  background-color: white;
  padding: 40px 20px 15px 20px;
  margin-top: 20px;
  /* margin-left: 100px; */
}
.bookingperiodbar-container {
  display: flex;
  height: 40px;
  align-items: center;
  border: 1px solid;
}
.div-minimum-time {
  height: 100%;
  display: flex;
  align-items: center;
  width: 15%;
  position: relative;
}
.div-maximum-time {
  height: 100%;
  display: flex;
  align-items: center;
  width: 70%;
  background-color: #90ee90;
  position: relative;
}
.div-minimum-time-container {
  width: 100%;
  text-align: center;
}
.div-maximum-time-container {
  width: 100%;
  text-align: center;
}

.div-minimum-time::before {
  content: "Now";
  position: absolute;
  top: -15px;
}
.div-minimum-time::after {
  content: "Minimum";
  position: absolute;
  top: -15px;
  right: -35px;
}
.div-maximum-time::after {
  content: "Maximum";
  position: absolute;
  top: -15px;
  right: -35px;
}
.div-maximum-time-container::after {
  content: "Potential Booking Period*";
  position: absolute;
  top: -15px;
  left: 40%;
  /* right: -35px; */
}
.bookingperiodbar-btn {
  margin-top: 12px;
  background: transparent;
  color: black;
}
.bookingperiodbar-btn:hover {
  background: transparent;
  color: black;
}
.bar-wrapper {
  display: flex;
  /* align-items: baseline; */
  gap: 10;
}
.bar-wrapper p {
  margin: 30px 0 0 0 !important;
  margin-left: 30px !important;
  width: 400px !important;
  line-height: 18px;
}

@media (max-width: 600px) {
  .bookingperiodbar-wrapper {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
  }
  .div-minimum-time::before {
    top: -36px !important;
  }
  .div-minimum-time::after {
    top: -35px !important;
  }
  .div-maximum-time::after {
    top: -38px !important;
  }
  .div-maximum-time-container::after {
    top: -22px !important;
  }
}
