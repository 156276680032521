
/*admin-appointments-page*/
.table-box-medium{
	min-height: 80vh;
}
.table-box-medium.admin-appoi td span {
	float: left;
	text-align: left;
	width: 65%;
	margin: 5px 0 0 0;
}
.table-box-medium.admin-appoi td.change a {
	width: 80px;
	background-color: gba(78, 100, 221, 0.07);
	padding: 6px 0;
}
.table-box-medium.admin-appoi td.change a img {
	margin: 0 7px 0 0;
	position: relative;
	top: 4px;
}
.table-box-medium.admin-appoi td label {
	margin: 0;
}
#serviceType{
	height: unset !important;
}
.admin-clintepage td a {
	width: 134px;
	display: inline-block;
	background: rgba(78, 100, 221, 0.12);
	text-align: center;
	height: 34px;
	line-height: 34px;
	border-radius: 5px;
	text-transform: capitalize;
}
.admin-clintepage td a img {
	margin: 0 7px 0 0;
}
.table-box-medium tr.innr:hover {
	background-color: #F7F7F3;
}
.table-box-medium tr.innr:hover a {
	background-color: #4E64DD;
	color: #fff;
}
.clin-name-fed span {
	width: 70% !important;
}
.tabs-content.inspiration-stories-page td label {
	margin: 6px 0 0 0;
}
.tabs-content.inspiration-stories-page td p {
	margin: 0;
}
.tabs-content.inspiration-stories-page td i {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	margin: 0px 0 0 5px;
	position: relative;
	top: -2px;
}
.admin-upcoming-app td{
	padding: 8px !important;
}
.appointment-no-show-card{
	height: 45px !important;
    line-height: 48px !important;
	width: 100px  !important;

}
.now-show-box-wrapper {
	max-height: 900px;
	overflow-x: scroll;
	display: flex;
	align-items: center;
	flex-direction: column;
}