/* .bg-img{
    background-image: url(/localImages/Bg.jpg);
} */

.login-registerheader {
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  background-color: #fff;
  box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.05);
}
.login-registerheader .logo-top {
  float: left;
  width: 50%;
  text-align: left;
}
.login-registerheader .right-login-register {
  float: right;
  width: 50%;
  text-align: right;
}
.right-login-register p {
  margin: 0;
}
.right-login-register p button {
  display: inline-block;
  width: 153px;
  height: 48px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #233b58;
  color: #233b58 !important;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 0 15.55px;
  background-color: transparent;
}
.right-login-register p {
  margin: 0;
  color: #485257;
  font-size: 16px;
}
.right-login-register p a {
  display: inline-block;
  width: 153px;
  height: 48px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #d5a67b;
  line-height: 48px;
  color: #d5a67b !important;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 0 15.55px;
}

@media (max-width: 767px) {
  .login-register-saction .container {
    width: 100% !important;
    margin: 0;
    max-width: inherit;
    flex-wrap: wrap;
    display: inherit;
  }
  .login-register-saction .col-md-6:nth-child(1) {
    order: 2;
    flex-wrap: wrap;
    display: none;
  }
  .login-register-saction .col-md-6:nth-child(2) {
    order: 1;
    flex-wrap: wrap;
  }
  .login-register-saction {
    background: inherit;
    height: 100vh;
    background-color: #f6f6f6;
  }
  .login-saction-right {
    display: inline-block;
    width: auto;
    padding: 0;
    text-align: left;
  }
  .form-l---r form {
    width: 100% !important;
  }
  .testim .cont div .img img {
    width: 75%;
  }
  .logo {
    margin: 15px 0 0 0;
    text-align: left;
  }
  .logo img {
    width: auto;
    display: none !important;
  }
  .login-register-saction {
    display: block;
  }
  .login-saction-right h2 {
    margin: 50.41px 0 0 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
  }
  .login-saction-right h2 br {
    display: none;
  }
  .form-l---r {
    margin: 15.5px 0 0 0;
    text-align: left;
  }
  .form-l---r input {
    height: 50px;
    padding: 0 35px;
    font-size: 14px;
  }
  .icon {
    left: 8px;
    top: 41px;
  }
  .icon img {
    width: 20px;
  }
  .icon.email-icon {
    top: 46px;
  }
  .icon-right {
    right: 8px;
    top: 41px;
  }
  .icon-right img {
    width: 20px;
  }
  .form-l---r .from-fild {
    margin: 0 0 15px 0;
  }
  .bution {
    margin: 20.05px 0 0 0;
  }
  .forget-fild {
    margin: 4px 0 0 0;
    text-align: left;
  }
  .bution .button-bx {
    height: 55px;
  }
  .dont-have-acc {
    margin: 25.46px 0 0 0 !important;
  }
  .dont-have-acc p {
    font-size: 14px;
  }
  .dont-have-acc .btn.btn-info.btn-lg {
    font-size: 14px;
  }
  .testim .cont {
    margin: 3rem 0 0;
  }
  .testim .cont div h2 {
    font-size: 18px;
    margin: 15px 0 0 0;
  }
  .testim .cont div p {
    font-size: 13px;
    width: 100%;
    margin: 10px 0 0;
    line-height: 18px !important;
  }
  .testim .dots {
    margin: 15px 0;
  }
  .dont-have-acc .modal-dialog {
    max-width: 90% !important;
    margin: 0 auto !important;
    height: auto !important;
    border-radius: 10px !important;
    border: 2px solid #d5a67b !important;
    background-color: #fff !important;
  }
  .dont-have-acc .modal-body {
    text-align: center;
    padding: 15px;
  }
  .icon-pop img {
    width: 15%;
  }
  .dont-have-acc .modal-body ul {
    margin: 30.28px 0 0;
    padding: 0;
  }
  .dont-have-acc .modal-body ul li {
    display: inline-block;
    width: 43%;
    margin: 0 10px;
    cursor: pointer;
  }
  .dont-have-acc .modal-body ul li a {
    width: 100%;
    border: 1px solid #fff;
    border-radius: 6px;
    height: auto;
    padding: 10px;
  }
  .dont-have-acc .modal-body ul li a img {
    display: inline;
    width: 48px;
    height: 40px;
  }
  .dont-have-acc .modal-body ul li a span {
    margin: 10.08px 0 0 0;
    font-size: 13px;
    line-height: 20px;
  }
  .images img {
    width: 85%;
  }
  .register-medium #testim-content {
    margin: 3rem 0 0;
  }
  .register-medium .modal-dialog {
    width: 90% !important;
    height: auto !important;
    border-radius: 11px;
    margin: 0 auto;
  }
  .register-medium .modal-dialog .icon-pop {
    margin: 20.95px 0 0 0;
  }
  .register-medium .modal-dialog h3 {
    color: #485257;
    font-size: 18px;
    margin: 15.09px 0 0 0;
    line-height: 25px;
  }
  .register-medium .modal-dialog p {
    margin: 10px auto 0;
    font-size: 14px;
    line-height: 20px;
  }
  .register-medium .modal-dialog a {
    margin: 20.1px 0;
    width: 87%;
    height: 50px;
    line-height: 50px;
    font-size: 13px;
  }
  .bution .btn.btn-info.btn-lg {
    height: 55px;
  }
  .dont-have-acc .btn.btn-info.btn-lg {
    font-size: 14px !important;
    font-family: "Oakes-Grotesk-Regular";
    margin: 0;
    padding: 0 9px !important;
  }
  .dont-have-acc .modal-body ul li div {
    display: inline-block;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 10px;
    height: 220px;
    padding: 20px;
  }
  .dont-have-acc .modal-body ul li div img {
    display: inline;
    width: 50px;
    height: 50.83px;
  }
  .dont-have-acc .modal-body ul li div span {
    margin: 12.08px 0 0 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  /* .continue {
        display: none;
    } */
  .register-clint h2 {
    margin: 20.41px 0 0 0;
  }
  .check-box input[type="checkbox"] + label::before {
    width: 16.52px;
    height: 16.52px;
    padding-left: 4px;
    padding-bottom: 0;
    margin-right: 10px;
  }
  .check-box br {
    display: none;
  }
  .register-right .bution {
    margin: 25.6px 0 0;
  }
  .form-l---r select {
    width: 100%;
    height: 50px;
    padding: 0 35px;
  }
  .register-clint .logo {
    margin-top: 0px;
  }
  .register-medium {
    padding: 2rem 0;
  }
}

.footer-login-screen {
  display: block;
  width: 100%;
  border-top: 2px solid;
  padding: 10px;
  padding-left: 140px;
  position: absolute;
  bottom: -20px;
  width: 100%;
}
.footer-login-screen-head {
  height: 100vh;
  /* overflow: hidden; */
}

@media (max-width: 768px) {
  .footer-login-screen {
    padding-left: 5px !important;
    bottom: -40px;
    text-align: center;
  }
}
@media (max-width: 750px) {
  .footer-login-screen {
    padding-left: 5px !important;
    bottom: -290px;
    text-align: center;
  }
  .footer-login-screen-head{
    height: unset !important;
    overflow: unset !important;
  }
}
