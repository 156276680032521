#PayItForwardPopUp .modal-dialog {
    max-width: 60%;
text-align: center;
} 

.backfill-ask{
    gap: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}