.search-container input {
  background-image: url(/images/search.png);
  background-repeat: no-repeat;
  background-position: 5%;
}
.filter-wrapper {
  display: flex;
  justify-content: end;
  gap: 10px;
}
.selectOption {
  margin: auto;
} 
select.pointer {
  width: 295px !important;
}