.practioner-heading {
  text-align: center;
  margin: 3rem 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: black;
}
.starter-practioners-margin {
  margin: 4rem 0;
}
.starter-practioners {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.box-practioners {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  position: relative;
  width: 100%;
  padding: 0 10px 10px;
  background-color: white;
}
.img-practi {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -62px;
}
.img-practi img {
  width: 45%;
  border-radius: 50%;
}
.content-practi {
  margin: 85px 0 0 0;
  display: inline-block;
  width: 100%;
}
.content-practi h3 {
  color: #000;
  font-size: 28px;
  font-weight: 600;
  display: inline-block;
  width: 100%;
  text-align: center;
}
.content-practi p {
  color: #666;
  font-size: 20px;
  margin: 5px 0 0 0;
}
.content-practi em {
  font-style: normal;
  font-size: 24px;
  color: #000;
  font-weight: 600;
  margin: 0 7px;
  border-left: 1px solid #a4a4a4;
  padding: 0 8px 0 11px;
}
.content-practi span {
  display: inline-block;
  margin: 10px 0 10px 0;
}

.box-practioners-internal {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
/******/



.schedule-advance-box-all-container{
  max-width: 1560px;
  margin: 52px auto !important;
  display: block !important;
}

.schedule-advance-box-all {
  display: inline-block;
  width: 100%;
  margin: 52px 0 0 0;
}
.schedule-advance-box-left {
  display: inline-block;
  width: 100%;
  box-shadow: 0px 0px 33px #E0E4EE;
  border-radius: 7px;
  background-color: #fff;
  overflow: hidden;
  height: 750px;
}
.schedule-advance-box-left .accordion{
  margin: 0px 0;
}
.schedule-advance-box-left .accordion .item {
    border: none;
    margin-bottom: 0px;
    background: none;
}
.schedule-advance-box-left .accordion .item .item-header h2 button.btn.btn-link {
  background: #fff;
  color: white;
  border-radius: 0px;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: inherit;
  text-decoration: none;
  padding: 16px 14.86px !important;
  color: #485257;
  border-bottom: 1px solid #D1D1D1;
  border-top: none;
  border-left: none;
  border-right: none;
}
.schedule-advance-box-left .accordion .item .item-header {
    border-bottom: none;
    background: transparent;
    padding: 0px;
}
.app-prof {
	margin: 0 !important;
}
.last-li {
	margin: 0 !important;
}

.schedule-advance-box-left .accordion .item .item-header h2 button {
    color: white;
    font-size: 20px;
    padding: 15px;
    display: block;
    width: 100%;
    text-align: left;
}
.schedule-advance-box-left .accordion .slect-language-list {
  display: inline-block;
  width: 100%;
  padding: 0px 0 !important;
  overflow: scroll;
  height: 190px;
  overflow-x: inherit;
}
.accordion .slect-language-list .form-group {
  margin: 0 0 0 0 !important;
}
.schedule-advance-box-left  button.btn.btn-link.collapsed img {
    transform: rotate(0deg);
}

.schedule-advance-box-left  button.btn.btn-link img {
    transform: rotate(180deg);
    transition: 0.5s;
}
.schedule-advance-box-left .accordion  .btn img {
  width: auto;
  height: auto;
  margin: 0;
  position: relative;
  top: 1px;
  float: right;
}
.slect-language-list .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.slect-language-list .form-group label {
  position: relative;
  cursor: pointer;
}
.slect-language-list .form-group label::before {
	content: '';
	-webkit-appearance: none;
	background-color: transparent;
	border: 1px solid #CFCFCF;
	width: 20px !important;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 10px;
	border-radius: 50% !important;
	height: 20px !important;
	right: 0;
	position: absolute !important;
	top: 12px;
}
.slect-language-list .form-group input:checked + label::before {
  background-color: #D5A67B;
  border-color: #D5A67B;
}
.slect-language-list .form-group input:checked + label::after {
	content: '';
	display: block;
	position: absolute;
	top: 15px !important;
	right: 18px !important;
	width: 3px;
	height: 11px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
	left: inherit !important;
}
.schedule-advance-box-left .accordion .slect-language-list label {
	color: #485257;
	font-size: 14px;
	font-weight: 500;
	width: 100%;
	margin: 0;
	padding: 16px 13px;
}
.slect-language-list .form-group input:checked + label {
	background-color: rgba(213, 166, 123, 0.09);
	color: #D5A67B;
}
.schedule-advance-bx {
  display: inline-block;
  width: 100%;
  border: 1px solid #CACACA;
  border-radius: 5px;
  background-color: #fff;
}
.profile-box-top {
	border-bottom: 1px solid #D9D9D9;
	padding: 20.69px 15px !important;
	display: inline-block;
	width: 100%;
	position: relative;
}
.left-prof-bx {
	float: none !important;
	width: 100% !important;
	border-radius: 50%;
	display: inline-block;
	height: auto !important;
	text-align: center;
}
.left-prof-bx .prof-circal {
	border: 2px solid #D5A67B;
	border-radius: 50%;
	height: 129.19px !important;
	width: 130px !important;
	text-align: center;
	padding: 5px 0 0 0;
	display: inline-block;
}
.left-prof-bx .prof-circal img {
  width: 77%;
}
.view-pro {
	background-color: #D5A67B !important;
	color: #fff !important;
}
.left-name-bx {
	float: inherit !important;
	width: 100% !important;
	padding: 14.87px 0 0 0 !important;
	display: inline-block !important;
	text-align: center !important;
}
.left-name-bx span {
	width: 100%;
	display: inline-block;
	color: #000;
	font-size: 20px;
	font-weight: 600;
}
.rewie-bx {
	margin: 8px 0 0 0;
	display: inline-block;
	width: 46px !important;
	position: absolute;
	right: 25px;
	top: 0;
	text-align: right;
	height: 25px;
	border: 1px solid #D5A67B;
	border-radius: 4px;
	line-height: 25px;
	color: #D5A67B;
	font-size: 13px;
}
.rewie-bx img {
	width: 14px;
	margin: 0px 4px 0 4px !important;
	position: relative;
	top: 1px !important;
}
.rewie-bx em {
	font-style: normal;
	color: #2F2F2F;
	font-size: 12px;
	font-weight: 500;
	border-left: 1px solid #dcdcdc;
	margin: 0 0 0 4px;
	padding: 0 0 0 5px;
}
.language-list-box {
	display: inline-block;
	width: 100%;
	padding: 15.1px 15px;
}
.language-list-box ul {
	display: inline-block;
	width: 100%;
	padding: 0;
	margin: 0;
	height: 145px;
}
.language-list-box ul li {
	display: inline-block;
	width: 100%;
	margin: 0 0 23.69px 0;
	position: relative;
	padding: 0 0 0 33px;
	line-height: 20px;
}
.language-list-box ul li img {
	position: absolute;
	top: -1px;
	margin: 0 8px 0 0;
	left: 0;
}
.language-list-box ul li span {
	color: #000;
	font-size: 13px;
	font-weight: 400;
}
.language-list-box ul li em {
	font-style: normal;
	color: #D5A67B;
	font-size: 13px;
	font-weight: 500;
	margin: 0 0 0 4px;
}
.button-select-profile {
	display: inline-block;
	width: 100%;
	text-align: center;
	margin: 14.48px 0 10px 0 !important;
}
.button-select-profile button {
	width: 43%;
	margin: 0 8px;
	height: 46px;
	border: 1px solid #D5A67B;
	border-radius: 7px;
	background-color: transparent;
	color: #D5A67B;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.01em;
}
.schedule-advance-box-right {
	display: inline-block;
	width: 100%;
	padding: 0 0 0 0;
}
.schedule-advance-box-right .row {
  margin-bottom: 13px;
}

.d-flex-flex-wrap-wrap{
  display: flex;
  flex-wrap: wrap;
}
.schedule-advance-box-all-container {
	width: 1240px;
	max-width: inherit !important;
}
.app-new-profile .profile-name a {
	padding: 0;
	border: none;
	text-align: left;
	width: auto;
	height: auto;
}
.app-new-profile .profile-image {
	height: auto;
}
.filter-tabs{
  background: #D5A67B;
  color: white;
  padding: 10px;
  border-radius: 20px;
  margin: 5px;
  display: inline-block;
}
.filter-tabs-cross{
  color: black;
  margin-left: 10px;
}
.filter-tabs-container{
  margin-top: 15px;
}
.app-prof em {
	display: inline-block !important;
	font-style: normal;
	background-color: rgba(213, 166, 123, 0.09);
	padding: 8px 3px;
	font-size: 10px;
	color: #D5A67B;
	font-weight: 400;
	margin: 12px 5px 0 0;
	float: left;
	width: 48%;
	text-align: center;
  height: 40px;
}
.prof-price-info.app-prof {
	display: inline-block;
	width: 100%;
}
.schedule-advance-box-left h3 {
	margin: 0;
	background-color: #D5A67B;
	padding: 17.21px 19.36px 13px;
	color: #fff;
	font-size: 24px;
	font-weight: 600;
}
.langu-img {
	float: left !important;
	width: auto !important;
	height: auto !important;
	margin: -3px 10px 0 0 !important;
	transition: inherit !important;
	transform: inherit !important;
}
.filter-images-n{
  width: 22px !important;
  height: 22px !important;
}







@media (max-width: 767px) {
  .single-profile-saction-new .profile-info {
    width: 100% !important;
    text-align: center;
    display: inline-block !important;
  }
  .app-new-profile .profile-image {
    width: 100%;
    height: auto;
    bottom: 0;
    overflow: hidden;
    text-align: center;
    float: inherit !important;
  }
  .app-new-profile .profile-image img {
    width: 70px;
    height: 70px;
    border: none !important;
  }
  .app-new-profile .profile-name a {
    width: auto;
    border: none;
    padding: 0;
    margin: 0px 0 0 0;
  }
  .single-profile-saction-new .profile-book-now {
    float: right;
    width: 100% !important;
    padding: 20px 0 20px 0 !important;
  }
  .profile-book-now button {
    background-color: #D5A67B;
    width: 90%;
    height: 44px;
    font-size: 14px;
  }


  .app-new-profile-details ul li a {
    width: auto !important;
    height: auto;
    line-height: inherit;
    padding: 7px 19px;
    margin: 6px 0 0 0 !important;
  }
  .app-new-profile-details ul li em {
    width: auto;
    float: left;
    margin: 17px 0 0 0;
  }
  .app-new-profile-details .icon-detls {
    margin: 17px 10px 0 0 !important;
  }
  .app-new-profile-details li {
    margin: 0 !important;
  }
  .language-list-box ul {
    height: auto !important;
  }


}

@media  screen and (max-width: 1366px) {
	.profile-box-top {
		padding: 20.37px 10.37px;
	} 
	.left-prof-bx {
		width: 60px;
		height: 60px;
	} 
	.left-name-bx span {
		width: 100%;
		font-size: 13px;
	} 
  .left-prof-bx .prof-circal {
    height: 60.3px;
    width: 60.3px;
  }
	.language-list-box ul li {
		position: relative;
		padding: 0 0 0 36px;
		margin: 0 0 17px 0px;
		line-height: 20px;
	}
	.language-list-box ul li img {
		position: absolute;
		top: -1px;
		left: 0;
	}
	.language-list-box ul li span {
		color: #000;
		font-size: 12px;
	}
	.language-list-box ul li em {
		font-size: 12px;
	}
	.button-select-profile button {
    width: 41%;
    margin: 0 5px;
    height: 45px;
  }
  .accordion .slect-language-list .form-group {
    position: relative;
  }
  .slect-language-list .form-group label::before {
    position: absolute;
    left: 0;
    top: -2px;
  }
  .schedule-advance-box-left .accordion .slect-language-list label {
    height: 22px !important;
    font-size: 12px;
    padding: 0 0 0 30px;
  }
  .slect-language-list .form-group input:checked + label::after {
    top: 0px;
  }
  .schedule-advance-box-all-container {
    width: 1280px;
    max-width: inherit !important;
    margin: 10px auto !important;
  }
  .language-list-box ul {
    height: 125px;
  }

		
}