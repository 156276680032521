/*single-practioners-box*/

.back-practioners {
  display: inline-block;
  width: 100%;
  text-align: left;
  color: #000;
  font-size: 27px;
  text-transform: capitalize;
}
.practioners-singl-profile {
  display: inline-block;
  width: 100%;
  text-align: left;
  margin: 45px 0 0 0;
}
.practioners-left-profile {
  float: left;
  width: 25%;
  text-align: center;
}
.practioners-left-profile img {
  width: auto;
  border-radius: 50%;
}
.practioners-left-profile h3 {
  display: inline-block;
  margin: 13px 0 0 0;
  width: 100%;
  color: #485257;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: "Oakes-Grotesk-Medium";
}
.practioners-left-profile em {
  font-style: normal;
  font-size: 24px;
  color: #000;
  font-weight: 600;
  margin: 0 7px;
  border-left: 1px solid #a4a4a4;
  padding: 0 8px 0 11px;
}
.practioners-left-profile span {
  display: inline-block;
  margin: 10px 0 10px 0;
  width: 100%;
}
.practioners-left-profile span img {
  width: auto;
  margin: 0 3px;
}
.practioners-left-profile a {
  display: inline-block;
  width: 100%;
  background-color: #37474f;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  padding: 13px 0;
  margin: 10px 0 0 0;
  font-weight: 300;
  font-family: "Oakes-Grotesk-Regular";
}
.back-practioners img {
  margin: 0 10px 0 0;
}
.practioners-right-profile {
  float: right;
  width: 75%;
  padding: 3rem 0 0 7rem;
}
.practioners-right-profile li {
  display: inline-block;
  width: 100%;
  margin: 0 0 18px 0;
  font-family: "Oakes-Grotesk-Regular";
  color: #333;
  font-size: 18px;
}
.practioners-right-profile li span {
  margin: 0 0 0 19px;
  font-size: 20px;
  font-weight: 900;
  color: #37474f;
}
.practioners-right-profile li:nth-child(2) span:nth-child(2) {
  border-left: 1px solid #666;
  padding: 0 3px 0 18px;
}
.biography-saction {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #666;
  margin: 50px 0 0;
  padding: 30px 0 0;
}
.biography-saction h2 {
  display: inline-block;
  margin: 13px 0 0 0;
  width: 100%;
  color: #485257;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: "Oakes-Grotesk-Medium";
}
.biography-saction p {
  display: inline-block;
  margin: 25px 0 0 0;
  font-family: "Oakes-Grotesk-Regular";
  color: #485257;
  line-height: 29px;
  font-size: 18px;
}
.back-practioners .back-practioners-back-btn {
  color: #37474f;
  font-family: "Oakes-Grotesk-Regular";
  font-size: 23px;
  font-weight: 500;
}

/*****/

.single-profile-saction-new .profile-info {
  width: 60%;
  float: left;
}
.single-profile-saction-new .profile-book-now {
  float: right;
  width: 27%;
  text-align: center;
  padding: 45px 0 0 0;
}
.profile-book-now button,
.profile-book-now .book-now-practice {
  background-color: #d5a67b;
  width: 273px;
  /* height: 46px; */
  border: none;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #e0e4ee;
  padding: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.profile-book-now p {
  margin: 12.25px 0 0 0;
  color: #485257;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  width: 100%;
}
.single-profile-saction-new a,
.bac-href {
  border: 1px solid #cacaca;
  border-radius: 5px;
  background-color: #fff;
  width: 199px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  color: #485257;
  font-size: 14px;
  font-weight: 400;
}
.single-profile-saction-new a img,
.bac-img {
  margin: 0 7px 0 0;
  position: relative;
  top: 3px;
}
.feedback-table-practioners table {
  border: 1px solid black;
}

.feedback-table-practioners table th {
  padding: 20px;
  background-color: #d5a67b;
  color: white;
}
.feedback-table-practioners table td {
  padding: 20px;
  border: 1px solid black;
}
.feedback-image {
  width: 45px;
}
.feedback-image img {
  border: 1px solid black !important;
  border-radius: 50% !important;
}
.feedback-star-image {
  /* width: 25px;
  display: flex; */
}
.feedback-star-image img {
  border: unset !important;
  padding: 3px;
}
.feedback-date {
  margin-top: unset !important;
  font-size: 14px !important;
}

.feedback-container {
  max-height: 800px;
  overflow: scroll;
  border: 2px solid;
  padding: 19px;
}
.min-width-200px{
  min-width :200px;
}
.feedback-heading{
  font-weight: 900;
  display: block;
  font-size: 18px;
  margin-bottom: 7px !important;
}
@media (max-width: 767px) {
  .schedule-advance-box-all-container {
    max-width: 100%;
    margin: 15px auto !important;
    padding: 0 15px;
    width: 100%;
  }
  .single-profile-saction-new a,
  .bac-href {
    width: 160px;
    height: 35px;
    line-height: 35px;
    font-size: 12px;
  }
  .single-profile-saction-new a img,
  .bac-img {
    width: 16px;
  }
  .schedule-advance-box-all-container .row {
    display: inline-block;
  }
  .schedule-advance-box-left {
    margin: 15px 0 0 0;
    height: auto;
  }
  .schedule-advance-box-left
    .accordion
    .item
    .item-header
    h2
    button.btn.btn-link {
    padding: 15px 15px !important;
  }
  .schedule-advance-box-left .accordion .slect-language-list {
    padding: 20px 15px;
    height: 180px;
  }
  .schedule-advance-box-left .accordion .btn img {
    width: auto !important;
  }
  .schedule-advance-box-right {
    width: 100%;
    padding: 0;
    margin: 15px 0 0 0;
  }
  .schedule-advance-box-right .col-md-4.mb-3 {
    padding: 0;
  }
  .profile-box-top {
    border-bottom: 1px solid #d9d9d9;
    padding: 15px;
  }
  .left-prof-bx .prof-circal {
    height: 60px;
    width: 60px;
    padding: 4px 0 0 0;
  }
  .left-prof-bx .prof-circal img {
    width: 40px;
  }
  .left-name-bx {
    width: 82%;
    padding: 0 0 0 15px;
  }
  .left-name-bx span {
    font-size: 18px;
  }
  .language-list-box {
    padding: 15px;
  }
  .language-list-box ul li {
    margin: 0 0 15px 0;
    position: relative;
    padding: 0 0 0 30px;
    line-height: 24px;
  }
  .button-select-profile button {
    width: 45%;
    margin: 0 8px;
    height: 45px;
    color: #d5a67b;
    font-size: 14px;
    letter-spacing: 0.01em;
  }
  .language-list-box ul li img {
    position: absolute;
    top: -2px;
    margin: 0 0 0 0;
    left: 0;
  }
  .language-list-box ul li span {
    color: #000;
    font-size: 13px;
  }
  .language-list-box ul li em {
    font-size: 13px;
    margin: 0 0 0 5px;
  }
  .accordion .slect-language-list .form-group {
    margin: 0 0 18.31px 0;
  }
}

.profile-bio .paypal form{

  width: 360px;

}
.profile-bio .paypal form button{
  background-color: #d5a67b !important;
  color: white !important;
  border: unset !important;
  width: 184px;
  margin-top:10px;
  border-radius: 4px;
}
.paypal-container {
  width: 200px;
  margin-top: 20px;
}
.paypal-button{
  background-color: #d5a67b !important;
  color: white !important;
  border: unset !important;
  width: 184px;
  margin-top:10px;
  border-radius: 4px;
}