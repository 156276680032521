.intergrate-box-all {
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 100vh;
  vertical-align: middle;
  padding: 15rem 0 0;
}
.intergrate-box-all h3 {
  display: inline-block;
  width: 100%;
  font-size: 38px;
  font-weight: 900;
  color: #37474f;
  margin-bottom: 76px;
}
.box-three-box {
  display: inline-block;
  width: 436px;
  border: 0.5px solid #485257;
  border-radius: 10px;
  margin: 0 20px;
  box-shadow: 0px 10px 40px #00000029;
  background-color: #fff;
  padding: 30px 0;
}
.box-three-box p {
  margin: 20.17px 0 0 0;
  display: inline-block;
  width: 100%;
  color: #485257;
  font-size: 20px;
  font-weight: 200;
}
.bution-intergrate {
  display: inline-block;
  width: 100%;
  margin: 112px 0 0 0;
}
.bution-intergrate ul {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
}
.bution-intergrate ul li {
  display: inline-block;
  width: auto;
  margin: 0 20px;
}
.bution-intergrate ul li button {
  display: inline-block;
  width: 212px;
  height: 54px;
  font-size: 18px;
  font-weight: 200;
  color: #485257;
  border-radius: 6px;
  text-decoration: none;
}
.bution-intergrate ul li .logoutt {
  border: 0.5px solid #485257;
  background-color: #fff;
}
.bution-intergrate ul li .proceed {
  background-color: #485257;
  color: #fff;
  border-color: #485257;
}
.wight-image {
  display: none;
}
.box-three-box:hover {
  background-color: #dbb38f;
  border-color: #dbb38f;
}
.box-three-box:hover .black-image {
  display: none;
}
.box-three-box:hover .wight-image {
  display: inline;
}
.box-three-box:hover p {
  color: #fff;
}
.bution-intergrate ul li button:hover {
  background-color: #dbb38f;
  color: #fff;
  border-color: #dbb38f;
}
.icon-img {
  display: inherit;
  width: 100px;
  height: 77px;
  overflow: hidden;
}
.box-three-box-active {
  background-color: #dbb38f;
  border-color: #dbb38f;
}

.box-three-box-active  p{
	color: #fff;
  }
  .box-three-box-active .black-image {
	display: none;
  }
  .box-three-box-active .wight-image {
	display: inline;
  }





@media (max-width: 767px) {

  .integaction-page-saction {
    margin: 20px 0 0 0;
  }
  .integaction-page-saction h2 {
    color: #000;
    font-size: 20px;
  }
  .integaction-page-saction .row {
    margin-top: 24.03px;
    display: inline-block;
  }
  .box-integaction {
    margin-bottom: 20px;
  }
  .link-your {
    padding: 15px;
  }
  .up-down-errow {
    padding: 5px 0;
    margin: 0px 0 0 0;
  }
  .cont-link span {
    font-size: 14px;
  }
  .icon-inte {
    padding: 20.28px 15.88px;
  }
  .video-saction-inte iframe {
    width: 100%;
    height: 300px;
  }
  .box-popup-inte {
    display: inline-block;
    width: 92.8%;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    padding: 19px 20.91px;
    position: absolute;
    left: 0;
    right: 0;
    top: 18.5rem;
    margin: 0 auto;
    z-index: 99;
    border-top: none;
  }
  .link-your.active::after {
    display: none;
  }
  .button-close-proced button {
    width: 140px;
    height: 50px;
    margin: 0 0 0 0;
  }
  .button-close-proced .proced {
    float: right;
  }
  .box-popup-inte ul {
    padding: 0;
  }
  .box-popup-inte ul li {
    margin: 0 0 15px 0;
    font-size: 13px;
    padding: 0 0 0 13px;
    line-height: 20px;
  }
  .box-popup-inte p {
    margin: 20px 0;
    font-size: 13px;
    line-height: 20px;
    position: relative;
    padding: 0 0 0 26px;
  }
  .box-popup-inte img {
    position: absolute;
    top: 2px;
    left: -2px;
  }





  }

  .zoom-message-box{
    background-color: #b8860b;
    color: black;
    font-size: 30px;
    text-align: center;
    padding: 40px 20px;
    line-height: 32px;
    position: absolute;
  }