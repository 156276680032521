.add-new-sessions-groups {
	display: inline-block;
	width: 100%;
	margin: 59px 0 0 0;
}
.hading-sessions {
	display: inline-block;
	width: 100%;
}
.hading-sessions span {
	color: #485257;
	font-size: 20px;
	font-weight: 400;
	font-family: 'Montserrat', sans-serif !important;
	letter-spacing: 0.4px;
	width: 100%;
	display: inline-block;
}
.add-new-sessions-from {
	display: inline-block;
	width: 70%;
	margin: 48px 0 0 0;
}
.add-new-sessions-from .sessions-from-left {
	float: left;
	width: 48%;
}
.add-new-sessions-from .sessions-from-right {
	float: right;
	width: 48%;
	text-align: left;
}
.add-new-sessions-from label {
	width: 100%;
	display: inline-block;
	margin: 0;
	color: #485257;
	font-size: 18px;
	letter-spacing: 0.36px;
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 600;
}
.add-new-sessions-from input {
	border: 0.5px solid #A3ADB2;
	width: 100%;
	border-radius: 5px;
	height: 50px;
	padding: 3px 10px;
	color: #000;
	background-color: transparent;
	margin: 10px 0 40px 0;
}
.add-new-sessions-from .select-dropdown,
.select-dropdown * {
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}
.add-new-sessions-from .select-dropdown select {
	font-weight: normal;
	max-width: 100%;
	padding: 8px 24px 8px 10px;
	background-color: transparent;
	appearance: none;
	border: 0.5px solid #A3ADB2;
	width: 100%;
	border-radius: 5px;
	color: #485257;
	font-size: 18px;
	height: 50px;
	margin: 10px 0 40px 0;
	font-family: 'Montserrat', sans-serif !important;
	cursor: pointer;
}
.add-new-sessions-from .select-dropdown select:active, .select-dropdown select:focus {
	outline: none;
	box-shadow: none;
}
.add-new-sessions-from .select-dropdown::after {
	content: "";
	position: absolute;
	top: 32%;
	right: 12px;
	width: 13px;
	height: 13px;
	background: url('/images/Iconeroww.png');
	background-repeat: no-repeat;
	background-size: contain;
}
.sessions-from-left .left-select-b {
	float: left;
	width: 48%;
}
.sessions-from-left .right-select-b {
	float: right;
	width: 48%;
}
.booking-timebx .time-booking-b {
	float: left;
	width: auto;
	color: #485257;
	font-size: 18px;
	margin: 24px 0 0 0;
}
.booking-timebx .select-dropdown {
	float: left;
	width: 77%;
	padding: 0 0 0 15px;
}
.booking-timebx label {
	margin-bottom: 16px;
}

.sessions-from-right .left-select-b {
	float: left;
	width: 48%;
}
.sessions-from-right .right-select-b {
	float: right;
	width: 48%;
}
.session-bookinf-all {
	display: inline-block;
	width: 88%;
}
.add-booking-questions-box input {
	margin-bottom: 27px;
}
.add-booking-questions-box select {
	margin-bottom: 27px !important;
}
.add-booking-questions-box {
	display: inline-block;
	width: 90%;
}
.buttion-add-box {
	float: right;
	width: 10%;
	text-align: right;
	margin: 38px 0 0 0;
}
.buttion-add-box button {
	border: 1px solid #485257;
	border-radius: 50%;
	height: 50px;
	width: 50px;
	background-color: transparent;
	color: #485257;
	font-size: 35px;
	font-weight: 300;
	font-family: 'Montserrat', sans-serif !important;
	padding: 0;
}
.next-box-ans-quction {
	/* display: none; */
}
.filde-box-price p 
[type="radio"]:checked,
.filde-box-price p [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.filde-box-price p [type="radio"]:checked + label, .filde-box-price p  [type="radio"]:not(:checked) + label {
	position: relative;
	padding-left: 50px;
	cursor: pointer;
	display: inline-block;
	color: #666;
	font-weight: 300;
	font-size: 18px;
	position: relative;
	line-height: 33px;
}
.filde-box-price p [type="radio"]:checked + label::before, .filde-box-price p  [type="radio"]:not(:checked) + label::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 31px;
	height: 31px;
	border: 1px solid #707070;
	border-radius: 100%;
}
.filde-box-price p [type="radio"]:checked + label::after,.filde-box-price p  [type="radio"]:not(:checked) + label::after {
	content: '';
	width: 21px;
	height: 21px;
	background: #485257;
	position: absolute;
	top: 6px;
	left: 6px;
	border-radius: 100%;
	transition: all 0.2s ease;
}
.filde-box-price p [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.filde-box-price p [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.filde-box-price p {
	width: 18%;
	margin: 0;
	display: inline-block;
}
.filde-box-price form {
	margin: 20px 0 0 0;
}
.price-fild-input {
	float: left;
	width: 60%;
	margin: 22px 0 0 0;
	border: 0.5px solid #A3ADB2;
	border-radius: 5px;
	height: 50px;
	padding: 8px 5px;
}
.price-fild-input .box-doler {
	float: left;
	width: 50px;
	text-align: center;
	border-right: 1px solid;
}
.price-fild-input input {
	margin: 0;
	border: none;
	padding: 0 14px;
	width: 92%;
	float: left;
	height: 33px;
}
.price-fild-input .box-doler {
	float: left;
	width: 50px;
	text-align: center;
	border-right: 1px solid #485257;
	color: #485257;
	font-size: 18px;
	padding: 4px 0;
}

.cancel-sumbit-button-box {
	display: inline-block;
	width: 100%;
	text-align: center;
	margin: 48px 0 0 0;
}
.cancel-sumbit-button-box button {
	width: 212px;
	height: 54px;
	border-radius: 5px;
	margin: 0 9px;
	background-color: transparent;
	border: 0.5px solid #485257;
	color: #485257;
	font-size: 18px;
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 500;
}
.cancel-sumbit-button-box .submit-btn {
	background-color: #485257;
	color: #fff;
}
.price-variable-dash{
	display: block;
    width: 80px;
    text-align: center;
    margin: 22px 0 0 0;
}