.video-popover-container {
  justify-content: center;
  align-items: center;
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}
.video-popover-wrapper {
  background-color: white;
  padding: 10px;
  position: relative;
  min-width: 660px;
  min-height: 439px;
  box-sizing: border-box;
}
.video-popover-wrapper .frame {
  min-height: 365px;
}
.video-popover-wrapper .frame iframe {
  width: 100%;
  min-height: 365px;
}
.popoverClose {
  color: #ffffff;
  background-color: #d5a67b;
  border-radius: 50px 50px 50px 50px;
  padding: 12px 35px 12px 35px;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 15px;
  margin-top: 20px;
  box-sizing: border-box;
}
.popover-video {
  box-sizing: border-box;
  width: 660;
  height: 430;
  min-height: 430px;
}
.text-right {
  text-align: right;
}
.popoverClose:hover {
  background-color: #d5a67b;
}
.popoverClose-icon {
  font-size: 20px;
  right: 20px;
  top: 10px;
  position: absolute;
  color: gray;
  position: absolute;
  z-index: 10000;
  cursor: pointer;
}
