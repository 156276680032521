

.backfill-toggle-wrapper {
  font-family: "Poppins", sans-serif;
}



.backfill-toggle-wrapper.active .primary--button {
  border: 2px solid #fff;
  background-color: #fff;
}

.primary--button {
  width: 200px;
  min-height: 50px;
  border: none;
  background-color: transparent;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  position: relative;
  cursor: pointer;
}
.primary--button:hover {
    background-color: transparent !important;
    background-color: transparent !important;
}
.primary--button::after {
  content: "";
  min-height: 40px;
  width: 45px;
  display: block;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: transform 1s;
}

.primary--button:before {
  content: "Inactive";
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 90px;
  right: unset;
  transform: translateY(-50%);
  color: white;
  transition: content 1s;
}

.turn-on .primary--button:before {
  content: "Active";
  right: 100px;
  left: unset;
  transition: content 1s;

}

.turn-on .primary--button::after {
  content: "";
  min-height: 40px;
  width: 45px;
  display: block;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translate(140px, -50%);
  transition: transform 1s;
}
.turn-on .primary--button{
    background-color: green !important;
}
.turn-off .primary--button{
    background-color: red !important;
}
.margin-bottom-unset{
    margin-bottom: unset !important;
}