.add-neapp .modal::before {
	content: '';
	height: 100%;
	vertical-align: middle;
}
.add-neapp .modal {
	text-align: center;
	padding: 0 !important;
}
.tabs-content.inspiration-stories-page .add-neapp .btn.btn-info.btn-lg {
	background-color: #4E64DD;
	border: none !important;
	width: 100%;
}
.add-neapp .btn.btn-info.btn-lg span {
	background-color: #fff;
	color: #4E64DD;
	width: 26px;
	height: 26px;
	display: inline-block;
	border-radius: 50%;
	font-weight: 900;
	line-height: 26px;
	margin: 0 10px 0 0;
}
.tabs-content.inspiration-stories-page .right-filtr {
	width: 56% !important;
}
.tabs-content.inspiration-stories-page .right-filtr .add-neapp {
	width: 270px;
}
.add-neapp #myModal .upload-btn-wrapper {
	position: inherit !important;
	overflow: hidden;
	display: inline-block;
	top: inherit;
	right: inherit !important;
	width: 100%;
	text-align: left;
	margin: 53px 0 0 0;
}
.add-neapp #myModal .btn {
	color: #4E64DD;
	background-color: white;
	padding: 0 !important;
	font-size: 16px !important;
	font-weight: 300 !important;
	width: 40%;
	height: auto;
	border: none;
	text-align: left;
}
.add-neapp #myModal .btn .upload-icon {
	float: left;
	margin: 0 15px 0 0;
}
.add-neapp #myModal .btn span {
	margin: 13px 0 0 0;
	display: inline-block;
}
.add-neapp #myModal .upload-btn-wrapper input[type=file] {
  opacity: 0;
}
.add-neapp .modal-dialog {
	background-color: #fff;
	border-radius: 21px;
	box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.06);
	border: none;
	max-width: 530px;
	height: 778px;
	padding: 71px 57px 0;
	margin: 4rem auto;
}
.add-neapp .close {
	height: 43px;
	width: 43px;
	background-color: #E5E5E5;
	border-radius: 50%;
	opacity: inherit;
}
.inspiration-stories-page .add-neapp .modal-content {
	border: none;
	background-color: transparent;
	padding: 0;
}
.inspiration-stories-page .add-neapp .modal-body {
	background-color: transparent;
	padding: 0;
	border: none;
}
.inspiration-stories-page .add-neapp h3 {
	margin: 0;
	color: #2F2F2F;
	font-size: 32px;
	font-weight: 600;
}
.test-uplaod-box {
	display: inline-block;
	width: 100%;
	margin: 46px 0 0 0;
	text-align: left;
}
.test-uplaod-box .from-filinput {
	float: left;
	width: 94%;
	margin-bottom: 24px;
}
.test-uplaod-box .from-filinput label {
	font-size: 16px;
	font-weight: 600;
	color: #2F2F2F;
	width: 100%;
	display: inline-block;
}
.test-uplaod-box .from-filinput input {
	display: inline-block;
	width: 100%;
	padding: 0;
	height: 60px;
	border: 1px solid #CACACA;
	border-radius: 5px;
	padding: 0 15px;
	font-size: 14px;
}
.test-uplaod-box .from-filinput #msg {
	border: 1px solid #CACACA;
	border-radius: 5px;
	height: 211px;
	width: 100%;
	padding: 10px;
	font-size: 15px;
}
.test-uplaod-box .butin-sve-dardt {
	display: inline-block;
    display:flex;
	width: 100%;
	margin: 30px 0 0 0;
}
.butin-sve-dardt button {
	width: 48%;
	float: left;
	margin: 0 10px 0 0;
	height: 60px;
	border-radius: 5px;
	border: none;
	font-size: 16px;
	font-weight: 500;
}
.butin-sve-dardt #publish {
	background-color: #D5A67B;
	color: #fff;
	box-shadow: 0px 5px 10px #E0E4EE;
}
.butin-sve-dardt #sav-drft {
	background-color: #4E64DD;
	color: #fff;
	box-shadow: 0px 5px 10px #E0E4EE;
}
.test-uplaod-box input:focus-visible {
	outline: none;
}
#msg:focus-visible {
	outline: none;
}