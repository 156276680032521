/* .help-container {
  position: fixed;
  z-index: 9999;
  right: 80px;
  bottom: 110px;
  cursor: pointer;
} */

.help-container {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  cursor: pointer;
  width: 100%;
  height: 52px;
  color: white;
  background-color: #d5a67b;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  left: 0;
  display: none;
}

.help-container a {
  color: white;
  text-decoration: none !important;
  font-size: 24px;
}
.help-container a:hover ,.help-container a:active{
  color: white;
  text-decoration: none !important;
}
.help-container .span-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.span-tag img {
  width: 30px;
  height: 30px;
}
.span-tag span {
  color: white;
  font-size: 24px;
  margin-top: 6px;
}

@media (max-width: 500px) {
  .help-container {
    display: flex;
  }
}
