.form-width-guest-client{
    min-width: 400px !important;
    margin: 0 auto;
    max-width: 600px;
}
.confirm-btn-host-booking{

}
.confirm-btn-host-booking a{
    width: unset !important;
    height: unset !important;
}

.advance-guest-book-span ,.advance-guest-book-p{
    display: inline-block;
    font-size: 18px;
    font-family: Oakes-Grotesk-Light;
}
.guest-booking-error-border{
    border: 1px solid #d5a67b;
    padding: 10px;
}

.payment-option {
    display: inline-block;
    margin-right: 10px;
  }
  
  .payment-subtext {
    font-size: 0.8em;
    color: #666;
    text-align: center;
    margin-top: 5px;
    width: 113px;
  }

