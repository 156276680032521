.profilecard-wrapper {
  border-radius: 5px;
  border: 1px solid #cacaca;
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 300px;
}

.profilecard-wrapper .profile-photo {
  width: 60px;
  height: 60px;
}

.profilecard-wrapper .profile-photo div {
  border-radius: 50%;
  border: 2px solid #d5a67b;
  width: 100%;
  height: 100%;
}
.profilecard-wrapper .profile-photo img {
  border-radius: 50%;
  padding: 3px;
}

.profilecard-wrapper .view-profile-btn {
  font-size: 11px;
  width: 90px;
  height: 30px;
}

.profilecard-selected {
  border: 2px solid #0080006b;
  box-shadow: 0px 0px 12px 5px #0080002e;
}

.profilecard-wrapper .profile-details-medium {
  width: 110px;
  word-break: break-all;
}
