.feedback-item {
    display: block;
    margin-bottom: 10px !important; /* Space between feedback items */
  }
  .custom-modal-content {
    width: 100vh;
  }
  .align-data{
    line-height: 0.2 !important;
  }

