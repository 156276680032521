/* @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {

} */
@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .appointment-area-white {
    padding: 5px 0 100px 0 !important;
  }
  .appointment-text-tab {
    margin-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .feedback-border-feed {
    flex-wrap: wrap !important;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 800px) {
  /* .login-register-saction .col-md-6:nth-child(1) {
    order: 2;
    flex-wrap: wrap;
    display: none;
  
}
.login-register-saction .col-md-6:nth-child(2) {
    order: 1;
    flex-wrap: wrap;
    flex: 0 0 100% !important;
    max-width: 100% !important;

}
.login-register-saction .col-md-6:nth-child(2) .login-saction-right h2 {
 width: unset !important;

}
.login-register-saction {
  background: inherit;
  height: 100vh;
  background-color: #f6f6f6;
}
.login-register-saction .container {
  width: 100% !important;
  margin: 0;
  max-width: inherit;
  flex-wrap: wrap;
  display: inherit;
  background: unset !important;
}
.login-saction-right {
  display: inline-block;
  width: auto;
  padding: 0;
  text-align: left;
}
.logo {
  margin: 15px 0 0 0;
  text-align: left;
} */
}
@media only screen and (min-device-width: 200px) and (max-device-width: 568px) {
  .navbar-light .navbar-toggler {
    background-color: white;
    display: block;
  }
  #navbarSupportedContent {
    background-color: white;
    border-radius: 5px;
  }
  #navbarSupportedContent ul li {
    display: block;
    padding: 15px 50px;
    margin: unset;
    color: #d5a67b !important;
  }
  #navbarSupportedContent ul li a {
    color: inherit !important;
  }
  #navbarSupportedContent ul li:hover {
    background-color: #d5a67b;
    color: #fff !important;
  }
  #navbarSupportedContent ul li:last-child a {
    color: #fff !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1118px) {
  #navbarSupportedContent ul li:last-child {
    margin-top: 15px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1100px) /* and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) */ {
  .login-saction-right{
    padding: 0 0 0 40px !important;
  }
  .login-saction-right h2{
   width: 100%;
  }
  .appointment-area-white {
    padding: 15px 0 100px 0 !important;
  }

  .appointment-text-tab {
    margin-top: 25px !important;
  }

  .schedule-advance-box-all {
    width: 100% !important;
  }
  .profile-book-now button,
  .profile-book-now .book-now-practice {
    width: 180px;
  }
}
/* .appointment-area-white{
    padding: 15px 0 100px 0;
}


.appointment-text-tab{
    margin-top: 25px;
}

.schedule-advance-box-all{
   width: 100; 
} */
.z-index-1222222{
  z-index:1222222;
}
.CreateNewAppointmentVal input  {
  width: 200px !important;
  height: 25px !important;
}