.support-cards{
 width: 600px;
    padding: 20px;
    box-shadow: -1px 1px 14px 11px #00000024;
    margin: 38px;
}

.support-cards .full-fild{
    float: right;
}

div.system-update-btn {
   
    margin-left: 19px !important ;
    
}
.checkbox-style {
    height: unset !important;
    width: unset !important;
}