.backfill-wrapper {
  /* background: green; */
  width: 100%;
  min-height: 800px;
  padding: 20px;
}

.backfill-container {
  display: flex;
  background: white;
  width: 100%;
  height: 100%;
  /* flex-wrap: wrap; */
flex-direction: column;
}
.backfill-left-side {
}
.backfill-right-side {
}

.width-260-px {
  width: 260px;
}

.margin-60px {
  margin: 60px;
}
.margin-left-60px {
  margin-left: 60px;
}
.i-button-backfill {
  content: "";
  background: url(/images/qu.png);
  position: absolute;
  width: 16px;
  height: 16px;
  background-size: cover;
  /* right: 2px; */
  top: 2px;
  cursor: pointer;
}

.tooltip-1-backfill::after,
.tooltip-2-backfill::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000eb transparent transparent transparent;
}

.tooltip-1-backfill,
.tooltip-2-backfill {
  display: none;
  position: absolute;
  z-index: 2;
  background: #000000eb;
  color: white;
  width: 300px;
  padding: 10px;
  border-radius: 12px;
  top: -40px;
}

.tooltip-1-backfill {
  left: -80px;
}

.tooltip-2-backfill {
  left: 10px;
}

.tooltip-1-focus-backfill:hover ~ .tooltip-1-backfill {
  display: block;
}

.tooltip-2-focus-backfill:hover ~ .tooltip-2-backfill {
  display: block;
}

.backfill-container label {
  margin-bottom: 0.5rem !important;
}
/*toggle button active inactive */


/* /// */
@media only screen and (max-width: 768px) {
  .margin-60px {
    margin: 30px 60px;
  }
}
