.ondemandpractice-wrapper {
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin-top: 24px;
  margin-right: -16px;
  margin-left: -16px;
  padding-bottom: 24px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(18, 18, 18, 0.18) 0px 3px 12px 0px;
  padding: 20px;
  width: 60%;
  margin-bottom: 40px;
}

.ondemandpractice-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  margin: 0px;
}

.godemantevent-box {
  padding-top: 16px;
  padding-bottom: 16px;
}
.godemantevent-box-list {
  margin: 0px;
  padding: 0px 16px;
  list-style: none;
}
.godemantevent-box-list li {
  border-bottom: 1px solid lightgray;
}
.godemantevent-internal-box {
  position: relative;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 4px;
}

.godemantevent-date-box {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  -webkit-box-pack: justify;
  justify-content: space-between;
  /* width: 4.2em; */
  margin-right: 8px;
  color: rgb(18, 18, 18);
  /* text-align: center; */
}

.godemantevent-date-box .month-name {
  line-height: 1.2;
  text-transform: uppercase;
  font-size: 16px;
}

.godemantevent-date-box .month-date {
  font-size: 23px;
  line-height: 1;
}

.godemantevent-date-box {
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin-right: 8px;
  word-break: break-word;
  overflow-wrap: break-word;
  line-height: 25px;
  /* text-align: left; */
}

.ondemandpractice-date-box-day {
  color: rgb(100, 100, 100);
  /* width: 4.2em; */
}

.dieHWG::before {
  top: 50%;
  display: inline-block;
  width: 0.25em;
  height: 0.25em;
  margin: 0px 0.4em;
  border-radius: 50%;
  background-color: currentcolor;
  transform: translateY(-50%);
  content: "";
}

.ivIeWB {
  font-size: var(--rainier-font-size, 16px);
  line-height: var(--rainier-line-height, 22px);
  letter-spacing: 0.02em;
  font-weight: 600;
  display: block;
  color: rgb(18, 18, 18);
}

.sc-fyofxi-5 + .sc-fyofxi-5::before {
  top: 50%;
  display: inline-block;
  width: 0.25em;
  height: 0.25em;
  margin: 0px 0.4em;
  border-radius: 50%;
  background-color: currentcolor;
  transform: translateY(-50%);
  content: "";
}

.jWLmQR {
  display: block;
  color: rgb(100, 100, 100);
}

.godemantevent-date-box {
  display: block;
  align-self: center;
  margin-left: 16px;
}

.godemantevent-date-box .jdbotF {
  margin: initial;
  border: 1px solid transparent;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  padding: 8px 24px;
  color: rgb(255, 255, 255);
  background-color: #d5a67b;
  display: inline-block;
  width: auto;
  min-height: 44px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  transition: background-color 0.2s;
  text-decoration: none;
}
.kCQEIv {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.lmaHQg::after {
  /* position: absolute;
    inset: -16px;
    content: ""; */
}
.jdbotF {
  margin: initial;
  border: 1px solid transparent;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  padding: 8px 24px;
  height: 50px;
  color: rgb(255, 255, 255);
  background-color: #d5a67b;
  display: inline-block;
  width: 450px;
  min-height: 44px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
  text-align: center;
  transition: background-color 0.2s;
  text-decoration: none;
}
.jdbotF-blacnk {
  margin: initial;
  border: 2px solid #d5a67b;
  color: #d5a67b;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  padding: 8px 24px;
  /* background-color: #d5a67b; */
  display: inline-block;
  width: auto;
  min-height: 44px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  transition: background-color 0.2s;
  text-decoration: none;
  margin-left: 40px;
}
.bcqnRP {
  display: block;
  transform: rotate(-90deg);
}
.ckLyyv {
  display: inline-block;
  flex-shrink: 0;
  vertical-align: middle;
  transition: transform 0.2s ease-out;
  fill: white;
}

.width-175-px {
  width: 175px !important;
}
.width-415-px {
  width: 415px ;
}
.width-215-px {
  width: 215px !important;
}
.width-260-px {
  width: 260px !important;
}
.width-55 {
  width: 55%;
}

.filter-container-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 153px;
  height: 44px;
  /* width: 153px; */
  /* padding: 10px 10px 10px 13px; */
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("/images/filter.png"), url("/images/dropdown.png");
  background-repeat: no-repeat;
  background-position: 10%, 90%;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 3px;
  color: #485257;
  width: unset;
  cursor: pointer;
}

.filter-container-box input {
  background: transparent;
  border: unset;
  margin-left: 36px;
  width: 240px;
  box-shadow: unset !important;
  cursor: pointer;
  margin-left: 50px;
}
.filter-container-box input:active,
.filter-container-box input:focus,
.filter-container-box input:focus-within,
.filter-container-box input:focus-visible {
  background: transparent;
  border: unset;
  margin-left: 36px;
  width: 240px;
  box-shadow: unset !important;
  cursor: pointer;
}

.start-at-ondemand {
  padding: 24px 0px !important;
  padding-left: 91px !important;
}

/* /left right modal/ */
.modal.left_modal,
.modal.right_modal {
  position: fixed;
  z-index: 99999;
}

.modal.left_modal .modal-dialog,
.modal.right_modal .modal-dialog {
  position: fixed;
  margin: auto;
  width: 90%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.modal-30w {
  max-width: 30% !important;
}
.modal-dialog {
  /* max-width: 100%; */
  margin: 1.75rem auto;
}

@media (min-width: 576px) {
  .left_modal .modal-dialog {
    max-width: 100%;
  }

  .right_modal .modal-dialog {
    max-width: 100%;
  }
}

.modal.left_modal .modal-content,
.modal.right_modal .modal-content {
  /*overflow-y: auto;
	  overflow-x: hidden;*/
  height: 100vh !important;
}

.modal.left_modal .modal-body,
.modal.right_modal .modal-body {
  padding: 15px 15px 30px;
}

/*.modal.left_modal  {
	  pointer-events: none;
	  background: transparent;
  }*/

/* /Left/ */
.modal.left_modal.fade .modal-dialog {
  left: -50%;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left_modal.fade.show .modal-dialog {
  left: 0;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.5);
}

/* /Right/ */
.modal.right_modal.fade .modal-dialog {
  right: -50%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right_modal.fade.show .modal-dialog {
  right: 0;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.5);
}

/* ----- MODAL STYLE ----- */
/* .modal-content {
	border-radius: 0;
	border: none;
  } */

.modal-header.left_modal,
.modal-header.right_modal {
  padding: 10px 15px;
  border-bottom-color: #eeeeee;
  /* background-color: #FAFAFA; */
}

.modal_outer .modal-body {
  /* /height:90%;/ */
  /* overflow-y: auto;
	  overflow-x: hidden;
	  height: 91vh; */
}

/* // */
.fybOzd {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 50px;
  border-bottom: 1px solid rgb(191, 191, 191);
  background: rgb(255, 255, 255);
  pointer-events: auto;
}

.cwNJQZ {
  margin: initial;
  padding: initial;
  border: 1px transparent;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: #d5a67b;
  background-color: transparent;
  display: inline-block;
  width: 44px;
  height: 44px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
}
.cwNJQZ:hover {
  background-color: unset;
}
.eZmLtC {
  position: absolute;
  top: 2px;
}
.iXcSWy {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 100%;
}
.ggZsDW {
  display: inline-block;
  flex-shrink: 0;
  vertical-align: middle;
  transition: transform 0.2s ease-out;
  fill: currentcolor;
  transform: rotate(90deg);
}

.iFZzof {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: var(--fiji-font-size, 18px);
  letter-spacing: 0.02em;
  margin: 0px auto;
  padding: 0px 44px;
  line-height: 50px;
  text-align: center;
}

.gRbkB {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: rgb(255, 255, 255);
  pointer-events: auto;
  height: 100%;
}
.lfhkUh {
  padding: 24px 16px 0px;
}

.QondX {
  align-items: flex-start;
}

.hksPTU {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}

.KhUBn {
  margin-bottom: 24px;
  color: rgb(100, 100, 100);
}

.dUiSGl {
  margin: 0px;
  color: rgb(18, 18, 18);
  font-weight: 800;
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.bottom-line {
  border-bottom: 1px solid rgb(191, 191, 191);
}

@media (max-width: 1000px) {
  .ondemandpractice-wrapper {
    width: 100% !important;
  }
}
@media (max-width: 700px) {
  .godemantevent-internal-box {
    /* flex-direction: column; */
  }
  .modal-30w {
    max-width: 100% !important;
  }
}

.go-ondemand-practice-hover .hover-popup-r {
  width: unset;
  min-width: 500px;
  padding: 10px;
}
.go-ondemand-practice-hover .hover-popup-r .mainpre {
  font-size: 12px;
  margin: 5px;
}
.ondemant-i-btn {
  width: 15px;
  position: relative;
  top: -2px;
  left: 3px;
}
.disabled-btn-join{
  background: gainsboro;
  color: white;
  cursor: pointer;
}
.disabled-btn-join:hover{
  background: gainsboro;
  color: white;
  cursor: pointer;
}
.no-app-fount-box
{
  margin: 15px;
  padding: 40px 10px;
  background: #80808030;
  border: 1px solid;

}

.white-border{
  border: 2px solid white;
}
.white-bg-btn{
  background: white;
  color: #d5a67b;
  border: 2px solid #d5a67b;
}