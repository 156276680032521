.calendar-box-new .mb-0 > a {
  display: block;
  position: relative;
}
.calendar-box-new .mb-0 > a::after {
  content: "";
  font-family: "FontAwesome";
  position: absolute;
  right: 0;
  background: url("/images/Iconeroww.png");
  width: 18px;
  height: 9px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 9px;
}
.calendar-box-new .mb-0 > a[aria-expanded="true"]::after {
  content: "";
  background: url("/images/Iconeroww.png");
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotatex(178deg);
  width: 15px;
  top: 8px;
}
.calendar-box-new {
  display: inline-block;
  width: 100%;
  padding: 57px 28px 0 0 !important;
}
.calendar-box-new .card-header {
  padding: 23px 26px;
  background-color: #f5f5f5;
  border: 0.5px solid #707070;
  border-radius: 10px !important;
  margin-bottom: 7px;
}
.calendar-box-new .card-header h5 a {
  color: #485257;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  width: 100%;
  display: inline-block;
  font-family: "Montserrat", sans-serif !important;
}
.edite-btn {
  display: inline-block;
  margin: 0 0 0 14px;
  padding: 1px 0 0 0;
  background-color: #fff !important;
  border: 0.5px solid #485257;
  border-radius: 5px;
  width: 60px;
  height: 50px;
}
.calendar-box-new .card-body {
  padding: 0;
}
.table-box-clander {
  border: 0.5px solid #485257;
  border-radius: 10px;
  padding: 26px 26px;
  background-color: #ffffff;
  margin-bottom: 7px;
}
.table-box-clander table {
  width: 100%;
}
.table-box-clander table tr {
  border-bottom: 0.2px solid #707070;
}
.table-box-clander table td {
  padding: 17px 0;
  color: #485257;
  font-size: 20px;
  vertical-align: middle;
  font-family: "Montserrat", sans-serif !important;
}
.table-box-clander table tr td:last-child {
  text-align: right;
}
.table-box-clander table td button {
  width: 60px;
  height: 50px;
  border: 0.5px solid #485257;
  border-radius: 5px;
  background-color: #fff;
  padding: 0;
  margin: 0 0px 0 20px;
}
.table-box-clander table td .tbl-edete {
  position: relative;
  height: 50px;
  top: 0px;
}
.table-box-clander table tr:last-child {
  border-bottom: none;
}
.top-bar-clander-box {
  display: inline-block;
  width: 100%;
  margin-bottom: 37px;
}
.top-bar-clander-box .left-cont-clandr {
  float: left;
  width: 50%;
  text-align: left;
}
.left-cont-clandr span {
  font-size: 20px;
  color: #485257;
  font-weight: 600;
  display: inline-block;
  margin: 13px 0 0 0;
  font-family: "Montserrat", sans-serif !important;
}
.right-cont-clandr {
  float: right;
  width: 50%;
  text-align: right;
}
.right-cont-clandr button {
  border: 0.5px solid #485257;
  border-radius: 6px;
  background-color: #fff;
  color: #485257;
  font-size: 16px;
  font-weight: 300;
  padding: 16px 22px;
  margin: 0 0 0 15px;
  height: auto;
  font-family: "Montserrat", sans-serif !important;
}

.medium-schedules-all {
  display: inline-block;
  width: 100%;
  margin: 45px 0 0 0;
  padding: 0 28px 0 0;
}
.hading-boxmedium span {
  font-family: "Montserrat", sans-serif !important;
  font-size: 22px;
  font-weight: 400;
  display: inline-block;
  text-align: left;
  width: 100%;
  margin: 15px 0 0 0;
}
.button-boxmedium {
  text-align: right;
  width: 100%;
  display: inline-block;
}
.button-boxmedium .search-btn {
  width: 192px;
  height: 54px;
  background-color: #485257;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif !important;
}
.button-boxmedium .select-dropdown,
.select-dropdown * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}
.button-boxmedium .select-dropdown select {
  width: 100%;
  padding: 0 10px;
  border: none;
  background-color: transparent;
  appearance: none;
  color: #485257;
  font-weight: 300;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  cursor: pointer;
}
.button-boxmedium .select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}
.button-boxmedium .select-dropdown::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -3px;
  border-top: 8px solid #485257;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.session-type-btn {
  display: inline-block;
  width: 192px;
  height: 54px;
  border: 0.5px solid #485257;
  border-radius: 6px;
  line-height: 54px;
  padding: 0;
  margin: 0 5px 0 0;
}
.button-time-zone {
  display: inline-block;
  width: 144px;
  height: 54px;
  border: 0.5px solid #485257;
  border-radius: 6px;
  line-height: 54px;
  padding: 0;
  margin: 0 5px 0 0;
}
.country-button {
  display: inline-block;
  width: 144px;
  height: 54px;
  border: 0.5px solid #485257;
  border-radius: 6px;
  line-height: 54px;
  padding: 0;
  margin: 0 5px 0 0;
}
.medium-name-button {
  display: inline-block;
  width: 242px;
  height: 54px;
  border: 0.5px solid #485257;
  border-radius: 6px;
  line-height: 54px;
  padding: 0;
  margin: 0 5px 0 0;
}
.table-mideum-table {
  display: inline-block;
  width: 100%;
  margin: 21px 0 0;
  overflow: scroll;
}
.table-mideum-table p,
.table-mideum-table span {
  font-size: 13px !important;
}
.table-mideum-table table {
  width: 100%;
}
.table-mideum-table table th {
  background-color: #e7e7e7;
  padding: 19px 35px;
  vertical-align: middle;
  color: #485257;
  font-size: 17px;
  font-family: "Montserrat", sans-serif !important;
}
.table-mideum-table table td {
  padding: 68px 15px;
  border: 1px solid #d3d3d3 !important;
  border-top: unset !important;
  vertical-align: middle;
}
.table-mideum-table table td span {
  display: inline-block;
  width: 100%;
  color: #485257;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  margin: 5px 0;
}
.table-mideum-table table td p {
  color: #485257;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  line-height: 33px;
  margin-bottom: unset;
}
.table-mideum-table table td .left-last-td {
  float: left;
  width: 50%;
  color: #485257;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  padding: 4.9rem 0;
}
.table-mideum-table table td .right-last-td {
  float: right;
  width: 50%;
}
.right-last-td button {
  border: 0.5px solid #485257;
  border-radius: 5px;
  background-color: #fff;
  color: #485257;
  height: 50px;
  width: 140px;
  padding: 0;
  margin-bottom: 11px;
  font-size: 14px;
}
.right-last-td button img {
  width: 19px;
  margin: 0 4px;
  position: relative;
  top: 4px;
}
.table-mideum-table table th:nth-child(1),
.table-mideum-table table th:nth-child(2) {
  width: 15%;
}
.table-mideum-table table th:last-child {
  width: 25%;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.popup-acordion .btn.btn-info.btn-lg {
  float: left;
  position: absolute;
  top: 0;
  height: 46px;
  width: 60px;
  border: 0.5px solid #485257;
  border-radius: 5px;
  background-color: #fff;
  left: 0;
  margin: 11px 0 0 17.5rem;
  line-height: 58px;
  padding: 0 0 0 10px !important;
  text-align: center;
}

.popup-accordion .modal-dialog {
  max-width: 798px;
  margin: 10.75rem auto;
  height: 352px;
  background-color: #fff;
  border-radius: 10px;
}
.popup-accordion .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-clip: padding-box;
  height: 100%;
  border: none;
  background-color: transparent !important;
}
.popup-accordion .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  border-bottom: none;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popup-accordion h4 {
  width: 100%;
  text-align: center;
  color: #485257;
  text-transform: capitalize;
  font-size: 22px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  margin: 0;
}
.popup-accordion .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 54px 52px;
}
.popup-accordion form {
  display: inline-block;
  width: 100%;
  margin: 47px 0 0 0;
}
.popup-accordion form .fild-popup-row {
  width: 100%;
  display: inline-block;
}
.popup-accordion form label {
  margin: 0;
  width: 100%;
  color: #485257;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
}
.popup-accordion form input {
  width: 100%;
  border: 0.5px solid #666;
  border-radius: 5px;
  margin: 10px 0 0 0;
  height: 50px;
  padding: 0 10px;
  color: #000;
}
.popup-accordion .cancel-btn-sumbit {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 39px 0 0 0;
}
.popup-accordion .cancel-tn {
  width: 212px;
  height: 54px;
  border-radius: 6px;
  color: #485257;
  background-color: #fff;
  border: 1px solid #666;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
}
.popup-accordion .sumbit-tn {
  width: 212px;
  height: 54px;
  background-color: #485257;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
}
.popup-accordion .cancel-btn-sumbit button {
  margin: 0 10px;
}
.popup-accordion .close {
  float: right;
  /* font-weight: 700; */
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: inherit;
  background-color: #666;
  position: absolute;
  right: 26px;
  top: 26px;
  padding: 0 !important;
  width: 40px;
  border-radius: 50%;
  height: 40px;
  font-weight: 300;
  font-size: 28px;
  cursor: pointer;
  z-index: 999;
  font-family: "Montserrat", sans-serif !important;
}

/***/
.sessionRadio-box .filde-box-price p {
  width: unset !important;
}
.sessionRadio-box .filde-box-price p label {
  margin-right: 20px;
}

.schedule-advance-box-all {
  display: inline-block;
  width: 100%;
  margin: 52px 0 0 0;
}
.schedule-advance-box-left {
  display: inline-block;
  width: 100%;
  box-shadow: 0px 0px 33px #e0e4ee;
  border-radius: 7px;
  background-color: #fff;
  overflow: hidden;
  height: 750px;
}
.schedule-advance-box-left .accordion {
  margin: 0px 0;
}
.schedule-advance-box-left .accordion .item {
  border: none;
  margin-bottom: 0px;
  background: none;
}
.schedule-advance-box-left
  .accordion
  .item
  .item-header
  h2
  button.btn.btn-link {
  background: #fff;
  color: white;
  border-radius: 0px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: inherit;
  text-decoration: none;
  padding: 16px 14.86px !important;
  color: #485257;
  border-bottom: 1px solid #d1d1d1;
  border-top: none;
  border-left: none;
  border-right: none;
}
.schedule-advance-box-left .accordion .item .item-header {
  border-bottom: none;
  background: transparent;
  padding: 0px;
}

.schedule-advance-box-left .accordion .item .item-header h2 button {
  color: white;
  font-size: 20px;
  padding: 15px;
  display: block;
  width: 100%;
  text-align: left;
}
.schedule-advance-box-left .accordion .slect-language-list {
  display: inline-block;
  width: 100%;
  padding: 11.85px 17.75px;
  overflow: scroll;
  height: 190px;
  overflow-x: inherit;
}
.accordion .slect-language-list .form-group {
  margin: 0 0 20.31px 0;
}
.schedule-advance-box-left button.btn.btn-link.collapsed img {
  transform: rotate(0deg);
}

.schedule-advance-box-left button.btn.btn-link img {
  transform: rotate(180deg);
  transition: 0.5s;
}
.schedule-advance-box-left .accordion .btn img {
  width: auto;
  height: auto;
  margin: 0;
  position: relative;
  top: 1px;
  float: right;
}
.slect-language-list .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.slect-language-list .form-group label {
  position: relative;
  cursor: pointer;
}
.slect-language-list .form-group label::before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #cfcfcf;
  width: 18px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
  height: 18px;
}
.slect-language-list .form-group input:checked + label::before {
  background-color: #d5a67b;
  border-color: #d5a67b;
}
.slect-language-list .form-group input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 3px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.schedule-advance-box-left .accordion .slect-language-list label {
  color: #485257;
  font-size: 14px;
  font-weight: 500;
}
.schedule-advance-bx {
  display: inline-block;
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 5px;
  background-color: #fff;
  height: 100% !important;
}
.profile-box-top {
  border-bottom: 1px solid #d9d9d9;
  padding: 25.37px 33.37px;
  display: inline-block;
  width: 100%;
}
.left-prof-bx {
  float: left;
  width: 72.3px;
  border-radius: 50%;
  height: 72.3px;
}
.left-prof-bx .prof-circal {
  border: 2px solid #d5a67b;
  border-radius: 50%;
  height: 72.3px;
  width: 72.3px;
  text-align: center;
  padding: 5px 0 0 0;
}
.left-prof-bx .prof-circal img {
  width: 77%;
}
.left-name-bx {
  float: left;
  width: 78%;
  padding: 10px 0 0 18.12px;
}
.left-name-bx span {
  width: 100%;
  display: inline-block;
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
.rewie-bx {
  margin: 8px 0 0 0;
  display: inline-block;
  width: 100%;
}
.rewie-bx img {
  width: 15px;
  margin: 0 5px 0 0;
  position: relative;
  top: 3px;
}
.rewie-bx em {
  font-style: normal;
  color: #2f2f2f;
  font-size: 13px;
  font-weight: 500;
  border-left: 1px solid #dcdcdc;
  margin: 0 0 0 4px;
  padding: 0 0 0 5px;
}
.language-list-box {
  display: inline-block;
  width: 100%;
  padding: 17.1px 25.6px;
}
.language-list-box ul {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
}
.language-list-box ul li {
  display: inline-block;
  width: 100%;
  margin: 0 0 19.69px 0;
}
.language-list-box ul li img {
  position: relative;
  top: 6px;
  margin: 0 8px 0 0;
}
.language-list-box ul li span {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}
.language-list-box ul li em {
  font-style: normal;
  color: #d5a67b;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 7px;
}
.button-select-profile {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 10px 0 10px 0;
}
.button-select-profile button {
  width: 44%;
  margin: 0 10px;
  height: 46px;
  border: 1px solid #d5a67b;
  border-radius: 7px;
  background-color: transparent;
  color: #d5a67b;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
}
.schedule-advance-box-right {
  display: inline-block;
  width: 100%;
  padding: 0 27px 0 0;
}
.schedule-advance-box-right .row {
  margin-bottom: 13px;
}

.single-profile-saction-new .profile-info {
  width: 60%;
  float: left;
}
.single-profile-saction-new .profile-book-now {
  float: right;
  width: 20%;
  text-align: center;
  padding: 45px 0 0 0;
}
.profile-book-now button {
  background-color: #d5a67b;
  width: 273px;
  height: 46px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #e0e4ee;
  padding: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.profile-book-now p {
  margin: 12.25px 0 0 0;
  color: #485257;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  width: 100%;
}
.single-profile-saction-new a {
  border: 1px solid #cacaca;
  border-radius: 5px;
  background-color: #fff;
  width: 199px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  color: #485257;
  font-size: 14px;
  font-weight: 400;
}
.single-profile-saction-new a img {
  margin: 0 7px 0 0;
  position: relative;
  top: 3px;
}

.min-width-285 {
  min-width: 285px;
}

@media (max-width: 767px) {
  /**calendar-page css**/
  .calendar-box-new {
    padding: 15px 0 0 0 !important;
  }
  .top-bar-clander-box {
    margin-bottom: 20px;
  }
  .top-bar-clander-box .left-cont-clandr {
    width: 100%;
  }
  .left-cont-clandr span {
    font-size: 16px;
    margin: 10px 0 0 0;
  }
  .right-cont-clandr {
    width: 100%;
    margin: 15px 0 0 0;
    text-align: left;
  }
  .right-cont-clandr button {
    font-size: 14px;
    padding: 12px 0px;
    margin: 0 10px 0 0;
    height: auto;
    width: 48%;
  }
  .calendar-box-new .card-header {
    padding: 5px 10px;
    border-radius: 7px !important;
  }
  .calendar-box-new .card-header h5 a {
    color: #485257;
    font-size: 15px;
    line-height: 40px;
  }
  .edite-btn {
    display: inline-block;
    margin: 0 0 0 10px;
    padding: 3px 0 0 0;
    width: 35px;
    height: 32px;
  }
  .edite-btn img {
    width: 18px;
  }
  .calendar-box-new .mb-0 > a[aria-expanded="true"]::after {
    top: 12px;
  }
  .table-box-clander {
    border: 0.5px solid #485257;
    border-radius: 10px;
    padding: 0 10px 10px;
    overflow: scroll;
  }
  .table-box-clander table {
    width: 500px;
  }
  .edite-btn {
    margin: 4px 30px 0 0;
    padding: 0 0 0 0;
    width: 35px;
    height: 32px;
    float: right;
  }
  .table-box-clander table td button img {
    width: 18px;
  }
  .table-box-clander table td {
    padding: 10px 0;
    color: #485257;
    font-size: 14px;
  }
  .table-box-clander table tr td:last-child {
    padding-right: 10px;
  }
  .calendar-box-new .mb-0 > a::after {
    top: 12px;
  }
  .table-box-clander table td button {
    width: 30px;
    height: 30px !important;
    margin: 0 0px 0 7px;
  }
  .right-cont-clandr button:nth-child(2) {
    float: right;
    margin: 0;
  }

  /**Medium Schedules page css**/
}

.min-booking-tooltip {
  padding: unset !important;
}
.min-booking-tooltip::after {
  top: unset !important;
  right: unset !important;
}
.min-booking-tooltip-1 {
  left: 48px;
  top: -60px;
}
.end-time-tooltip-1 {
  left: 35px;
  top: -109px;
}

.buffer-tooltip-1 {
  left: -24px;
  top: -38px;
}
/*****availability scheduleage**/
.action-button-schedules-table {
  float: unset !important;
}
.action-button-schedules-table-th {
  width: 10% !important;
}

.hours_valid {
  background-color: #e7e7e7;
  font-weight: bold;
}

.btn-sedule button {
  width: auto;
  padding: 0 26px;
}
.cross-preview-box {
  position: fixed;
  margin-left: -16px;
}

@media (max-width: 767px) {
  .medium-schedules-all {
    width: 100%;
    margin: 0 0 0 0;
    padding: 0;
  }
  .tr-padding {
    padding: 20px 0 0 0;
    text-align: left;
    display: inline-block !important;
    width: 100%;
  }
  .middle-screen-name {
    display: inline-block;
    width: 100%;
    text-align: left;
    margin: 0 0 0 -13px;
  }
  .btn-sedule {
    width: 100%;
    display: inline-block;
    margin: 10px 0 0 0;
  }
  .btn-sedule button {
    margin: 0 0 13px 0 !important;
    height: auto;
    padding: 14px 0;
    width: 140px !important;
  }
  .table-mideum-table table th {
    padding: 5px;
    font-size: 13px;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600;
  }
  .table-mideum-table table td {
    padding: 7px;
    vertical-align: top;
    text-align: left;
  }
  .tableclander table {
    width: 1450px;
  }
  .table-mideum-table table td p {
    line-height: inherit;
  }
  .table-mideum-table table td .right-last-td {
    width: 100%;
  }
  .tableclander .right-last-td button {
    width: 100%;
    margin-bottom: 15px;
    font-size: 13px;
    height: auto;
    padding: 7px 0;
  }
}
.hr-schedule-dated {
  border-bottom: 2px solid #e7e7e7;
  margin-bottom: 15px;
}
.backtoschedules-modal .modal {
  top: 30%;
}
.edit-button-availbility {
  box-shadow: 0px 0px 4px black;
  font-size: 16px !important;
}

.preview-button-availbility {
  box-shadow: 0px 0px 4px green;
  font-size: 16px !important;
  color: green !important;
  border: 0.5px solid green !important;
}
.remove-button-availbility {
  box-shadow: 0px 0px 4px red;
  font-size: 16px !important;
  color: red !important;
  border: 0.5px solid red !important;
}

.time-difference-popup {
  position: absolute;
  background: #ffc1c3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 125px;
  /* color: white; */
  border: 2px solid red;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1012;
  width: 680px;
  text-align: center;
  padding: 0 75px;
  margin: auto;
  line-height: 28px;
}
