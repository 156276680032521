/*single-practioners-box*/

.back-practioners {
	display: inline-block;
	width: 100%;
	text-align: left;
	color: #000;
	font-size: 27px;
	text-transform: capitalize;
}
.practioners-singl-profile {
	display: inline-block;
	width: 100%;
	text-align: left;
	margin: 45px 0 0 0;
}
.practioners-left-profile {
	float: left;
	width: 25%;
	text-align: center;
}
.practioners-left-profile img {
	width: auto;
	border-radius: 50%;
}
.practioners-left-profile h3 {
	display: inline-block;
	margin: 13px 0 0 0;
	width: 100%;
	color: #485257;
	font-size: 24px;
	font-weight: 600;
	letter-spacing: 0.5px;
	font-family: "Oakes-Grotesk-Medium";
}
.practioners-left-profile em {
	font-style: normal;
	font-size: 24px;
	color: #000;
	font-weight: 600;
	margin: 0 7px;
	border-left: 1px solid #a4a4a4;
	padding: 0 8px 0 11px;
}
.practioners-left-profile span {
	display: inline-block;
	margin: 10px 0 10px 0;
	width: 100%;
}
.practioners-left-profile span img {
	width: auto;
	margin: 0 3px;
}
.practioners-left-profile a {
	display: inline-block;
	width: 100%;
	background-color: #37474f;
	border-radius: 5px;
	color: #fff;
	font-size: 20px;
	padding: 13px 0;
	margin: 10px 0 0 0;
	font-weight: 300;
	font-family: "Oakes-Grotesk-Regular";
}
.back-practioners img {
	margin: 0 10px 0 0;
}
.practioners-right-profile {
	float: right;
	width: 75%;
	padding: 3rem 0 0 7rem;
}
.practioners-right-profile li {
	display: inline-block;
	width: 100%;
	margin: 0 0 18px 0;
	font-family: "Oakes-Grotesk-Regular";
	color: #333;
	font-size: 18px;
}
.practioners-right-profile li span {
	margin: 0 0 0 19px;
	font-size: 20px;
	font-weight: 900;
	color: #37474f;
}
.practioners-right-profile li:nth-child(2) span:nth-child(2) {
	border-left: 1px solid #666;
	padding: 0 3px 0 18px;
}
.biography-saction {
	display: inline-block;
	width: 100%;
	border-top: 1px solid #666;
	margin: 50px 0 0;
	padding: 30px 0 0;
}
.biography-saction h2 {
	display: inline-block;
	margin: 13px 0 0 0;
	width: 100%;
	color: #485257;
	font-size: 24px;
	font-weight: 600;
	letter-spacing: 0.5px;
	font-family: "Oakes-Grotesk-Medium";
}
.biography-saction p {
	display: inline-block;
	margin: 25px 0 0 0;
	font-family: "Oakes-Grotesk-Regular";
	color: #485257;
	line-height: 29px;
	font-size: 18px;
}
.back-practioners .back-practioners-back-btn {
	color: #37474f;
	font-family: "Oakes-Grotesk-Regular";
	font-size: 23px;
	font-weight: 500;
}

/*****/


.single-profile-saction-new .profile-info {
    width: 60%;
    float: left;
}
.single-profile-saction-new .profile-book-now {
	float: right;
	width: 27%;
	text-align: center;
	padding: 45px 0 0 0;
}
.profile-book-now button ,.profile-book-now .book-now-practice  {
    background-color: #D5A67B;
    width: 273px;
    /* height: 46px; */
    border: none;
    border-radius: 5px;
    box-shadow: 0px 5px 10px #E0E4EE;
    padding: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}
.profile-book-now p {
    margin: 12.25px 0 0 0;
    color: #485257;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    width: 100%;
}
.single-profile-saction-new a ,.bac-href {
    border: 1px solid #CACACA;
    border-radius: 5px;
    background-color: #fff;
    width: 199px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    color: #485257;
    font-size: 14px;
    font-weight: 400;
}
.single-profile-saction-new a img ,.bac-img {
    margin: 0 7px 0 0;
    position: relative;
    top: 3px;
}




@media (max-width: 767px) {
	.schedule-advance-box-all-container {
		max-width: 100%;
		margin: 15px auto !important;
		padding: 0 15px;
		width: 100%;
	}
	.single-profile-saction-new a, .bac-href {
		width: 160px;
		height: 35px;
		line-height: 35px;
		font-size: 12px;
	}
	.single-profile-saction-new a img, .bac-img {
		width: 16px;
	}
	.schedule-advance-box-all-container .row {
		display: inline-block;
	}
	.schedule-advance-box-left {
		margin: 15px 0 0 0;
		height: auto;
	}
	.schedule-advance-box-left .accordion .item .item-header h2 button.btn.btn-link {
		padding: 15px 15px !important;
	}
	.schedule-advance-box-left .accordion .slect-language-list {
		padding: 20px 15px;
		height: 180px;
	}
	.schedule-advance-box-left .accordion .btn img {
		width: auto !important;
	}
	.schedule-advance-box-right {
		width: 100%;
		padding: 0;
		margin: 15px 0 0 0;
	}
	.schedule-advance-box-right .col-md-4.mb-3 {
		padding: 0;
	}
	.profile-box-top {
		border-bottom: 1px solid #D9D9D9;
		padding: 15px;
	}
	.left-prof-bx .prof-circal {
		height: 60px;
		width: 60px;
		padding: 4px 0 0 0;
	}
	.left-prof-bx .prof-circal img {
		width: 40px;
	}
	.left-name-bx {
		width: 82%;
		padding: 0 0 0 15px;
	}
	.left-name-bx span {
		font-size: 18px;
	}
	.language-list-box {
		padding: 15px;
	}
	.language-list-box ul li {
		margin: 0 0 15px 0;
		position: relative;
		padding: 0 0 0 30px;
		line-height: 24px;
	}
	.button-select-profile button {
		width: 45%;
		margin: 0 8px;
		height: 45px;
		color: #D5A67B;
		font-size: 14px;
		letter-spacing: 0.01em;
	}
	.language-list-box ul li img {
		position: absolute;
		top: -2px;
		margin: 0 0 0 0;
		left: 0;
	}
	.language-list-box ul li span {
		color: #000;
		font-size: 13px;
	}
	.language-list-box ul li em {
		font-size: 13px;
		margin: 0 0 0 5px;
	}
	.accordion .slect-language-list .form-group {
		margin: 0 0 18.31px 0;
	}
}








.practioner-heading {
	text-align: center;
	margin: 3rem 0;
	font-weight: bold;
	font-size: 1.8rem;
	color: black;
  }
  .starter-practioners-margin {
	margin: 4rem 0;
  }
  .starter-practioners {
	display: inline-block;
	width: 100%;
	text-align: center;
  }
  .box-practioners {
	border: 1px solid #dcdcdc;
	border-radius: 8px;
	position: relative;
	width: 100%;
	padding: 0 10px 10px;
	background-color: white;
  }
  .img-practi {
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: -62px;
  }
  .img-practi img {
	width: 45%;
	border-radius: 50%;
  }
  .content-practi {
	margin: 85px 0 0 0;
	display: inline-block;
	width: 100%;
  }
  .content-practi h3 {
	color: #000;
	font-size: 28px;
	font-weight: 600;
	display: inline-block;
	width: 100%;
	text-align: center;
  }
  .content-practi p {
	color: #666;
	font-size: 20px;
	margin: 5px 0 0 0;
  }
  .content-practi em {
	font-style: normal;
	font-size: 24px;
	color: #000;
	font-weight: 600;
	margin: 0 7px;
	border-left: 1px solid #a4a4a4;
	padding: 0 8px 0 11px;
  }
  .content-practi span {
	display: inline-block;
	margin: 10px 0 10px 0;
  }
  
  .box-practioners-internal {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
  }
  /******/
  
  
  
  .schedule-advance-box-all-container{
	max-width: 1560px;
	margin: 52px auto !important;
	display: block !important;
  }
  
  .schedule-advance-box-all {
	display: inline-block;
	width: 100%;
	margin: 52px 0 0 0;
  }
  .schedule-advance-box-left {
	display: inline-block;
	width: 100%;
	box-shadow: 0px 0px 33px #E0E4EE;
	border-radius: 7px;
	background-color: #fff;
	overflow: hidden;
	height: 750px;
  }
  .schedule-advance-box-left .accordion{
	margin: 0px 0;
  }
  .schedule-advance-box-left .accordion .item {
	  border: none;
	  margin-bottom: 0px;
	  background: none;
  }
  .schedule-advance-box-left .accordion .item .item-header h2 button.btn.btn-link {
	background: #fff;
	color: white;
	border-radius: 0px;
	font-family: 'Poppins';
	font-size: 16px;
	font-weight: 400;
	line-height: inherit;
	text-decoration: none;
	padding: 16px 14.86px !important;
	color: #485257;
	border-bottom: 1px solid #D1D1D1;
	border-top: none;
	border-left: none;
	border-right: none;
  }
  .schedule-advance-box-left .accordion .item .item-header {
	  border-bottom: none;
	  background: transparent;
	  padding: 0px;
  }
  
  .schedule-advance-box-left .accordion .item .item-header h2 button {
	  color: white;
	  font-size: 20px;
	  padding: 15px;
	  display: block;
	  width: 100%;
	  text-align: left;
  }
  .schedule-advance-box-left .accordion .slect-language-list {
	display: inline-block;
	width: 100%;
	padding: 11.85px 17.75px;
	overflow: scroll;
	height: 190px;
	overflow-x: inherit;
  }
  .accordion .slect-language-list .form-group {
	margin: 0 0 20.31px 0;
  }
  .schedule-advance-box-left  button.btn.btn-link.collapsed img {
	  transform: rotate(0deg);
  }
  
  .schedule-advance-box-left  button.btn.btn-link img {
	  transform: rotate(180deg);
	  transition: 0.5s;
  }
  .schedule-advance-box-left .accordion  .btn img {
	width: auto;
	height: auto;
	margin: 0;
	position: relative;
	top: 1px;
	float: right;
  }
  .slect-language-list .form-group input {
	padding: 0;
	height: initial;
	width: initial;
	margin-bottom: 0;
	display: none;
	cursor: pointer;
  }
  
  .slect-language-list .form-group label {
	position: relative;
	cursor: pointer;
  }
  .slect-language-list .form-group label::before {
	content: '';
	-webkit-appearance: none;
	background-color: transparent;
	border: 1px solid #CFCFCF;
	width: 18px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 10px;
	border-radius: 5px;
	height: 18px;
  }
  .slect-language-list .form-group input:checked + label::before {
	background-color: #D5A67B;
	border-color: #D5A67B;
  }
  .slect-language-list .form-group input:checked + label::after {
	content: '';
	display: block;
	position: absolute;
	top: 3px;
	left: 8px;
	width: 3px;
	height: 11px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
  }
  .schedule-advance-box-left .accordion .slect-language-list label {
	color: #485257;
	font-size: 14px;
	font-weight: 500;
  }
  .schedule-advance-bx {
	display: inline-block;
	width: 100%;
	border: 1px solid #CACACA;
	border-radius: 5px;
	background-color: #fff;
  }
  .profile-box-top {
	  border-bottom: 1px solid #D9D9D9;
	  padding: 15px;
	  display: inline-block;
	  width: 100%;
  }
  .left-prof-bx {
	  float: left;
	  width: 55.3px;
	  border-radius: 50%;
	  height: 55.3px;
  }
  .left-prof-bx .prof-circal {
	  border: 2px solid #D5A67B;
	  border-radius: 50%;
	  height: 55.3px;
	  width: 55.3px;
	  text-align: center;
	  padding: 5px 0 0 0;
  }
  .left-prof-bx .prof-circal img {
	width: 77%;
  }
  .left-name-bx {
	  float: left;
	  width: 76%;
	  padding: 2px 0 0 15.12px;
  }
  .left-name-bx span {
	  width: 100%;
	  display: inline-block;
	  color: #000;
	  font-size: 16px;
	  font-weight: 600;
  }
  .rewie-bx {
	margin: 8px 0 0 0;
	display: inline-block;
	width: 100%;
  }
  .rewie-bx img {
	  width: 14px;
	  margin: 0 3px 0 0;
	  position: relative;
	  top: 3px;
  }
  .rewie-bx em {
	  font-style: normal;
	  color: #2F2F2F;
	  font-size: 12px;
	  font-weight: 500;
	  border-left: 1px solid #dcdcdc;
	  margin: 0 0 0 4px;
	  padding: 0 0 0 5px;
  }
  .language-list-box {
	  display: inline-block;
	  width: 100%;
	  padding: 15.1px 15px;
  }
  .language-list-box ul {
	  display: inline-block;
	  width: 100%;
	  padding: 0;
	  margin: 0;
	  height: 145px;
  }
  .language-list-box ul li {
	  display: inline-block;
	  width: 100%;
	  margin: 0 0 23.69px 0;
	  position: relative;
	  padding: 0 0 0 33px;
	  line-height: 20px;
  }
  .language-list-box ul li img {
	  position: absolute;
	  top: -1px;
	  margin: 0 8px 0 0;
	  left: 0;
  }
  .language-list-box ul li span {
	  color: #000;
	  font-size: 13px;
	  font-weight: 400;
  }
  .language-list-box ul li em {
	  font-style: normal;
	  color: #D5A67B;
	  font-size: 13px;
	  font-weight: 500;
	  margin: 0 0 0 4px;
  }
  .button-select-profile {
	display: inline-block;
	width: 100%;
	text-align: center;
	margin: 10px 0 10px 0;
  }
  .button-select-profile button {
	  width: 43%;
	  margin: 0 8px;
	  height: 46px;
	  border: 1px solid #D5A67B;
	  border-radius: 7px;
	  background-color: transparent;
	  color: #D5A67B;
	  font-size: 13px;
	  font-weight: 500;
	  letter-spacing: 0.01em;
  }
  .schedule-advance-box-right {
	  display: inline-block;
	  width: 100%;
	  padding: 0 0 0 0;
  }
  .schedule-advance-box-right .row {
	margin-bottom: 13px;
  }
  
  .d-flex-flex-wrap-wrap{
	display: flex;
	flex-wrap: wrap;
  }
  .schedule-advance-box-all-container {
	  width: 1240px;
	  max-width: inherit !important;
  }
  .app-new-profile .profile-name a {
	  padding: 0;
	  border: none;
	  text-align: left;
	  width: auto;
	  height: auto;
  }
  .app-new-profile .profile-image {
	  height: auto;
  }
  .filter-tabs{
	background: #D5A67B;
	color: white;
	padding: 10px;
	border-radius: 20px;
	margin: 5px;
	display: inline-block;
  }
  .filter-tabs-cross{
	color: black;
	margin-left: 10px;
  }
  .filter-tabs-container{
	margin-top: 15px;
  }
  @media (max-width: 767px) {
	.single-profile-saction-new .profile-info {
	  width: 100% !important;
	  text-align: center;
	  display: inline-block !important;
	}
	.app-new-profile .profile-image {
	  width: 100%;
	  height: auto;
	  bottom: 0;
	  overflow: hidden;
	  text-align: center;
	  float: inherit !important;
	}
	.app-new-profile .profile-image img {
	  width: 70px;
	  height: 70px;
	  border: none !important;
	}
	.app-new-profile .profile-name a {
	  width: auto;
	  border: none;
	  padding: 0;
	  margin: 0px 0 0 0;
	}
	.single-profile-saction-new .profile-book-now {
	  float: right;
	  width: 100% !important;
	  padding: 20px 0 20px 0 !important;
	}
	.profile-book-now button {
	  background-color: #D5A67B;
	  width: 90%;
	  height: 44px;
	  font-size: 14px;
	}
	.app-new-profile-details ul li a {
	  width: auto !important;
	  height: auto;
	  line-height: inherit;
	  padding: 7px 19px;
	  margin: 6px 0 0 0 !important;
	}
	.app-new-profile-details ul li em {
	  width: auto;
	  float: left;
	  margin: 17px 0 0 0;
	}
	.app-new-profile-details .icon-detls {
	  margin: 17px 10px 0 0 !important;
	}
	.app-new-profile-details li {
	  margin: 0 !important;
	}
	.language-list-box ul {
	  height: auto !important;
	}
  
  
  }
  
  @media  screen and (max-width: 1366px) {
	  .profile-box-top {
		  padding: 20.37px 10.37px;
	  } 
	  .left-prof-bx {
		  width: 60px;
		  height: 60px;
	  } 
	  .left-name-bx span {
		  width: 100%;
		  font-size: 13px;
	  } 
	.left-prof-bx .prof-circal {
	  height: 60.3px;
	  width: 60.3px;
	}
	  .language-list-box ul li {
		  position: relative;
		  padding: 0 0 0 36px;
		  margin: 0 0 17px 0px;
		  line-height: 20px;
	  }
	  .language-list-box ul li img {
		  position: absolute;
		  top: -1px;
		  left: 0;
	  }
	  .language-list-box ul li span {
		  color: #000;
		  font-size: 12px;
	  }
	  .language-list-box ul li em {
		  font-size: 12px;
	  }
	  .button-select-profile button {
	  width: 41%;
	  margin: 0 5px;
	  height: 45px;
	}
	.accordion .slect-language-list .form-group {
	  position: relative;
	}
	.slect-language-list .form-group label::before {
	  position: absolute;
	  left: 0;
	  top: -2px;
	}
	.schedule-advance-box-left .accordion .slect-language-list label {
	  font-size: 12px;
	  padding: 0 0 0 30px;
	}
	.slect-language-list .form-group input:checked + label::after {
	  top: 0px;
	}
	.schedule-advance-box-all-container {
	  width: 1280px;
	  max-width: inherit !important;
	  margin: 10px auto !important;
	}
	.language-list-box ul {
	  height: 125px;
	}
  
		  
  }
