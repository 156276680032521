.privacy-btn-wrapper {
  font-size: 12px;
  text-align: right;
  position: absolute;
  text-align: right;
  width: 100%;
  margin-top: 3px;
}
.privacy-btn {
  background: none;
  border: none;
  color: unset;
  padding: unset;
  display: inline-block;
  text-decoration: underline;
  height: unset;
}
.privacy-btn:hover {
  background: none;
  border: none;
  color: unset;
  padding: unset;
  display: inline-block;
  text-decoration: underline;
}

.text-decoration-underline {
  text-decoration: underline;
}
.showexpectioninfo {
  text-align: left;
  font-size: 12px;
  line-height: 15px;
}
.showexpectioninfo ol {
  margin-top: 5px;
  margin-bottom: 5px;
}

.radio-btn-starter {
  accent-color:#0076f8;
  border: 1px solid red;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-top: 2px;
  background-color: #f0ffff;
  display: block;
}
/* .c[type="checkbox"]::before {
  background-color: blue !important;
} */
.button-starter{
  background: #80808012;
  border-color: gray;
  border-radius: 6px;
  color: #000000cc;
  font-size: 16px;

}

.register-client-below-text
{  border-bottom: 2px solid #D5A67B !important;
  width: 330px;
  text-align: center;
  height: 30px;
  /* color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
 margin-bottom: unset;
 font-size: 18px;

  }

.flex-direction-responsive{
  flex-direction: row;
}
.min-height-100vh-client{
  min-height: 100vh;
}

@media (max-width: 1374px) {
  .flex-direction-responsive{
    flex-direction: column !important;
  }
  .text-dflex{
    width: 100% !important;
  }
}
@media (max-width:766px) {
  .min-height-100vh-client{
    min-height: unset;
  }
  .row-client{
    flex-direction: column;
  }
  .col-md-nth-child{
    display: block !important;
    order: unset !important;
  }
  .flex-direction-responsive{
    /* flex-direction: unset !important; */
  margin-top: unset !important;
  }
  .background-sm{
    background: linear-gradient(to right, #f5f5f5 100%, transparent 100%) !important;
    margin-top: unset !important;
  }
  .text-dflex{
   
    font-size: 22px;
  }
  .black-container-bg{
    background:unset !important;
  }
}
.black-container-bg{
  background: linear-gradient(to right, #f5f5f5 50%, transparent 50%);
    margin-top: unset !important;
}